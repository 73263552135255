/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

 import ApiClient from '../ApiClient';

 /**
  * The Uploads model module.
  * @module model/Uploads
  * @version 1.0
  */
 class Uploads {
     /**
      * Constructs a new <code>Uploads</code>.
      * @alias module:model/Uploads
      */
     constructor() { 
         
         Uploads.initialize(this);
     }
 
     /**
      * Initializes the fields of this object.
      * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
      * Only for internal use.
      */
     static initialize(obj) { 
     }
 
     /**
      * Constructs a <code>Uploads</code> from a plain JavaScript object, optionally creating a new instance.
      * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
      * @param {Object} data The plain JavaScript object bearing properties of interest.
      * @param {module:model/Uploads} obj Optional instance to populate.
      * @return {module:model/Uploads} The populated <code>Uploads</code> instance.
      */
     static constructFromObject(data, obj) {
         if (data) {
             obj = obj || new Uploads();
 
             if (data.hasOwnProperty('Originalname')) {
                 obj['Originalname'] = ApiClient.convertToType(data['Originalname'], 'String');
             }
             if (data.hasOwnProperty('UploadId')) {
                obj['UploadId'] = ApiClient.convertToType(data['UploadId'], 'String');
            }
            if (data.hasOwnProperty('Size')) {
                obj['Size'] = ApiClient.convertToType(data['Size'], 'String');
            }
         }
         return obj;
     }
 
 
 }
 
 /**
  * @member {String} Size
  */
 Uploads.prototype['Size'] = undefined;
 
  /**
  * @member {String} UploadId
  */
   Uploads.prototype['UploadId'] = undefined;
 
    /**
  * @member {String} Originalname
  */
 Uploads.prototype['Originalname'] = undefined;
 
 
 
 
 
 export default Uploads;
 
 