import DJRView from "components/djr/DJRView";
import HomeView from "components/home/HomeView";
import LegalView from "components/profile/LegalView";
import UserAdministartionView from "components/profile/UserAdminstrationnView";
import UserView from "components/users/UserView";
import path from "routesPath";

import AllDJRView from "components/djr/AllDJRView";

import ProjectAdministartionView from "components/profile/ProjectAdministartionView";

import Login from "components/auth/Login";
import ClientAdministartionView from "components/profile/ClientAdminstrationnView";

import PhoneVerificationPage from "components/PhoneVerification";
import AlertView from "components/alerts/AlertView";
import AllAlertView from "components/alerts/AllAlertView";
import ForgotPassword from "components/auth/ForgotPassword";
import CreedRequestView from "components/client-request-creeds/CreedRequestView";
import FeedbackView from "components/client-request-creeds/FeedbackView";
import JobDetailsView from "components/client-request-creeds/JobDetailsView";
import ClientView from 'components/client/ClientView';
import ContractorView from "components/contractor/ContractorView";
import CreedView from "components/creed/CreedView";
import DjrSummaryList from "components/djr/DjrSummaryList";
import EditDjr from "components/djr/EditDjr";
import HolidayView from "components/holiday/HolidayView";
import TaskDetails from "components/home/TaskDetails";
import LeaveList from "components/leaves/LeaveList";
import LeaveView from "components/leaves/LeaveView";
import MTAApprovalView from "components/mat-approvals/MTAApprovalView";
import MtaSpecificationView from "components/mta-specifications/MtaSpecificationView";
import AppPrivacyPolicy from "components/profile/AppPrivacyPolicy";
import AppTermsAndConditions from "components/profile/AppTermsAndConditions";
import ContractorAdministartionView from "components/profile/ContractorAdministartionView";
import CreedAdministartionView from "components/profile/CreedAdministartionView";
import HolidayAdministartionView from "components/profile/HolidayAdministartionView";
import LeaveAdministartionView from "components/profile/LeaveAdminstrationnView";
import PersonalInfo from "components/profile/PersonalInfo";
import SubContractorAdministartionView from "components/profile/SubContractorAdministartionView";
import TermsView from "components/profile/Terms";
import ProjectView from "components/project/ProjectView";
import ReportView from "components/report/Report";
import SubContractorView from "components/subContractor/SubContractorView";
import UserUpdatePassword from "components/users/UserUpdatePasswod";
export const routes = [
  { path: path.login, component: Login },



  { path: path.home, component: HomeView },

  { path: path.DJRSummary, component: DjrSummaryList },
  { path: path.DJR, component: DJRView },
  { path: path.alert, component: AlertView },

  { path: path.allAlerts, component: AllAlertView },



  { path: path.allDJRs, component: AllDJRView },
  { path: path.EditDJR, component: EditDjr },


  { path: path.user.userDetail, component: UserView },
  { path: path.client.clientDetail, component: ClientView },
  { path: path.project.projectDetail, component: ProjectView },
  { path: path.creed.creedDetail, component: CreedView },
  { path: path.creedRequest.creedRequestDetail, component: CreedRequestView },

  { path: path.contractor.contractorDetail, component: ContractorView },
  { path: path.holiday.holidayDetail, component: HolidayView },
  { path: path.leaves.leaveDetail, component: LeaveView },
  { path: path.subContractor.subContractorDetail, component: SubContractorView },
  { path: path.task.taskDetail, component: TaskDetails },
  { path: path.leaves.leaveList, component: LeaveList },
  { path: path.user.userUpdatePassword, component: UserUpdatePassword },
  { path: path.profile.alerts, component: AllAlertView },
  { path: path.profile.userAdministration, component: UserAdministartionView },
  { path: path.profile.leaveAdministration, component: LeaveAdministartionView },
  { path: path.profile.report, component: ReportView },
  { path: path.profile.matApproval, component: MTAApprovalView },
  { path: path.profile.matProjectSpecification, component: MtaSpecificationView },
  { path: path.profile.clientAdministration, component: ClientAdministartionView },
  { path: path.profile.projectAdministration, component: ProjectAdministartionView },
  { path: path.profile.credAdministration, component: CreedAdministartionView },
  { path: path.profile.holidayAdministration, component: HolidayAdministartionView },
  { path: path.profile.contractorAdministration, component: ContractorAdministartionView },
  { path: path.profile.subContractorAdministration, component: SubContractorAdministartionView },
  { path: path.profile.legal, component: LegalView },
  { path: path.profile.personalInfo, component: PersonalInfo },
  { path: path.forgotPassword, component: ForgotPassword },
  { path: path.terms, component: TermsView },
  { path: path.appterms, component: AppTermsAndConditions },
  { path: path.appprivacy, component: AppPrivacyPolicy },
  { path: path.phoneVerify, component: PhoneVerificationPage },
  { path: `${path.feedback}/:id`, component: FeedbackView },
  { path: `${path.coverage}/:id`, component: JobDetailsView },
];
