import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";

import * as actions from "../actions";
import Api from "services/api";
import { prop } from "ramda";
import {
  endProgress,
  failProgress,
  startProgress,
} from "sagas/common";
import history from "../history";
import path from "routesPath";

function* getHolidays() {
  const progress = yield startProgress();
  try {
    const holidays = yield Api.holiday.getHolidaysUsingGET();
    yield put(actions.setHolidayProps({ holidays }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addHoliday(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const holiday = yield Api.holiday.addHoliday(body);
    history.goBack();
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeHoliday(action) {
  try {
    const body = action.payload;
    const param = {
      "Id":body
    }
    const holiday = yield Api.holiday.removeHoliday(param);

    history.goBack();
  } catch (error) {
    console.error(error);
  }
}

function* refreshHoliday() {
  const progress = yield startProgress();
  yield getHolidays();
  yield endProgress(progress);
}

function* showHolidayDetails(action) {
  const { holiday } = action.payload;
  yield put(actions.setHolidayProps({ holidayDetails: holiday }));
  history.push(path.holiday.holidayDetail);
}

export default function* saga() {
  yield all([
    takeLatest(actions.GET_HOLIDAYS, getHolidays),
    takeLatest(actions.ADD_HOLIDAY, addHoliday),
    takeLatest(actions.REMOVE_HOLIDAY, removeHoliday),
    takeLatest(actions.REFRESH_HOLIDAYS, refreshHoliday),
    takeLatest(actions.HOLIDAY_DETAILS, showHolidayDetails),
  ]);
}
