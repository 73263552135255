export default class AuthControllerMock {

  constructor() {
    console.log('Using mock of AuthControllerApi')
  }

  log(...msg) {
    console.log('[AuthControllerMock]', ...msg);
  }

  loginUsingPOST(req) {
    this.log('loginUsingPOST:', req)
    return {};
  }

  refreshTokenUsingPOST(req) {
    this.log('refreshTokenUsingPOST:', req)
    return {}
  }

  sendOTPRequestUsingPOST(req) {
    this.log('sendOTPRequestUsingPOST:', req)
    return {}
  }

}
