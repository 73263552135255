/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIEndPoints from "./ApiEndpoints";

/**
* NotificationController service.
* @module api/NotificationControllerApi
* @version 1.0
*/
export default class NotificationControllerApi {

  /**
  * Constructs a new NotificationControllerApi. 
  * @alias module:api/NotificationControllerApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
    this.endPoints = new APIEndPoints();
  }



  /**
   * registerToken
   * @param {module:model/PushRegistration} request request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  registerTokenUsingPOSTWithHttpInfo(request) {
    let postBody = request;
    // verify the required parameter 'request' is set
    if (request === undefined || request === null) {
      throw new Error("Missing the required parameter 'request' when calling registerTokenUsingPOST");
    }

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = null;
    return this.apiClient.callApi(
      this.endPoints.Notification.RegisterToken.URI, this.endPoints.Notification.RegisterToken.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * registerToken
   * @param {module:model/PushRegistration} request request
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  registerTokenUsingPOST(request) {
    return this.registerTokenUsingPOSTWithHttpInfo(request)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


}
