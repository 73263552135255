import _, {noop} from 'lodash';

class SecureStorage {

  checkStorage = () => new Promise((resolve, reject) => {
    if (!_.get(window, 'cordova.plugins.SecureKeyStore')) {
      reject(new Error("cordova.plugins.SecureKeyStore isn't available"));
    } else {
      resolve(true);
    }
  });

  setUseLocalStorage = () => {
    this.useLocalStorage = true;
  }


  set = (key, value) => new Promise((resolve = noop, reject) => {
    if (this.useLocalStorage) {
      window.localStorage.setItem(key, value);
      resolve();
    } else {
      window.cordova.plugins.SecureKeyStore.set(resolve, reject, key, value);
    }
  });

  get = (key) => new Promise((resolve = noop, reject) => {
    if (this.useLocalStorage) {
      resolve(window.localStorage.getItem(key));
    } else {
      window.cordova.plugins.SecureKeyStore.get(resolve, reject, key);
    }
  });


  setRefreshToken = (token) => this.set('refreshToken', token);
  getRefreshToken = () => this.get('refreshToken');

  setAccessToken = (token) => this.set('accessToken', token);
  getAccessToken = () => this.get('accessToken');

}

const secureStorage = new SecureStorage();
export default secureStorage;
