import * as actions from '../actions/progress';

const initialState = {
  activeProgressIds: [],
  show: false,
  completed: false,
  failed: false,
};

const progressReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.PROGRESS_SET_PROPS:
      return {...state, ...action.payload};

    case actions.PROGRESS_SHOW:
      const {show, failed, activeProgressIds} = state;
      return {
        ...state,
        show: true,
        failed: show && failed,
        activeProgressIds: [...activeProgressIds, action.payload.id],
      };

    default:
      return state;
  }
};

export default progressReducer;
