import React from 'react';
import Dialog from "@mui/material/Dialog";
import { SlideUp } from "components/common/transitions";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-end",
        width: '100%'
    },
    topPaperScrollBody: {
        margin: '0',
        width: '100%'
    },

});

export default function ResetPasswordDialogue({ open, onClose, onRemoveConfirm }) {
    const classes = useStyles();
    return (
        <Dialog open={open}
            onClose={onClose}
            TransitionComponent={SlideUp}
            classes={{

                scrollPaper: classes.topScrollPaper,
                paperScrollPaper: classes.topPaperScrollBody
            }}
        >
            <DialogContent>
                <Typography gutterBottom > {"Are you sure you want to reset User password?"}</Typography>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    data-testid='btn-dialog-back'>
                    Back
                </Button>
                <Button variant="contained"
                    color="secondary"
                    onClick={onRemoveConfirm}
                    data-testid='btn-dialog-remove'
                >
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );
}