import {
  all, put, takeLatest
} from "redux-saga/effects";

import path from "routesPath";
import {
  endProgress,
  failProgress,
  startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

function* getClients() {
  const progress = yield startProgress();
  try {
    const clients = yield Api.client.getClientsUsingGET();
    yield put(actions.setClientProps({ clients }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addClient(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const client = yield Api.client.addClient(body);
    if (body.goBack) {
      history.goBack()
    }
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeClient(action) {
  try {
    const id = action.payload.Id;
    const client = yield Api.client.removeClient(id);

    history.goBack()
  } catch (error) {
    console.error(error);
  }
}

function* refreshClient() {
  const progress = yield startProgress();
  yield getClients();
  yield endProgress(progress);
}

function* showClientDetails(action) {
  const { client } = action.payload;
  if (client.isNew) {
    yield put(actions.setClientProps({ clientDetails: client }));
    history.push(path.client.clientDetail);
  }
  else {
    const body = {
      Id: client.Id
    };
    const djrResp = yield Api.client.getClientByIdUsingPOST(body);
    yield put(actions.setClientProps({ clientDetails: djrResp }));
    history.push(path.client.clientDetail);
  }
}

function* getClientUsers(action) {
  const { client } = action.payload;
  const body = {
    Id: client.Id
  };
  const userRes = yield Api.client.getClientUsersUsingPOST(body);
  yield put(actions.setClientProps({ clientUsers: userRes }));
}


export default function* saga() {
  yield all([
    takeLatest(actions.GET_CLIENTS, getClients),
    takeLatest(actions.ADD_CLIENT, addClient),
    takeLatest(actions.REMOVE_CLIENT, removeClient),
    takeLatest(actions.REFRESH_CLIENTS, refreshClient),
    takeLatest(actions.CLIENT_DETAILS, showClientDetails),
    takeLatest(actions.CLIENT_USERS, getClientUsers),
  ]);
}
