import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button, DialogActions, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from '@mui/styles/makeStyles';
import { SlideUp } from "components/common/transitions";
import moment from "moment";
import { useEffect, useState } from "react";
const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-end",
        width: '100%',

    },
    topPaperScrollBody: {
        margin: '0',
        width: '100%',
        maxWidth: '650px'
    },
});

export default function MultiDateSelectionPopup({ open, onClose, onSaveConfirm, formData }) {
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (formData.StartDate && formData.EndDate) {
            const startMoment = moment(formData.StartDate, 'YYYY-MM-DD HH:mm');
            const endMoment = moment(formData.EndDate, 'YYYY-MM-DD HH:mm');
            const st = moment(formData.StartDate, 'YYYY-MM-DD HH:mm').format('HH:mm')
            const et = moment(formData.EndDate, 'YYYY-MM-DD HH:mm').format('HH:mm')
            const tempData = [];

            let currentDate = startMoment.clone();

            while (currentDate.isBefore(endMoment)) {
                const sdt = moment(currentDate.format('YYYY-MM-DD') + ' ' + st, 'YYYY-MM-DD HH:mm');
                const edt = moment(currentDate.format('YYYY-MM-DD') + ' ' + et, 'YYYY-MM-DD HH:mm');
                const newEdt = edt.isBefore(sdt) ? edt.add(1, 'd') : edt;
                tempData.push({
                    id: tempData.length + 1,
                    StartDate: sdt.format('YYYY-MM-DD HH:mm'),
                    EndDate: newEdt.format('YYYY-MM-DD HH:mm')
                });
                currentDate = currentDate.clone().add(1, 'day');
            }
            setData(tempData);
        }

    }, [formData]);


    const handleStartDateChange = (event, id) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === id);
        const date = event.target.value;

        const startDate = moment(date);
        const endDate = moment(newData[index].EndDate, 'YYYY-MM-DD HH:mm');

        const newEndDate = endDate.isBefore(startDate) ?
            startDate : endDate
        newData[index] = { ...newData[index], StartDate: startDate.format('YYYY-MM-DD HH:mm'), EndDate: newEndDate.format('YYYY-MM-DD HH:mm') };
        setData(newData);
    };


    const handleEndDateChange = (event, id) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === id);
        const date = event.target.value;
        const endDate = moment(date);
        const startDate = moment(newData[index].StartDate, 'YYYY-MM-DD HH:mm');

        const newStartDate = endDate.isBefore(startDate) ?
            endDate : startDate
        newData[index] = { ...newData[index], EndDate: endDate.format('YYYY-MM-DD HH:mm'), StartDate: newStartDate.format('YYYY-MM-DD HH:mm') };
        setData(newData);
    };

    // const handleDateChange = (event, id) => {
    //     const newData = [...data];
    //     const index = newData.findIndex((item) => item.id === id);
    //     const date = event.target.value;
    //     const currentDate = moment(date)
    //     const formattedDate = currentDate.format('YYYY-MM-DD');
    //     newData[index] = { ...newData[index], date: formattedDate };
    //     setData(newData);
    // };

    // const handleStartTimeChange = (event, id) => {
    //     const newData = [...data];
    //     const index = newData.findIndex((item) => item.id === id);
    //     const newStartTime = moment('01-01-2000 ' + event.target.value, 'DD-MM-YYYY HH:mm');
    //     newData[index] = { ...newData[index], startTime: newStartTime.format('HH:mm') };
    //     setData(newData);
    // };

    // const handleEndTimeChange = (event, id) => {
    //     const newData = [...data];
    //     const index = newData.findIndex((item) => item.id === id);
    //     const newEndTime = moment('01-01-2000 ' + event.target.value, 'DD-MM-YYYY HH:mm');
    //     newData[index] = { ...newData[index], endTime: newEndTime.format('HH:mm') };
    //     setData(newData);
    // };

    const onDelete = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    const onCopy = (row) => {
        const newData = [...data];
        newData.push({
            ...row,
            id: newData.length + 1,
        });
        setData(newData);
    };

    const calculateHourDifference = (startDate, endDate) => {
        const diffInMs = new Date(endDate) - new Date(startDate);
        return diffInMs / (1000 * 60 * 60); // Convert milliseconds to hours
    }
    const onSaveClick = () => {
        if (data.length > 0) {
            let showAlert = false;
            data.forEach(item => {
                const diffHours = calculateHourDifference(item.StartDate, item.EndDate);
                if (diffHours > 15) {
                    showAlert = true;
                }
            });
            if (showAlert) {
                alert("Alert: Difference between start and end date is more than 15 hours.");
            } else {
        console.log('Data to save:', data);
        onSaveConfirm(data);
            }

        } else {
            alert('No data to save');
        }
    };

    return (
        <Dialog open={open}
        // sx={{ width: "700px" }}
            onClose={onClose}
            TransitionComponent={SlideUp}
            classes={{
                scrollPaper: classes.topScrollPaper,
                paperScrollPaper: classes.topPaperScrollBody
            }}
        >
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Date</TableCell> */}
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <TextField sx={{ fontSize: '10px' }} type="datetime-local" value={row.StartDate} onChange={(e) => handleStartDateChange(e, row.id)} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField type="datetime-local" value={row.EndDate} onChange={(e) => handleEndDateChange(e, row.id)} />
                                    </TableCell>
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            label="Start Date"
                                            value={row.StartDate}
                                            onChange={(e) => handleStartDateChange(e, row.id)}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            label="End Date"
                                            value={row.EndDate}
                                            onChange={(e) => handleEndDateChange(e, row.id)}
                                        />
                                    </LocalizationProvider> */}
                                    {/* <TableCell>
                                        <TextField type="date" value={row.date} onChange={(e) => handleDateChange(e, row.id)} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="time"
                                            value={row.startTime}
                                            onChange={(e) => handleStartTimeChange(e, row.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="time"
                                            value={row.endTime}
                                            onChange={(e) => handleEndTimeChange(e, row.id)}
                                        />
                                    </TableCell> */}
                                    <TableCell>

                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size='small'
                                            sx={{ marginRight: '5px' }}
                                            onClick={() => onDelete(index)}
                                        >
                                            <DeleteIcon ></DeleteIcon>
                                        </Button>
                                        <Button
                                            size='small'
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => onCopy(row)}
                                        >
                                            <AddIcon></AddIcon>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}

                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSaveClick}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
