/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Roster from '../model/Roster';
import TimeSlots from "../model/TimeSlots";
import WeekDays from "../model/WeekDays";
import APIEndPoints from "./ApiEndpoints";

/**
* CardsController service.
* @module api/RosterControllerApi
* @version 1.0
*/
export default class RosterControllerApi {

    /**
    * Constructs a new RosterControllerApi. 
    * @alias module:api/RosterControllerApi
    * @class
    * @param {module:ApiClient} [apiRoster] Optional API Roster implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiRoster) {
        this.apiRoster = apiRoster || ApiClient.instance;
        this.endPoints = new APIEndPoints();
    }




    /**
     * getRoster
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Roster>} and HTTP response
     */
    getRostersUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [Roster];
        return this.apiRoster.callApi(
            this.endPoints.Roster.GetAll.URI,
            this.endPoints.Roster.GetAll.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getRoster
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Roster>}
     */
    getRostersUsingGET() {
        return this.getRostersUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }



    /**
   * getRoster
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Roster>} and HTTP response
   */
    getWeekDaysUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [WeekDays];
        return this.apiRoster.callApi(
            this.endPoints.Roster.GetAllWeekDays.URI,
            this.endPoints.Roster.GetAllWeekDays.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getRoster
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Roster>}
     */
    getWeekDaysUsingGET() {
        return this.getWeekDaysUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }



    /**
* getRoster
* @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Roster>} and HTTP response
*/
    getTimeSlotsUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [TimeSlots];
        return this.apiRoster.callApi(
            this.endPoints.Roster.GetAllTimeSlots.URI,
            this.endPoints.Roster.GetAllTimeSlots.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getRoster
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Roster>}
     */
    getTimeSlotsUsingGET() {
        return this.getTimeSlotsUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }



    /**
         * getRoster
         * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Roster>} and HTTP response
         */
    getUsersRosterUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [Roster];
        return this.apiRoster.callApi(
            this.endPoints.Roster.GetClientUserRosters.URI,
            this.endPoints.Roster.GetClientUserRosters.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getRoster
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Roster>}
     */
    getUsersRosterUsingGET() {
        return this.getUsersRosterUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
     * getAddRoster
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Roster>} and HTTP response
     */
    addRosterWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = Roster;
        return this.apiRoster.callApi(
            this.endPoints.Roster.Post.URI,
            this.endPoints.Roster.Post.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddRoster
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Roster>}
     */
    addRoster(body) {
        return this.addRosterWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
 * getRoster
 * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Roster>} and HTTP response
 */
    removeRosterWithHttpInfo(id) {
        let postBody = null;

        let pathParams = {
            id: id
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = Roster;
        return this.apiRoster.callApi(
            this.endPoints.Roster.Delete.URI,
            this.endPoints.Roster.Delete.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getRoster
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Roster>}
     */
    removeRoster(id) {
        return this.removeRosterWithHttpInfo(id)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    getRosterByIdUsingPOST(body) {
        return this.getRosterByIdUsingPOSTWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }

    getRosterByIdUsingPOSTWithHttpInfo(body) {

        let postBody = body;

        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = Roster;
        return this.apiRoster.callApi(
            this.endPoints.Roster.GetById.URI,
            this.endPoints.Roster.GetById.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }


}
