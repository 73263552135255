
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Autocomplete, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemText, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import defaultValue from "assets/data/default-value";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import PhoneValidator from "components/common/input/PhoneValidator";
import _, { chain, isEmpty, trim } from "lodash";
import moment from "moment";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import CancelCreedDialog from "./CancelCreedDialog";
import MultiDateSelectionPopup from "./Multi-date-selection-popup";
import CreedRequestUsersList from "./User-Creeds-List";

// import NavigationBar from "components/common/input/NavigationBar";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  form: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  // column: {
  //   [theme.breakpoints.up('sm')]: {
  //     flex: '0 0 50%',
  //     padding: theme.spacing(2),
  //   },
  // },
}));
export default function CreedRequestDetails({ CreedDetails = {}, requestType = 'Upcoming' }) {


  const classes = useStyles();
  const { users, LoginUserRole, LoginUserId } = useSelector(prop("user"));
  const [assignToOptions, setAssignToOptions] = useState([]);
  const { projects, usersProject } = useSelector(prop("project"));
  const { siteContactSuggestionByProject } = useSelector(prop("creedRequest"));
  const { creeds } = useSelector(prop('creed'));
  const [filteredCreedList, setfilteredCreedList] = useState([])
  const [siteContactSuggestionByProjectList, setSiteContactSuggestionByProjectList] = useState([])
  const [openMultiDateSelectionDialog, setopenMultiDateSelectionDialog] = React.useState(false);
  const [dateRange, setDateRange] = useState({});
  const [uniqueStrings, setUniqueStrings] = useState({ uniqueComments: [], uniqueWorkScope: [], uniqueContactName: [], uniqueContactEmail: [], uniqueContactPhoneNumber: [], uniqueLocation: [] });
  const dispatch = useDispatch();

  const getOptions = () => {
    if (LoginUserRole === 'clientuser') {
      dispatch(actions.getUsersProjects());
    } else {
      dispatch(actions.getProjects());
    }
    dispatch(actions.getCreeds());

  };
  useEffect(getOptions, []);

  useEffect(() => {
    if (creeds && CreedDetails) {
      let proj = CreedDetails?.Project
      if (proj) {
        setfilteredCreedList(creeds.filter(k => k.Department === proj?.Department))
        setAssignToOptions(users?.filter((usr) => usr.IsActive && usr.Departments?.some(d => d.Department?.toLowerCase() === proj?.Department?.toLowerCase())));
      } else {
        setfilteredCreedList(creeds)
        setAssignToOptions(users?.filter((usr) => usr.IsActive));
      }
    }
  }, [creeds, CreedDetails])
  useEffect(() => {
    let projectAddress = projects?.find(proj => proj.Id === form?.Project?.Id)?.ProjectAddresses || [];
    projectAddress = projectAddress.map(add => add.Location)
    if (siteContactSuggestionByProject && siteContactSuggestionByProject.length > 0) {
      setSiteContactSuggestionByProjectList(siteContactSuggestionByProject);
      const trimAndLowerCase = (str) => {
        const trimmed = (str || '').trim().toLowerCase();
        return trimmed === '' ? null : trimmed;
      };

      const filterAndMap = (array, key) => {
        return _.uniqBy(array, item => trimAndLowerCase(item[key]))
          .filter(item => trimAndLowerCase(item[key]) !== null)
          .map(item => item[key]);
      };

      let tempProjLoc = filterAndMap(siteContactSuggestionByProject, 'Location');
      if (projectAddress && projectAddress.length > 0) {
        tempProjLoc.push(...projectAddress);
      }
      tempProjLoc = _.uniq(tempProjLoc);
      setUniqueStrings({
        uniqueComments: filterAndMap(siteContactSuggestionByProject, 'Comment'),
        uniqueWorkScope: filterAndMap(siteContactSuggestionByProject, 'WorkScope'),
        uniqueContactName: filterAndMap(siteContactSuggestionByProject, 'ContactName'),
        uniqueContactEmail: filterAndMap(siteContactSuggestionByProject, 'ContactEmail'),
        uniqueContactPhoneNumber: filterAndMap(siteContactSuggestionByProject, 'ContactPhoneNumber'),
        uniqueLocation: tempProjLoc
      });
    }
    else {
      setSiteContactSuggestionByProjectList([])
      setUniqueStrings({
        uniqueComments: [],
        uniqueWorkScope: [],
        uniqueContactName: [],
        uniqueContactEmail: [],
        uniqueContactPhoneNumber: [],
        uniqueLocation: projectAddress,
      });

    }
  }, [siteContactSuggestionByProject])

  const creedRequest = CreedDetails || {};
  const { Creed = null,
    StartDate = null,
    EndDate = null,
    Status = null,
    StartTime = null,
    EndTime = null,
    Project = null,
    ProjectAddress = null,
    isNew = false,
    Id = null,
    Comment = '',
    WorkScope = '',
    Location = '',
    UserCreedsNotify = [],
    ContactName = '',
    ContactEmail = '',
    ContactPhoneNumber = '',

    FillInFor = null,
    QB = false,

  } = creedRequest;
  const [form, setForm] =
    useState({
      Creed: Creed,
      // Client: Project?.ProjectClient?.Name || '',
      StartDate: StartDate,
      EndDate: EndDate,
      Project: Project,
      Location: Location,
      StartTime: StartTime,
      EndTime: EndTime,
      Id: Id,
      Comment: Comment,
      WorkScope: WorkScope,
      UserCreedsNotify: UserCreedsNotify,
      ContactName: ContactName,
      ContactEmail: ContactEmail,
      ContactPhoneNumber: ContactPhoneNumber,
      Status: Status,
      QB: QB,

      FillInFor: FillInFor,
    });

  useEffect(() => {
    if (form.Project) {
      setForm({ ...form, Client: form.Project?.ProjectClient?.Name || '' })
      dispatch(actions.siteContactSuggestionByProject({ Id: form.Project.Id }));

    } else {
      setForm({ ...form, Client: '' })
    }
  }, [form.Project])

  const hasEmptyFields = chain({
    ...form,
  })
    .omit(["Id", "UserCreedsNotify", "Status", "ContactEmail", "Comment", "FillInFor"])
    .mapValues(trim)
    .some(isEmpty)
    .value();




  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "StartDate") {
      let newEndDate = form.EndDate;
      if (moment(newEndDate).isBefore(e)) {
        newEndDate = e
        setForm({ ...form, StartDate: e, EndDate: e })
        return;
      }
    }
    if (prop === "EndDate") {
      let newStartDate = form.StartDate;
      if (moment(newStartDate).isAfter(e)) {
        newStartDate = e
        setForm({ ...form, StartDate: e, EndDate: e });
        return;
      }
    }
    if (prop === "QB" || prop === "IsFieldStaff" || prop === "SendEmail" || prop === "SendSMS") {
      updateField(prop, e.target.checked);
    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = hasEmptyFields;

  const disabled = Status === "Approved";
  const onApprove = (data) => {
    // alert(data.Id);
    dispatch(actions.approveCreedRequest({ Id: data.Id, goBack: true }));
  }
  const onReassign = (data) => {
    // alert(data.Id);
    dispatch(actions.reassignCreedRequest({ Id: data.Id, goBack: true }));
  }
  const closeMultiDateSelecionDialog = () => {
    setopenMultiDateSelectionDialog(false);
  };

  const saveCallBack = (body) => {
    dispatch(actions.addCreedRequest(body));
  };

  const onMultiDateSelectClose = (data) => {
    let body = { ...form, goBack: true };
    body = processBody(body, data);
    saveCallBack(body);
  };


  const onAdd = () => {
    let body = { ...form, goBack: true }
    const startDate = moment(form.StartDate).format("YYYY-MM-DD");
    const endDate = isNew ? moment(body.EndDate).format("YYYY-MM-DD") : startDate;
    // const rangeSelected = isNew ? !moment(startDate).isSame(endDate) : false;
    let st = generateMomentDateTime(startDate, body.StartTime);
    let et = generateMomentDateTime(endDate, body.EndTime);

    if (isNew) {
      setDateRange({ StartDate: st, EndDate: et });
      setopenMultiDateSelectionDialog(true);
    } else {
      body = processBody(body, []);
      saveCallBack(body);
    }

  };


  const generateMomentDateTime = (date, time) => {
    return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
  };

  const processBody = (body, data = []) => {
    const startDate = moment(body.StartDate).format("YYYY-MM-DD");
    const endDate = moment(body.EndDate).format("YYYY-MM-DD");
    const rangeSelected = isNew;

    let st = generateMomentDateTime(startDate, body.StartTime);
    let et = generateMomentDateTime(endDate, body.EndTime);

    const endDateTime = moment(st).isAfter(et) ? moment(startDate + " " + body.EndTime, "YYYY-MM-DD HH:mm").add(1, 'd') : et;

    body.StartDate = moment(st).format(defaultValue.saveDateTimeFormat);
    body.EndDate = moment(endDateTime).format(defaultValue.saveDateTimeFormat);
    body.RangeSelected = rangeSelected;
    body.Title = body?.Title?.Id || "";
    body.Intervals = [...data];

    return body;
  };
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const onCancel = () => {
    setOpenCancelDialog(true);
  }
  const onCancelConfirm = () => {
    dispatch(actions.cancelCreedRequest({
      creedRequest: creedRequest,
      requestType: requestType,
      goBack: true
    }));
  }

  const closeDialog = () => {
    setOpenCancelDialog(false);
  };

  const [showContactSuggestions, setShowContactSuggestions] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const handleContactSuggestions = () => {
    setShowContactSuggestions(true);
  };

  const handleCloseContactSuggestions = () => {
    setShowContactSuggestions(false);
  };

  const handleContactSelection = (contact) => {
    updateField("ContactPhoneNumber", contact);
    handleCloseContactSuggestions();
  };

  return (
    <>
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit Coverage</> : <>Request Coverage</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            <Grid container spacing={2} className={classes.form}>
              <Grid item xs={12} sm={6} className={classes.column}>
                <Autocomplete
                  disabled={disabled}
                  id="project-type-select"
                  options={LoginUserRole === 'clientuser' ? usersProject : projects}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Name"
                      variant="standard"
                      margin="normal"
                      disabled={disabled}
                      fullWidth
                    />
                  )}
                  // getOptionLabel={(option) => option.ProjectName}
                  getOptionLabel={(option) =>
                    `${option.ProjectNo} (${option.ProjectName})`
                  }
                  value={form?.Project}
                  onChange={(_event, newValue) => {
                    setForm({ ...form, "Project": newValue, Creed: null });
                    const proj = newValue;
                    setfilteredCreedList(creeds.filter(k => k.Department === proj?.Department))
                    // setAssignToOptions(users?.filter((usr) => usr.IsActive && usr.Departments?.some(d => d.Department.toLowerCase() === proj?.Department?.toLowerCase())));
                    setAssignToOptions(users?.filter((usr) => usr.IsActive && usr.Departments?.some(d => d.Department?.toLowerCase() === proj?.Department?.toLowerCase())));
                    // updateField("Project", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <Autocomplete
                  id="creed"
                  name="Credentials"
                  options={filteredCreedList?.filter(k => k.ShowToClients)}
                  sx={{ width: "100%" }}
                  disabled={disabled}
                  renderInput={(params) => (
                    <TextField
                      disabled={disabled}
                      {...params}
                      label="Job Position"
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  getOptionLabel={(option) => option.CreedName}

                  value={form?.Creed}
                  onChange={(_event, newValue) => {
                    updateField("Creed", newValue);
                  }}
                />
              </Grid>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={12} sm={6} className={classes.column}>
                  <MobileDatePicker
                    name="StartDate"
                    label="Start Date"
                    disabled={disabled}
                    value={form?.StartDate}
                    onChange={handleChange("StartDate")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={disabled}
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {isNew && (
                  <Grid item xs={12} sm={6} className={classes.column}>
                    <MobileDatePicker
                      name="EndDate"
                      label="End Date"
                      disabled={disabled}
                      value={form?.EndDate}
                      onChange={handleChange("EndDate")}
                      renderInput={(params) => (
                        <TextField
                          disabled={disabled}
                          {...params}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} className={classes.column}>
                  <TextField
                    id="start-time"
                    label="Start Time"
                    type="time"
                    fullWidth
                    variant="standard"
                    disabled={disabled}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={form?.StartTime}
                    onChange={handleChange("StartTime")}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.column}>
                  <TextField
                    id="end-time"
                    label="End Time"
                    type="time"
                    disabled={disabled}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={form?.EndTime}
                    onChange={handleChange("EndTime")}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12} sm={12} md={12} className={classes.column}></Grid>

              <Grid item xs={12} sm={6} md={6} className={classes.column}>


                <Autocomplete
                  freeSolo
                  options={uniqueStrings.uniqueContactName}
                  value={form.ContactName}
                  // onChange={handleChange("ContactName")}
                  onChange={(_event, newValue) => {
                    updateField("ContactName", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Site Contact Name"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      disabled={disabled}
                      data-testid="input-ContactName"
                      onBlur={handleBlur("ContactName")}
                      autoComplete="off"
                    />
                  )}
                />
              </Grid>               

              <Grid item xs={12} sm={6} className={classes.column}>

                <Autocomplete
                  freeSolo
                  options={uniqueStrings.uniqueContactEmail}
                  value={form.ContactEmail}
                  // onChange={handleChange("ContactEmail")}
                  onChange={(_event, newValue) => {
                    updateField("ContactEmail", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Site Contact Email"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      disabled={disabled}
                      data-testid="input-ContactEmail"
                      onBlur={handleBlur("ContactEmail")}
                      validators={["isEmail"]}
                      autoComplete="off"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column} sx={{ position: 'relative' }}>
                <PhoneValidator
                  defaultCountry="us"
                  disableAreaCodes
                  countryCodeEditable={false}
                  label="Site Contact Phone number"
                  value={form?.ContactPhoneNumber}
                  onChange={handleChange("ContactPhoneNumber")}
                  fullWidth
                  disabled={disabled}
                  margin="normal"
                  validators={["isPhone"]}
                  errorMessages={["The Mobile number is not valid"]}
                  data-testid='input-MobileNo-number'
                />
                <IconButton
                  onClick={handleContactSuggestions}
                  color="primary"
                  aria-label="Choose Contact"
                  style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 0 }}
                >
                  <PersonOutlineIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classes.column}>


                <Autocomplete
                  freeSolo
                  options={uniqueStrings.uniqueLocation}
                  value={form.Location}
                  // onChange={handleChange("Location")}
                  onChange={(_event, newValue) => {
                    updateField("Site Location", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Site Location"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      disabled={disabled}
                      data-testid="input-Location"
                      onBlur={handleBlur("Location")}
                      autoComplete="off"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} className={classes.column}>
                <Autocomplete
                  freeSolo
                  options={uniqueStrings.uniqueComments}
                  value={form?.Comment}                  
                  // onChange={handleChange("Comments")}
                  onChange={(_event, newValue) => {
                    updateField("Comment", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Comments"
                      variant="standard"
                      fullWidth
                      multiline
                      rows={2}
                      margin="normal"
                      disabled={disabled}
                      data-testid="input-Comment"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classes.column}>
                <Autocomplete
                  freeSolo
                  options={uniqueStrings.uniqueWorkScope}
                  value={form?.WorkScope}
                  // onChange={handleChange("WorkScope")}
                  onChange={(_event, newValue) => {
                    updateField("WorkScope", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Scope of Work"
                      variant="standard"
                      fullWidth
                      multiline
                      rows={2}
                      margin="normal"
                      disabled={disabled}
                      data-testid="input-WorkScope"
                      onBlur={handleBlur("WorkScope")}
                    />
                  )}
                />
              </Grid>
              {
                (LoginUserRole === 'admin' && !isNew) &&
                <Grid item xs={12} sm={4} md={4} className={classes.column}>
                  <FormGroup sx={{ m: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultValue='true'
                          checked={form?.QB}
                          onChange={handleChange("QB")}
                        />
                      }
                      label="QB"
                    />
                  </FormGroup>
                </Grid>
              }
              <Grid item xs={12} sm={12} md={12} className={classes.column}></Grid>

              {
                (LoginUserRole === 'admin' && !isNew) &&
                <Grid item xs={12} sm={6} md={6} className={classes.column}>
                  <Autocomplete
                    id="user-type-select"
                    options={assignToOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fill In For"
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                    disabled={disabled}
                    // disabled={!isNew ? (Djr || InspectionPDF.lenght > 0) : false}
                    getOptionLabel={(option) => option.Name}
                    value={form?.FillInFor}
                    onChange={(_event, newValue) => {
                      updateField("FillInFor", newValue);
                    }}
                  />
                </Grid>
              }
            </Grid>
            {
              (LoginUserRole === 'admin' && !isNew) &&
              <Grid item xs={12} sm={12} md={12} className={classes.column}>
                <CreedRequestUsersList
                    userCreeds={CreedDetails?.UserCreedsNotify || []}
                  onApprove={(data) => { onApprove(data) }}
                    onReassign={(data) => { onReassign(data) }}
                  updateUserCreeds={(newValue) => {
                    updateField("UserCreedsNotify", newValue);
                    }}
                    creedRequest={creedRequest}
                    project={form.Project}
                  >
                </CreedRequestUsersList>
              </Grid>
            }
          </ValidatorForm>
        </Container>
        {/* {!isNew && LoginUserRole !== "staff" ? (
          <>
            <PrimaryButton
              onClick={onCancel}
              // disabled={submitDisabled}
              data-testid="btn-remove-task"
              color="error"
            >
              Cancel
            </PrimaryButton>
          </>
        ) : (
          <></>
        )} */}
        <FlexBox justifyContent="center">
          {!isNew ? (
            <>
              <PrimaryButton
                onClick={onAdd}
                disabled={submitDisabled}
                data-testid="btn-remove-user"
              >
                Save
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton onClick={onAdd} data-testid="btn-add-user" disabled={submitDisabled}>
              Submit
            </PrimaryButton>
          )}
        </FlexBox>
        <MultiDateSelectionPopup
          onClose={closeMultiDateSelecionDialog}
          onSaveConfirm={onMultiDateSelectClose}
          open={openMultiDateSelectionDialog}
          formData={dateRange}
        ></MultiDateSelectionPopup>
        <CancelCreedDialog
          open={openCancelDialog}
          onClose={closeDialog}
          onRemoveConfirm={onCancelConfirm}
        ></CancelCreedDialog>
        <Dialog open={showContactSuggestions} onClose={handleCloseContactSuggestions}>
          <DialogTitle>Contact Suggestions</DialogTitle>
          <DialogContent>
            <List>
              {uniqueStrings.uniqueContactPhoneNumber.map((contact, index) => (
                <ListItem button key={index} onClick={() => handleContactSelection(contact)}>
                  <ListItemText primaryTypographyProps={{ variant: "subtitle1", color: "primary" }}>
                    {contact}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseContactSuggestions} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </Container>
    </>
  );
}
