import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const CONTRACTOR_SET_PROPS = "CONTRACTOR_SET_PROPS";

export const GET_CONTRACTORS = "GET_CONTRACTORS";
export const ADD_CONTRACTOR = "ADD_CONTRACTOR";
export const REMOVE_CONTRACTOR = "REMOVE_CONTRACTOR";
export const REFRESH_CONTRACTORS = "REFRESH_CONTRACTORS";
export const CONTRACTOR_DETAILS = "CONTRACTOR_DETAILS";

export const setContractorProps = payloadAction(CONTRACTOR_SET_PROPS);

export const getContractors = simpleAction(GET_CONTRACTORS);
export const addContractor = payloadAction(ADD_CONTRACTOR);
export const removeContractor = payloadAction(REMOVE_CONTRACTOR);
export const refreshVirtualCard = simpleAction(REFRESH_CONTRACTORS);
export const showContractorDetails = (contractor) =>
  createAction(CONTRACTOR_DETAILS, { contractor });
