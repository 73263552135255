import AddIcon from '@mui/icons-material/Add';
import { Box, Container, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import { prop } from "ramda";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeaveListItem from "./LeaveListItem";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
}));

export default function LeaveList({ height}) {
  const classes = useStyles();
  const handleClick = (leave) => () => {dispatch(actions.showLeaveDetails(leave));};
  // const onAddNewLeave = (leave) => () => {
  //   dispatch(actions.showLeaveDetails({ ...leave, isNew: true }));
  // };
  const onAddNewLeave = (leave = {}) => {
    dispatch(actions.showLeaveDetails({ ...leave, isNew: true }));
  }


  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getLeaves());
  }

  useEffect(updateAll, []);
  const { leaves } = useSelector(prop('leave'));

  return (
    <>
    <BackAppBar />
    <Container>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-leave-administration">
          Vacation/DayOff
          </strong>
        </Typography>
          {/* <Typography
          color="textSecondary"
          data-testid="label-leave-administration-secondary-text"
        >
          Add as many Vacation/DayOff as you want.
        </Typography> */}
      </Box>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-leave-administration-list-leave">
            A list of Vacation/DayOff
          </strong>
        </Typography>
      </Box>
      <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
          <ListItem
            dense
            disableGutters
            onClick={onAddNewLeave}
            className={classes.item}
            data-testid={'li-add-leave'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a Leave'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem>
          {leaves?.map((leave) => (
            <LeaveListItem
              key={leave.Id}
              leave={leave}
              onClick={handleClick(leave)}
              itemClasses={classes.item}
              
            />
          ))}

        </ul>
      </List>
    </Container>
    </>
  );
}
