import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    // minHeight: '100vh',
    backgroundColor: theme.palette.common.bgGray
  }
}));

export default function BgView({children}) {
  const classes = useStyles();
  return (
      <div className={classes.root}>
        {children}
      </div>
  );
}
