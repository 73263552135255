import AddIcon from '@mui/icons-material/Add';
import { Box, Container, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import { prop } from "ramda";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HolidayListItem from "./HolidayListItem";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
}));

export default function HolidayList({ height, onItemSelect, onAddNewHoliday }) {
  const classes = useStyles();
  const handleClick = (holiday) => () => !!onItemSelect && onItemSelect(holiday);
  const handelNewHolidayClick = () => !!onAddNewHoliday && onAddNewHoliday();


  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getHolidays());
  }

  useEffect(updateAll, []);
  const { holidays } = useSelector(prop('holiday'));

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
          Holiday
          </strong>
        </Typography>
        {/* <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many holiday as you want.
        </Typography> */}
      </Box>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of holiday
          </strong>
        </Typography>
      </Box>
      <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
        <ListItem
            dense
            disableGutters
            onClick={onAddNewHoliday}
            className={classes.item}
            data-testid={'li-add-user'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a Holiday'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem>
          {holidays?.map((holiday) => (
            <HolidayListItem
              key={holiday.Id}
              holiday={holiday}
              onClick={handleClick(holiday)}
              itemClasses={classes.item}
              
            />
          ))}

      
        </ul>
      </List>
    </Container>
  );
}
