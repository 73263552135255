import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Typography} from "@mui/material";

const useStyles = makeStyles(theme => ({
  gutterTop: {
    marginTop: theme.spacing(4),
  },
}));

export default function ViewTitle({children, gutterTop = false}) {

  const classes = useStyles();

  const rootClasses = gutterTop ? classes.gutterTop : '';

  return (
      <Typography variant="h5" gutterBottom className={rootClasses}>
        <strong>{children}</strong>
      </Typography>
  );
}