/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LoginResponse model module.
 * @module model/LoginResponse
 * @version 1.0
 */
class LoginResponse {
    /**
     * Constructs a new <code>LoginResponse</code>.
     * @alias module:model/LoginResponse
     */
    constructor() { 
        
        LoginResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LoginResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LoginResponse} obj Optional instance to populate.
     * @return {module:model/LoginResponse} The populated <code>LoginResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoginResponse();

            if (data.hasOwnProperty('accessExpires')) {
                obj['accessExpires'] = ApiClient.convertToType(data['accessExpires'], 'Number');
            }
            if (data.hasOwnProperty('accessLiveTime')) {
                obj['accessLiveTime'] = ApiClient.convertToType(data['accessLiveTime'], 'Number');
            }
            if (data.hasOwnProperty('accessToken')) {
                obj['accessToken'] = ApiClient.convertToType(data['accessToken'], 'String');
            }
            if (data.hasOwnProperty('refreshToken')) {
                obj['refreshToken'] = ApiClient.convertToType(data['refreshToken'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
            }
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} accessExpires
 */
LoginResponse.prototype['accessExpires'] = undefined;

/**
 * @member {Number} accessLiveTime
 */
LoginResponse.prototype['accessLiveTime'] = undefined;

/**
 * @member {String} accessToken
 */
LoginResponse.prototype['accessToken'] = undefined;

/**
 * @member {String} refreshToken
 */
LoginResponse.prototype['refreshToken'] = undefined;

/**
 * @member {module:model/LoginResponse.StatusEnum} status
 */
LoginResponse.prototype['status'] = undefined;

/**
 * @member {String} userId
 */
LoginResponse.prototype['userId'] = undefined;

/**
 * @member {String} userName
 */
LoginResponse.prototype['userName'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
LoginResponse['StatusEnum'] = {

    /**
     * value: "NEW"
     * @const
     */
    "NEW": "NEW",
 

    /**
     * value: "REGISTERED"
     * @const
     */
    "REGISTERED": "REGISTERED",


    /**
     * value: "REJECTED"
     * @const
     */
    "REJECTED": "REJECTED"
};



export default LoginResponse;

