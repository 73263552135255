import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import BgView from "components/common/BgView";
import moment from "moment";
import { prop } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicTable from "./dynamic-mta-table";

const columns = [
    { id: 'Name', label: 'Name' },
    { id: '35305', label: '35305' },
    { id: '33931', label: '33931' },
    { id: 'MNR100106733', label: 'MNR 100106733' },
];

const data = [
    {
        Name: "Dennis Sanchez",
        "A35305": "SC",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "Rejected",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Justin Lebron",
        "A35305": "Pending",
        "D35305": "10/25/2023",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "Rejected",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Willard Roche",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Justin Roche",
        "A35305": "",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "       ",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Brian O'Donnel",
        "A35305": "",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Bruce Smith",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "James Rosas",
        "A35305": "Pending",
        "D35305": "10/25/2023",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "CSS",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Malcolm Punch",
        "A35305": "SC",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "1/27/23",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Kevin Lesesne",
        "A35305": "Pending",
        "D35305": "10/25/2023",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "Rejected",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Harris Christos",
        "A35305": "SC",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "SC",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Peter Calderon",
        "A35305": "SC",
        "D35305": "",
        "A33931": "Rejected",
        "D33931": "",
        "AMNR100106733": "Rejected",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "CSS",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Yomi Rasaki",
        "A35305": "",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Nelson Rowland",
        "A35305": "",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "Rejected",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "David Montero",
        "A35305": "SC",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Seretze Hayle",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "1/30/23"
    },
    {
        Name: "Juan Montero",
        "A35305": "SC",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "",
        "AMNR100106733": "Rejected",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Johnny Rodriguez",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "CSS",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "John Bello",
        "A35305": "Removed",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Ivan Garzon",
        "A35305": "SC",
        "D35305": "",
        "A33931": "Rejected",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "CSS",
        "D - MC-852 ": "",
        "A-37693": "1/27/23"
    },
    {
        Name: "Gilwyn Ferguson",
        "A35305": "SC",
        "D35305": "",
        "A33931": "CSS",
        "D33931": "03-02-2022",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Kristt Basile",
        "A35305": "",
        "D35305": "",
        "A33931": "Rejected",
        "D33931": "",
        "AMNR100106733": "Rejected",
        "DMNR100106733": "",
        "C-48731": "10/26/23",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Elliott Agosto (NO ROW)",
        "A35305": "",
        "D35305": "",
        "A33931": "NO ROW",
        "D33931": "",
        "AMNR100106733": "CSE",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "NO ROW",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Anthony H.",
        "A35305": "SC",
        "D35305": "",
        "A33931": "Pending",
        "D33931": "10/25/2023",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Alvaro L.",
        "A35305": "SC",
        "D35305": "",
        "A33931": "Pending",
        "D33931": "10/25/2023",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Nick Pennisi",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Lloyd Daley",
        "A35305": "SC",
        "D35305": "",
        "A33931": "Pending",
        "D33931": "10/25/2023",
        "AMNR100106733": "CSS",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "Rejected",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Gregory Eldridge",
        "A35305": "SC",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Wilfredo Pesante",
        "A35305": "SC",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "9/19/23",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Lorenzo Woods",
        "A35305": "SC",
        "D35305": "",
        "A33931": "SC",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "04-11-2023",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Geury Ramos",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Jerry Lampe",
        "A35305": "",
        "D35305": "",
        "A33931": "Pending",
        "D33931": "10/25/2023",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Syed Abbas",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Jeffry Abreu",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Samuel Alva",
        "A35305": "NO ROW",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Jose Pagan",
        "A35305": "Pending",
        "D35305": "10/25/2023",
        "A33931": "Pending",
        "D33931": "10-10-2023",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "10/23/23",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10-10-2023"
    },
    {
        Name: "Steven Rivera",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": ""
    },
    {
        Name: "Matthew Cellura",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    },
    {
        Name: "Adam Pizarro",
        "A35305": "",
        "D35305": "",
        "A33931": "",
        "D33931": "",
        "AMNR100106733": "",
        "DMNR100106733": "",
        "C-48731": "10/23/23",
        "MC-852 East Side Access": "",
        "D - MC-852 ": "",
        "A-37693": "10/25/23"
    }
];

export default function MTAApprovalView() {
    const dispatch = useDispatch();
    const { projects, projectSpecifications } = useSelector(prop('project'));
    const { mtas, mtaNotes } = useSelector(prop('mta'));
    const { users, LoginUserRole, seletedRole } = useSelector(prop("user"));

    useEffect(() => {

        // dispatch(actions.getMTAs());
        // dispatch(actions.getMTANotes());
        if (mtas.length == 0) {

            dispatch(actions.getMTACombine());
        }
        if (projectSpecifications.length == 0) {

            dispatch(actions.getSpecification());
        }

    }, [])
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    useEffect(() => {
        const columns = [
            {
                id: "Name",
                label: "Name",
                sortable: true,
            }
        ];
        if (projects) {
            projects.filter(k => k?.Department?.toLowerCase()?.includes('mta')).forEach((project) => {
                columns.push({
                    id: project.Id,
                    label: `${project.ProjectNo}`,
                    sortable: true,
                    status: project.ProjectStatus
                })
            })
        }
        setColumns(columns)
    }, [projects])

    const userHasAllCertificatesRequired = (projectSpec, userCreeds) => {
        let flag = 'No';
        for (let i = 0; i < projectSpec.length; i++) {
            let projSpec = projectSpec[i];
            if (projSpec.Value == false) {
                continue;
            }
            let found = userCreeds.find(k => k?.Creed?.Id == projSpec?.Creed?.Id);
            // alert(JSON.stringify(found))
            if (found) {
                // console.log(found);
                if (found.Creed.CreedFieldType == "Date" && !found.NoExpiration) {
                    let exp = moment(found.ExpirationDate);
                    if (exp.isAfter(moment(new Date()))) {
                        flag = 'Yes';
                    } else {
                        flag = 'No';
                        break;
                    }
                }
                else {
                    flag = 'Yes';
                }
            }
            else {
                flag = 'No';
                break;
            }
        }
        // alert(flag)
        return flag;
    }
    useEffect(() => {
        let tempData = []
        if (users && users.length > 0 && projects && projects.length > 0) {
            users
                // .filter(k => ['mta'].includes(k?.Department?.toLowerCase()))
                .filter(k => k.Departments?.some(d => ['mta'].includes(d?.Department?.toLowerCase())))
                .forEach(user => {
                    let matProjData = {};
                    projects?.forEach(proj => {
                        let pid = proj.Id;
                        let currProjSpec = projectSpecifications.filter(k => k.Project.Id == proj.Id);
                        let flag = userHasAllCertificatesRequired(currProjSpec, user.UserCreeds);
                        matProjData[pid] = {
                            'HASSPEC': flag
                        };
                    })

                    mtas
                        .filter(mta => mta?.__User__?.Id == user.Id)
                        .map(mtaUs => {
                            let proj = mtaUs.__Project__;
                            let creed = mtaUs.__Creed__;
                            if (proj.Id) {
                                let pid = proj.Id;
                                matProjData[pid] = matProjData[pid] || {};
                                matProjData[pid] = {
                                    ...matProjData[pid],
                                    CN: creed.CreedName,
                                    CID: creed.Id,
                                    D: moment(mtaUs.Date).isValid() ? moment(mtaUs.Date).format("MM-DD-YY") : null,
                                    BC: creed.BackgroundColor,
                                    TC: creed.TextColor,
                                    HASSPEC: creed.CreedName ? 'No' : matProjData.HASSPEC
                                };

                                // matProjData['A' + pid] = creed.CreedName;
                                // matProjData['D' + pid] = mtaUs.Date;
                                // matProjData['BC' + pid] = creed.BackgroundColor;
                                // matProjData['TC' + pid] = creed.TextColor;                                
                            }
                        });

                    mtaNotes?.forEach(note => {
                        let proj = note.__Project__;
                        let us = note.__User__;
                        if (us.Id == user.Id) {
                            matProjData[proj.Id] = matProjData[proj.Id] || {};
                            matProjData[proj.Id] = {
                                ...matProjData[proj.Id],
                                N: note.Note != '' ? note.Note : null
                            }
                        }
                    })
                    let temp = {
                        Name: user.Name,
                        UserId: user.Id,
                        IsActive: user.IsActive,
                        ...matProjData
                    };
                    tempData.push(temp)
                });
            setData(tempData)
        }
    }, [users, mtas, projectSpecifications, projects, mtaNotes])


    return (
        <BgView>
            <BackAppBar /> 
            <DynamicTable data={data} columns={columns} />
        </BgView>

    );
}

