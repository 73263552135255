import {APP_SET_PROPS} from 'actions/app';
import {propsReducer} from "utils/stateUtils";

const DEFAULT_STATE = {
  version :process.env.APP_VERSION,
  initialPath: '/',
  isDeveloper: false,
};

export default propsReducer(APP_SET_PROPS, DEFAULT_STATE);
