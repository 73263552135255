import { all, put, takeLatest, select } from "redux-saga/effects";

import * as actions from "../actions";
import Api from "services/api";
import { endProgress, failProgress, startProgress } from "sagas/common";
import { prop } from "ramda";
import history from "../history";
import path from "routesPath";

function* getLeaves() {
  const progress = yield startProgress();
  try {
    const { selectedUser } = yield select(prop("leave"));
    const param = {
      AssignedTo: { Id: selectedUser.Id }
    };
    const leaves = yield Api.leave.getLeavesUsingPOST(param);
    yield put(actions.setLeaveProps({ leaves }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addLeave(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const leave = yield Api.leave.addLeave(body);
    history.goBack();
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeLeave(action) {
  try {
    const body = action.payload;
    const param = {
      "Id":body
    }
    const leave = yield Api.leave.removeLeave(param);

    history.goBack();
  } catch (error) {
    console.error(error);
  }
}

function* refreshLeave() {
  const progress = yield startProgress();
  yield getLeaves();
  yield endProgress(progress);
}

function* showLeaveDetails(action) {
  const { leave } = action.payload;
  const { selectedUser } = yield select(prop("leave"));
  // if(leave.isNew) {
  //   leave = {...leave, AssignedTo:selectedUser}
  // }
  yield put(actions.setLeaveProps({ leaveDetails: leave }));
  history.push(path.leaves.leaveDetail);
}

export default function* saga() {
  yield all([
    takeLatest(actions.GET_LEAVES, getLeaves),
    takeLatest(actions.ADD_LEAVE, addLeave),
    takeLatest(actions.REMOVE_LEAVE, removeLeave),
    takeLatest(actions.REFRESH_LEAVES, refreshLeave),
    takeLatest(actions.LEAVE_DETAILS, showLeaveDetails),
  ]);
}
