/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import MTA from "../model/MTA";
import MTANotes from '../model/MTANotes';
import APIEndPoints from "./ApiEndpoints";

/**
* CardsController service.
* @module api/MTAControllerApi
* @version 1.0
*/
export default class MTAControllerApi {

    /**
    * Constructs a new MTAControllerApi. 
    * @alias module:api/MTAControllerApi
    * @class
    * @param {module:ApiClient} [apiMTA] Optional API MTA implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiMTA) {
        this.apiMTA = apiMTA || ApiClient.instance;
        this.endPoints = new APIEndPoints();
    }




    /**
     * getMTA
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MTA>} and HTTP response
     */
    getMTAsUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [MTA];
        return this.apiMTA.callApi(
            this.endPoints.MTA.GetAll.URI,
            this.endPoints.MTA.GetAll.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getMTA
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MTA>}
     */
    getMTAsUsingGET() {
        return this.getMTAsUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }
    getMTANotesUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [MTANotes];
        return this.apiMTA.callApi(
            this.endPoints.MTA.GetNotes.URI,
            this.endPoints.MTA.GetNotes.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getMTA
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MTA>}
     */
    getMTANotesUsingGET() {
        return this.getMTANotesUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }



    /**
         * getMTA
         * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MTA>} and HTTP response
         */
    getUsersMTAUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [MTA];
        return this.apiMTA.callApi(
            this.endPoints.MTA.GetClientUserMTAs.URI,
            this.endPoints.MTA.GetClientUserMTAs.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getMTA
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MTA>}
     */
    getUsersMTAUsingGET() {
        return this.getUsersMTAUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
     * getAddMTA
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MTA>} and HTTP response
     */
    addMTAWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = MTA;
        return this.apiMTA.callApi(
            this.endPoints.MTA.Post.URI,
            this.endPoints.MTA.Post.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddMTA
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MTA>}
     */
    addMTA(body) {
        return this.addMTAWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }

    addMTANoteWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = MTA;
        return this.apiMTA.callApi(
            this.endPoints.MTA.Note.URI,
            this.endPoints.MTA.Note.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddMTA
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MTA>}
     */
    addMTANote(body) {
        return this.addMTANoteWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
 * getMTA
 * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MTA>} and HTTP response
 */
    removeMTAWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = MTA;
        return this.apiMTA.callApi(
            this.endPoints.MTA.Delete.URI,
            this.endPoints.MTA.Delete.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getMTA
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MTA>}
     */
    removeMTA(id) {
        return this.removeMTAWithHttpInfo(id)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    getMTAByIdUsingPOST(body) {
        return this.getMTAByIdUsingPOSTWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }

    getMTAByIdUsingPOSTWithHttpInfo(body) {

        let postBody = body;

        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = MTA;
        return this.apiMTA.callApi(
            this.endPoints.MTA.GetById.URI,
            this.endPoints.MTA.GetById.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }


}
