import { propsReducer } from "utils/stateUtils";
import { DJR_SET_PROPS } from "actions/djr";



const DEFAULT_STATE = {
    djrs: [],
    djrDetails: {},
    qrDetails: null,
    djrResponse: null,
    djrSummary:{
        "PendingToday": "0",
        "Total": "0",
        "PendingTillDate": "0"
    },
    type:null
    

};

export default propsReducer(DJR_SET_PROPS, DEFAULT_STATE);
