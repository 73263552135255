import { CREED_REQUEST_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
  creedRequests: [],
  creedRequestDetails: null,
  completedCr: [],
  upcomingCr: [],
  feedbackDataSet: [],
  selectedDateRange: [],
};

export default propsReducer(CREED_REQUEST_SET_PROPS, DEFAULT_STATE);
