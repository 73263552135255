import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import cn from "classnames";

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  dense: {
    padding: theme.spacing(1),
  },
}));

export default function PaperTile({className, dense, onClick, children}) {
  const classes = useStyles();
  const paperClasses = cn(classes.paper, dense && classes.dense, className);
  return (
      <Paper elevation={0} className={paperClasses} onClick={onClick}>
        {children}
      </Paper>
  );
}