import {CORDOVA_SET_PROPS} from 'actions/cordova';

const initialState = {
  device: {},

  notSecureDevice: undefined,
  blurForAuth: false,

  fingerprint: {
    credentials: '',
  },

  isReady: false,
  isOffline: false,
};

const cordovaReducer = (state = initialState, action) => {
  switch (action.type) {
    case CORDOVA_SET_PROPS:
      return { ...state, ...action.payload };
    default:
  }
  return state;
};

export default cordovaReducer;
