import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";


import defaultValue from "assets/data/default-value";

import * as actions from "actions";
import { prop } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import dropDownOption from "assets/data/dropdown-options";
import storage from "services/storage";
import SchedulerCalender from "./SchedulerCalender";
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  card: {
    marginBottom: 5,
  },
  cardContent: {
    padding: "10px 5px!important",
  },
  paddingRight: {
    paddingRight: "5px!important",
  },
}));
export default function FlatScheduler() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { LoginUserRole } = useSelector(prop("user"));
  const { selectedDepartmentValue, selectedDateRange, fieldStaffChecked, view } =
    useSelector(prop("task"));
  const [others, setOthers] = useState([]);

  const [allowAdd, setAllowAdd] = useState();
  const [value, setValue] = React.useState(null);
  const [searchText, setSearchText] = useState('');

  const [category, setCategory] = useState();
  const departments = dropDownOption.Departments;
  const [datePickerInitialized, setDatePickerInitialized] =
    React.useState(false);

  const startOfWeek = (date) => {
    return moment(date).startOf("week").toDate().toISOString();
  };
  const endOfWeek = (date) => {
    return moment(date).endOf("week").toDate().toISOString();
  };
  const [dateRangeValue, setDateRangeValue] = React.useState([
    startOfWeek(new Date()),
    endOfWeek(new Date()),
  ]);
  const [dateIndexToDateMap, setDateIndexToDateMap] = React.useState({});
  const [gridColumns, setGridColumns] = React.useState([]);
  useEffect(() => {
    if (selectedDateRange && selectedDateRange[0] && selectedDateRange[1]) {
      let tempMap = {};
      const dates = [moment(selectedDateRange[0]), moment(selectedDateRange[1])];
      const range = moment.range(dates);
      let counter = 0;
      let columns = [];
      for (let month of range.by('days')) {
        let tempCol = { Id: month.format('YYYYMMDD'), Date: month.format('DD MMM YY'), Day: month.format('dddd') };
        columns.push(tempCol);
        tempMap[counter] = month.format('DD MMM YY');
        counter++;
      }
      setGridColumns(columns);
      setDateIndexToDateMap(tempMap);
    }

  }, [selectedDateRange])

  useEffect(() => {
    const todayDate = new Date();
    const selectedStartWeek = selectedDateRange[0] ? selectedDateRange[0] : startOfWeek(todayDate);
    const selectedEndWeek = selectedDateRange[1] ? selectedDateRange[1] : endOfWeek(todayDate);
    setDateRangeValue([selectedStartWeek, selectedEndWeek]);
    setDatePickerInitialized(true);
    const selectedCategory = selectedDepartmentValue ? selectedDepartmentValue : departments[0].Id;
    setCategory(selectedCategory)
    dispatch(
      actions.setTaskProps({
        selectedDateRange: [selectedStartWeek, selectedEndWeek]
      })
    );
  }, []);

  const selectedDate = moment();
  const from_date = selectedDate.startOf("week");
  moment.updateLocale("en", {
    week: {
      dow: 1,
    },
  });
  const getDayNumber = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const getTasks = () => {
    // const cat
    console.log(LoginUserRole, departments);
    if (LoginUserRole) {
      let cat = "staff";
      if (category) {
        cat = category;
      } else {
        cat = getCategory();
      }
      const dateRangeFormValue = {
        StartDate: moment(selectedDateRange[0]).format(
          defaultValue.dateTimeFormat
        ),
        EndDate: moment(selectedDateRange[1]).format(defaultValue.dateTimeFormat),
        Department: cat,
        // GroupBy: LoginUserRole == 'admin' ? 'Project' : 'User'
        GroupBy: 'User'
      };
      storage.set('selectedDepartmentValue')(cat);
      dispatch(actions.getTasks(dateRangeFormValue));
    }
  };

  const getCategory = () => {
    switch (LoginUserRole) {
      case "admin":
        return departments[0].Id;
      default:
        return "mta";
    }
  };
  const updateTask = () => {
    // let insp = [];
    // let survey = [];
    // let tech = [];
    // let other = [];
    // tasks.forEach((k) => {
    //   if (k.User.StaffCategory === "survey") {
    //     survey.push(k);
    //   } else if (k.User.StaffCategory === "inspection") {
    //     insp.push(k);
    //   } else if (k.User.StaffCategory === "technology") {
    //     tech.push(k);
    //   } else {
    //     other.push(k);
    //   }
    // });
    // setInspectionTeam(insp);
    // setSurveyTeam(survey);
    // setTechnologyTeam(tech);


    let filteredList = tasks ? [...tasks] : [];

    if (fieldStaffChecked) {
      filteredList = filteredList.filter(k => k.User.IsFieldStaff);
    }
    if (searchText.length > 0) {
      filteredList = filteredList.filter(k => k.User?.Name?.toLowerCase().includes(searchText)
        || k.User?.Email?.toLowerCase().includes(searchText)
        || k.User?.PhoneNo?.toLowerCase().includes(searchText)
      );
    }
    setOthers(filteredList);
  };

  useEffect(() => {
    if (datePickerInitialized) {
      getTasks();
    }
  }, [selectedDateRange, category, LoginUserRole]);
  const { tasks } = useSelector(prop("task"));


  useEffect(updateTask, [tasks, fieldStaffChecked, searchText]);

  const onDateChange = (date) => {
    setDateRangeValue([date[0], date[1]]);
  };


  const handleDepartmentChange = (event) => {
    dispatch(
      actions.setTaskProps({ selectedDepartmentValue: event.target.value })
    );
    setCategory(event.target.value);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }



  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              overflowX: "scroll",
            }}
          >
            {LoginUserRole ? (
              <>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Department
                  </InputLabel>
                  <Select
                    defaultValue={departments[0].Id}
                    labelId="department-select-standard-label"
                    id="department-select-standard"
                    value={category || ""}
                    onChange={handleDepartmentChange}
                    label="Department(s)"
                  >
                    {departments?.map((department) => (
                      <MenuItem value={department.Id}>{department.Name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </>
            ) : (
              <></>
            )}
            {/* <MobileDateRangePicker
              sx={{ width: 200, m: 1 }}
              disabled
              inputFormat="MM/dd/yy"
              startText="Start Date"
              endText="End Date"
              value={dateRangeValue}
              onChange={(newValue) => {
                onDateChange(newValue);
              }}
              onClose={() => {
                dispatch(
                  actions.setTaskProps({
                    selectedDateRange: dateRangeValue
                  })
                );
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} variant="standard" />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField {...endProps} variant="standard" />
                </React.Fragment>
              )}
            />
            {<DesktopDatePicker
              className={classes.paddingRight}
              label="For desktop"
              value={value}
              minDate={new Date("2017-01-01")}
              onChange={(newValue) => {
                onDateChange(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input
                    ref={inputRef}
                    {...inputProps}
                    style={{ visibility: "Hidden", width: 0 }}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />} */}
          </Box>
        </Stack>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}></LocalizationProvider>
      {/* <Box sx={{ mt: 1, mb: 0, maxWidth: '580px' }}>
        <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
      </Box> */}

      {others.length > 0 && (
        <>
          <SchedulerCalender
            data={others}
            view={view}
            onDateChange={({ start, end }) => {
              dispatch(
                actions.setTaskProps({
                  selectedDateRange: [start, end]
                })
              );
            }}
            onView={(view) => {
              dispatch(
                actions.setTaskProps({
                  view: view
                })
              );
            }}
          ></SchedulerCalender>

          {/* <SchedulerTable
            title="Other"
            tableData={others}
            getDayNumber={getDayNumber}
            columns={gridColumns}
            allowAdd={allowAdd}
            dateIndexToDateMap={dateIndexToDateMap}
          ></SchedulerTable> */}
        </>
      )}
    </>
  );
}
