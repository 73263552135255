import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const ROSTER_SET_PROPS = "ROSTER_SET_PROPS";

export const GET_ROSTERS = "GET_ROSTERS";
export const GET_WEEKDAYS = "GET_WEEKDAYS";
export const GET_TIMESLOTS = "GET_TIMESLOTS";
export const ADD_ROSTER = "ADD_ROSTER";
export const REMOVE_ROSTER = "REMOVE_ROSTER";
export const REFRESH_ROSTERS = "REFRESH_ROSTERS";
export const ROSTER_DETAILS = "ROSTER_DETAILS";
export const ROSTER_USERS = "ROSTER_USERS";

export const setRosterProps = payloadAction(ROSTER_SET_PROPS);

export const getRosters = simpleAction(GET_ROSTERS);
export const getWeekdays = simpleAction(GET_WEEKDAYS);
export const getTimeSlots = simpleAction(GET_TIMESLOTS);
export const addRoster = payloadAction(ADD_ROSTER);
export const removeRoster = payloadAction(REMOVE_ROSTER);
export const refreshVirtualCard = simpleAction(REFRESH_ROSTERS);
export const showRosterDetails = (Roster) =>
    createAction(ROSTER_DETAILS, { Roster });

export const getRosterUsers = (Roster) =>
    createAction(ROSTER_USERS, { Roster });