import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const CREED_SET_PROPS = "CREED_SET_PROPS";

export const GET_CREEDS = "GET_CREEDS";
export const ADD_CREED = "ADD_CREED";
export const REMOVE_CREED = "REMOVE_CREED";
export const REFRESH_CREEDS = "REFRESH_CREEDS";
export const CREED_DETAILS = "CREED_DETAILS";
export const CREED_SAVE_ORDER = 'CREED_SAVE_ORDER';

export const setCreedProps = payloadAction(CREED_SET_PROPS);

export const getCreeds = simpleAction(GET_CREEDS);
export const addCreed = payloadAction(ADD_CREED);
export const removeCreed = payloadAction(REMOVE_CREED);
export const refreshVirtualCard = simpleAction(REFRESH_CREEDS);
export const saveCreedOrder = payloadAction(CREED_SAVE_ORDER);
export const showCreedDetails = (creed) =>
  createAction(CREED_DETAILS, { creed });
