import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Container, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";


import PrimaryButton from "components/common/PrimaryButton";
import { chain, isEmpty, trim } from "lodash";
import PropTypes from "prop-types";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  ".MuiDialog-paper": {
    // backgroundColor:'red'
    margin: "5px",
    width: "100%",
    borderRadius: "10px",
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: "100vh",

    backgroundColor: theme.palette.background.paper,
  },
  title: {
    display: "flex",
    justifyItems: "center",
    justifyContent: "center",
  },
  top: {

    backgroundColor: theme.palette.background.paper,
  },
  container: {
    // position: "relative",

    padding: theme.spacing(2),
  },
  bottomBox: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px" }} {...other}>
      <strong> {children}</strong>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{

            position: "absolute!important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddClientUserDialogue({ handleClose, open, data, updateClientUsers }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { users } = useSelector(prop('user'));
  const [filteredUsers, setFilteredUsers] = useState();
  useEffect(() => {
    if (users && users.length > 0) {
      setFilteredUsers(users.filter(k => k.Role === 'clientuser'))
    }
  }, [users])

  const client = data || {};
  const {
    User = null,
    isNew = false,
    Id = null,
  } = client;
  const [form, setForm] = useState({
    User: User,
    Id: Id,
  });
  const hasEmptyFields = chain({
    ...form,
  })
    .omit(["Id"])

    .mapValues(trim)
    .some(isEmpty)
    .value();

  const onAdd = () => {
    let body = { ...form };
    updateClientUsers(body);
  };

  const disabled = false;
  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    updateField(prop, e && e.target ? e.target.value : e);
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());
  const submitDisabled = hasEmptyFields;


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.title}
        >
          Add Client User
        </BootstrapDialogTitle>
        <DialogContent>
          <Container className={classes.container}>
            <ValidatorForm instantValidate={true} autoComplete="off">
              <Autocomplete
                id="user"
                name="User"
                options={filteredUsers}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client User"
                    variant="standard"
                    margin="normal"
                    fullWidth
                  />
                )}
                getOptionLabel={(option) => option.Name}
                value={form?.User}
                onChange={(_event, newValue) => {
                  updateField("User", newValue);
                }}
              />
            </ValidatorForm>
          </Container>
        </DialogContent>
        <DialogActions>
          <PrimaryButton autoFocus onClick={onAdd} disabled={submitDisabled}>
            Save
          </PrimaryButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
