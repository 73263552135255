import CloseIcon from '@mui/icons-material/Close';
import {
    Autocomplete,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    styled
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import PrimaryButton from 'components/common/PrimaryButton';
import { prop } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formattedTime } from 'utils/strUtils';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    ".MuiDialog-paper": {
        // backgroundColor:'red'
        margin: "5px",
        width: "100%",
        borderRadius: "10px",
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        // minHeight: "100vh",

        backgroundColor: theme.palette.background.paper,
    },
    title: {
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
        fontSize: "24px",
    },
    top: {

        backgroundColor: theme.palette.background.paper,
    },
    container: {
        // position: "relative",

        padding: theme.spacing(2),
    },
    bottomBox: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px" }} {...other}>
            <strong> {children}</strong>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{

                        position: "absolute!important",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const EditRosterDialogue = ({ handleClose, open, data, onUpdate, Project }) => {
    const classes = useStyles();
    const [masterRosterData, setMasterRosterData] = useState({});

    const { users, LoginUserRole, seletedRole } = useSelector(prop("user"));
    const [timeSlotsList, setTimeSlotsList] = useState([]);
    const { roster, timeSlots, weekDays } = useSelector(prop('roster'));
    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch time slots and Master Roster data from your backend APIs
        async function fetchData() {
            try {
                let temoSlots = timeSlots.map(k => ({ id: k.Id, time: `${k.SlotName} (${formattedTime(k.StartTime)} - ${formattedTime(k.EndTime)})` }));
                setTimeSlotsList(temoSlots)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [timeSlots, weekDays]);

    useEffect(() => {
        if (data && data.MasterRoster && timeSlotsList.length > 0) {
            let temp = {};
            data.MasterRoster.forEach(k => {
                temp[k.Weekday.Id] = temp[k.Weekday.Id] || [];
                temp[k.Weekday.Id].push(timeSlotsList.find(l => l.id == k.TimeSlot.Id));

            });
            setMasterRosterData(temp);
        } else {
            setMasterRosterData({})
        }
    }, [data, timeSlotsList])
    const handleTimeSlotChange = (newValue, dayID) => {
        const updatedData = { ...masterRosterData };
        updatedData[dayID] = newValue || [];
        setMasterRosterData(updatedData);
    };

    const handleSave = async () => {
        try {
            let dataForSave = [];
            for (let k in masterRosterData) {
                masterRosterData[k].map(currTS => {
                    let tempObj = {
                        Weekday: weekDays.find(l => l.Id == k),
                        TimeSlot: timeSlots.find(l => l.Id == currTS.id),
                        User: users.find(user => user.Id === data.UserId)
                    };
                    dataForSave.push(tempObj);

                });
            }

            // if (dataForSave.length > 0)
                dispatch(actions.addRoster(dataForSave));

            handleClose();
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.title}>
                    Weekly Schedule
                </BootstrapDialogTitle>
                <DialogContent sx={{ width: '450px' }}>
                    <Container className={classes.container}>
                        <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
                            <thead>
                                <tr style={{ border: '1px solid #ddd' }}>
                                    <th style={{ border: '1px solid #ddd', padding: '8px', width: '100px' }}>Day</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Time Slots</th>
                                </tr>
                            </thead>
                            <tbody>
                                {weekDays.map((day, index) => (
                                    <tr key={index} style={{ border: '1px solid #ddd' }}>
                                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{day.DayName}</td>
                                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                            <Autocomplete
                                                multiple
                                                id="staff-client"
                                                options={timeSlotsList}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Time Slots"
                                                        variant="standard"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                )}
                                                getOptionLabel={(option) => option.time}
                                                value={masterRosterData[day.Id] || []}
                                                onChange={(e, newValue) => handleTimeSlotChange(newValue, day.Id)}
                                                fullWidth
                                            />

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <PrimaryButton autoFocus onClick={handleSave}>
                        Save Changes
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EditRosterDialogue;
