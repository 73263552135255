import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete, Avatar, Box, Container, Select } from "@mui/material";
import FlexBox from "components/common/FlexBox";
import Typography from "@mui/material/Typography";
import TextValidator from "components/common/input/TextValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import PrimaryButton from "components/common/PrimaryButton";
import { TextField } from "@mui/material";
import { chain, isEmpty, trim } from "lodash";
import * as actions from "actions";
import { useSelector, useDispatch } from "react-redux";
// import * as moment from 'moment';
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import defaultValue from "assets/data/default-value";
import { MobileDatePicker, MobileDateTimePicker } from "@mui/lab";
import BgView from "components/common/BgView";
import BackAppBar from "components/app/BackAppBar";
import dropDownOption from "assets/data/dropdown-options";
import { prop } from "ramda";
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    dateComp: {
        marginTop: 20,
        paddingBottom: 30,
    },
    fullWidth: {
        width: "100%",
    },
}));
export default function ReportView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const categorys = dropDownOption.StaffCategorys;
    const { LoginUserRole } = useSelector(prop("user"));

    const defaultStartTime = moment().set("hour", "07").set("minute", "00");
    const defaultEndTime = moment().set("hour", "15").set("minute", "30");



    const [form, setForm] = useState({
        StartDate: defaultStartTime,
        EndDate: defaultEndTime,
        StaffCategory: null

    });
    const hasEmptyFields = chain({
        ...form,
    })
        .mapValues(trim)
        .some(isEmpty)
        .value()

    const onAdd = () => {
        let body = { ...form };
        body.StartDate = body?.StartDate
            ? moment(body.StartDate).format(defaultValue.saveDateTimeFormat)
            : "";
        body.EndDate = body?.EndDate
            ? moment(body.EndDate).format(defaultValue.saveDateTimeFormat)
            : "";
        body.Category = body.StaffCategory.Id
        dispatch(actions.downloadReport(body));
    };

    const disabled = false;

    const updateField = (prop, value) => setForm({ ...form, [prop]: value });
    const handleChange = (prop) => (e) => {
        updateField(prop, e && e.target ? e.target.value : e);
    };

    const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

    const submitDisabled = hasEmptyFields;

    const getCategory = () => {

        switch (LoginUserRole) {
            case "admin":
                return categorys[0];
            case "surveyhead":
                return categorys.find(k => k.Id === "survey");
            case "inspectionhead":
                return categorys.find(k => k.Id === "inspection");
            case "technologyhead":
                return categorys.find(k => k.Id === "technology");
            default:
                return categorys.find(k => k.Id === "staff");
        }
    };
    useEffect(() => {
        // const cat = categorys.find(k => k.id === getCategory())
        const cat = getCategory();
        updateField("StaffCategory", cat);
    }, [LoginUserRole]);


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <>
            <BgView>
                <BackAppBar />
                <Container className={classes.root}>
                    <FlexBox className={classes.top}>
                        <Box flexGrow={1} pl={2}>
                            <Typography variant="h5">
                                <strong data-testid="label-user-administration">
                                    Download Report
                                </strong>
                            </Typography>
                        </Box>
                    </FlexBox>

                    <Container className={classes.container}>
                        <ValidatorForm instantValidate={true} autoComplete="off">

                            <Autocomplete
                                id="staff-category-select"
                                options={categorys}
                                disabled={LoginUserRole !== "admin"}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Staff Department"
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                                getOptionLabel={(option) => option.Name}
                                value={form?.StaffCategory}
                                onChange={(_event, newValue) => {
                                    updateField("StaffCategory", newValue);
                                }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        label="Start Date"
                                        value={form?.StartDate}
                                        onChange={handleChange("StartDate")}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <MobileDatePicker
                                        label="End Date"
                                        value={form?.EndDate}
                                        onChange={handleChange("EndDate")}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </ValidatorForm>
                    </Container>
                    <FlexBox justifyContent="center" flexDirection="column">

                        <>
                            <PrimaryButton
                                onClick={onAdd}
                                data-testid="btn-add-user"
                                disabled={submitDisabled}
                            >
                                Download
                            </PrimaryButton>
                        </>

                    </FlexBox>
                    {/* <Container>
                        <div>
                            <Document file="./assets/somefile.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} />
                            </Document>
                            <p>
                                Page {pageNumber} of {numPages}
                            </p>
                        </div>
                    </Container> */}
                </Container>
            </BgView>
        </>
    );
}
