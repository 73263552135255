import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { formatFullDate } from 'utils/strUtils';
import AddCreedDialogue from './AddCreedDialogue';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    listItemButtons: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flex: 0
    },
}));

function UserCreedList({ userCreeds, updateUserCreeds, readonly = false, departments }) {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [openAddCreedDialogue, setOpenAddCreedDialogue] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState({});
    const [itemIndex, setItemIndex] = React.useState(-1);

    useEffect(() => {
        if (userCreeds) {
            setItems(userCreeds);
        }
    }, [userCreeds])

    const onAddCreed = (data) => {
        const tempItems = [...items];
        if (itemIndex !== -1) {
            tempItems[itemIndex] = data;
            updateUserCreeds(tempItems);

            setOpenAddCreedDialogue(false);
        } else {
            if (items.some(k => k.Creed.Id == data.Creed.Id)) {
                alert("Credential Already Added");
            } else {
                tempItems.push(data);
                updateUserCreeds(tempItems);
                setOpenAddCreedDialogue(false);


            }
        }

    };
    const closeAddCreedDialog = () => {
        setOpenAddCreedDialogue(false);
    }


    const addItem = () => {
        setOpenAddCreedDialogue(true)
        setSelectedItem({});
        setItemIndex(-1);
    };

    const handleEdit = (index) => {
        setSelectedItem(items[index]);
        // alert(JSON.stringify(items[index]));
        setItemIndex(index);

        setOpenAddCreedDialogue(true)

        // const updatedItem = prompt('Edit Item', items[index]);
        // if (updatedItem) {
        //     const newItems = [...items];
        //     newItems[index] = updatedItem;
        //     updateUserCreeds(newItems);
        // }
    };

    const handleDelete = (index) => {
        const newItems = items.filter((item, i) => i !== index);
        updateUserCreeds(newItems);
    };

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h4>User Credential(s)</h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.root}>
                    <Grid container spacing={2}> {/* added this line */}
                        {items.map((item, index) => (
                            <Grid item xs={12} sm={12} key={index}> {/* added this line */}
                                <ListItem sx={{ width: '100%' }}>
                                    <ListItemText primary={item.Creed?.CreedName}
                                        secondary={`Expiration Date - ${formatFullDate(item.ExpirationDate)}`} />
                                    <div className={classes.listItemButtons}>

                                        {!readonly &&
                                            <IconButton onClick={() => handleEdit(index)} size='large' color="primary" >
                                                <EditIcon />
                                            </IconButton>}
                                        {!readonly &&
                                            <IconButton onClick={() => handleDelete(index)} size='large' color="error" >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    </div>
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                    {!readonly &&
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={addItem}
                            sx={{ mt: 2, mb: 1, mx: 'auto', width: '50%!important' }} // add this line
                        >
                            Add New
                        </Button>

                    }

                </div>
            </AccordionDetails>

            <AddCreedDialogue
                open={openAddCreedDialogue}
                handleClose={closeAddCreedDialog}
                onAddCreed={onAddCreed}
                data={selectedItem}
                departments={departments}
            >
            </AddCreedDialogue>
        </Accordion>
    );
}

export default UserCreedList;
