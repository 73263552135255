import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { formatDateTime, getNameStr } from 'utils/strUtils';
import AddClientUserDialogue from './AddClientUserDialogue';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    listItemButtons: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flex: 0
    },
    listItemButton: {
        marginLeft: theme.spacing(1),
    },
}));

function ClientUsersList({ clientUsers, updateClientUsers }) {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [openAddCreedDialogue, setOpenAddCreedDialogue] = React.useState(false);

    useEffect(() => {
        if (clientUsers) {
            setItems(clientUsers.map(k => ({
                ...k,
                User: {
                    ...k.User,
                    Name: getNameStr(k.User)
                }
            })));
        }
    }, [clientUsers])

    const onAdd = (data) => {
        const tempItems = [...items];
        const isDuplicate = tempItems.find(k => k.User.Id === data.User.Id);
        if (!isDuplicate)
            tempItems.push(data);
        updateClientUsers(tempItems);
        setOpenAddCreedDialogue(false);
    };
    const closeAddCreedDialog = () => {
        setOpenAddCreedDialogue(false);
    }


    const addItem = () => {
        setOpenAddCreedDialogue(true)
    };

    const handleEdit = (index) => {
        const updatedItem = prompt('Edit Item', items[index]);
        if (updatedItem) {
            const newItems = [...items];
            newItems[index] = updatedItem;
            updateClientUsers(newItems);
        }
    };

    const handleDelete = (index) => {
        const newItems = items.filter((item, i) => i !== index);
        updateClientUsers(newItems);
    };

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h4>Client Users</h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.root}>
                    <Grid container spacing={2}> {/* added this line */}
                        {items.map((item, index) => (
                            <Grid item xs={12} sm={12} key={index}> {/* added this line */}
                                <ListItem sx={{ width: '100%' }}>
                                    <ListItemText primary={`${item.User.Name} `}
                                        secondary={item.User.Email} />
                                    <div className={classes.listItemButtons}>
                                        <IconButton onClick={() => handleDelete(index)} size='large' color="error" >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                    <Button fullWidth variant="contained" color="primary" onClick={addItem}>
                        Add New
                    </Button>
                </div>
            </AccordionDetails>

            <AddClientUserDialogue
                open={openAddCreedDialogue}
                handleClose={closeAddCreedDialog}
                updateClientUsers={onAdd}>
            </AddClientUserDialogue>
        </Accordion>
    );
}

export default ClientUsersList;
