import BackAppBar from "components/app/BackAppBar";
import UserList from "components/leaves/UserList";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "actions";
import path from "routesPath";

import history from "../../history";
export default function LeaveAdministartionView() {
  const dispatch = useDispatch();
  const userDetails = (user) => {
    dispatch(actions.setLeaveProps({ selectedUser: user }));
    history.push(path.leaves.leaveList);
  };
  const newUserClick = (user = {}) => {
    dispatch(actions.showUserDetails({ ...user, isNew: true }));
  };
  return (
    <>
      <BackAppBar />
      <UserList onItemSelect={userDetails} onAddNewUser={newUserClick} />
    </>
  );
}
