import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "react-redux";
import { prop } from "ramda";
import { Box, Typography } from "@mui/material";
import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";

import FlexBox from "components/common/FlexBox";
import AlertList from './AlertList';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.common.bgGray
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    total: {
        fontWeight: 'bold'
    },
    totalAmount: {
        fontWeight: 'bold'
    },
    showAll: {
        color: theme.palette.primary.main
    },
    paper: {
        padding: theme.spacing(1),
    },
    alertList: {
        marginBottom: theme.spacing(6),
    }
}));

export default function AllAlertView() {
    const classes = useStyles();

    document.body.style.backgroundColor = "white";
    const dispatch = useDispatch();
    const updateAll = () => {
        dispatch(actions.getAlerts());
    }

    useEffect(updateAll, []);
    const showDetails = (tx) => dispatch(actions.showAlertDetails(tx));
    const { alerts, totalAmount = '50' } = useSelector(prop('alert'));
 
    return (
        <>
            <BackAppBar />
            <div className={classes.container}>
                <FlexBox justifyContent="space-between" mt={1}>
                    <Box minWidth={105}>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }} data-testid='label-alert'>
                            Alerts
                        </Typography>
                    </Box>
                </FlexBox>
                <AlertList
                    alerts={alerts} onItemSelect={showDetails} className={classes.alertList} />
               
            </div>
        </>
    );
}
function TitleValue({ primary, secondary }) {
    const classes = useStyles();
    return (
        <FlexBox flexDirection="column" alignItems="flex-start" >
            <Box minWidth={105}>
                <Typography variant="h4" className={classes.total}>
                    {primary}
                </Typography>
            </Box>
            <Typography variant="subtitle1" className={classes.totalAmount}>
                {secondary}
            </Typography>
        </FlexBox>
    )
}