import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import TopAppBar from "components/app/TopAppBar";
import ProfileDrawer from "components/home/ProfileDrawer";
import { prop } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import path from "routesPath";
import history from '../../history';
export default function HomeAppBar() {

  const showAlerts = () => history.push(path.profile.alerts);
  const { newAlerts } = useSelector(prop('alert'));
  const [profileOpen, setProfileOpen] = useState(false);
  const showProfile = () => setProfileOpen(true);
  const closeProfile = () => setProfileOpen(false);

  const alertsButton = (
      <IconButton aria-label="Alerts" onClick={showAlerts} size="large">
      <Badge badgeContent={newAlerts || 0} color="error">
        <NotificationsIcon style={{ width: '30px', height: '30px' }} />
      </Badge>
      </IconButton>
  );

  const profileButton = (
    <IconButton aria-label="Profile" onClick={showProfile} size="large"
    >
      <MenuIcon style={{
        width: '30px', height: '30px'
      }} />
      </IconButton>
  );

  return (
      <>
        <TopAppBar rightNav={profileButton} leftNav={alertsButton}/>
        <ProfileDrawer open={profileOpen} onClose={closeProfile} onOpen={showProfile}/>
      </>
  );
}