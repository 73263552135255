import React from "react";
import BackAppBar from "components/app/BackAppBar";
import { useSelector } from "react-redux";
import { prop } from "ramda";
import BgView from "components/common/BgView";
import ClientDeails from "components/client/ClientDeails";

export default function ClientView() {
  const { clientDetails } = useSelector(prop('client'));
  return (
    <BgView>
      <BackAppBar />
      <ClientDeails Client={clientDetails} />
    </BgView>
  );
}
