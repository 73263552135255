import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { stopPropagation } from "utils/uiUtils";
import CheckboxValidator from './CheckboxValidator';

export default function TermsAndConditions({acceptedTerms,handleTermsAcceptChange,disableForm}) {
  return (
    <CheckboxValidator
          id="acceptedTerms"
          formControlProps={{
            component: 'fieldset',
            fullWidth: true,
            margin: 'normal',
          }}
          checked={acceptedTerms}
          value={acceptedTerms}
          color="primary"
          onChange={handleTermsAcceptChange}
          validators={['isCheckboxChecked']}
          errorMessages={['Please read and accept the terms']}
          disabled={disableForm}
        >
      {/* <Typography onClick={stopPropagation}>
            I have read and agree to
            the <Link onClick={openTermsOfService} underline="always">Terms & Conditions</Link>
            , <Link onClick={openPrivacyPolicy} underline="always">Privacy Disclosure</Link>,
            and <Link onClick={openESignConsent} underline="always">E-Sign Consent</Link>.
          </Typography>  */}

      <Typography onClick={stopPropagation}>
        I have read and agree to
        the <Link to="/terms">Privacy Policy & Terms</Link>.
          </Typography>

        </CheckboxValidator>
  );
}
