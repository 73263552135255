import BackAppBar from "components/app/BackAppBar";
import BgView from "components/common/BgView";
import { prop } from "ramda";
import React from "react";
import { useSelector } from "react-redux";
import CreedRequestDetails from "./CreedRequestDeails";

export default function CreedRequestView() {
  const { creedRequestDetails } = useSelector(prop('creedRequest'));
  return (
    <BgView>
      <BackAppBar />
      <CreedRequestDetails CreedDetails={creedRequestDetails} />
    </BgView>
  );
}
