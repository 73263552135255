import {
    all, put, takeLatest
} from "redux-saga/effects";

import path from "routesPath";
import {
    endProgress,
    failProgress,
    startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

function* getMTAs() {
    const progress = yield startProgress();
    try {
        const mtas = yield Api.MTA.getMTAsUsingGET();
        yield put(actions.setMTAProps({ mtas }));
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}
function* getMTACombine() {
    const progress = yield startProgress();
    try {
        const mtas = yield Api.MTA.getMTAsUsingGET();
        const mtaNotes = yield Api.MTA.getMTANotesUsingGET();
        yield put(actions.setMTAProps({ mtas, mtaNotes }));
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}


function* getmtaNotes() {
    const progress = yield startProgress();
    try {
        const mtaNotes = yield Api.MTA.getMTANotesUsingGET();
        yield put(actions.setMTAProps({ mtaNotes }));
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}

function* addMTA(action) {
    const progress = yield startProgress();
    try {
        const body = action.payload;
        const MTA = yield Api.MTA.addMTA(body);
        yield getMTAs();
        // history.goBack()
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}
function* addMTANote(action) {
    const progress = yield startProgress();
    try {
        const body = action.payload;
        const MTA = yield Api.MTA.addMTANote(body);
        yield getmtaNotes();
        // history.goBack()
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}
function* removeMTA(action) {
    try {
        const body = action.payload;
        const MTA = yield Api.MTA.removeMTA(body);
        yield getMTAs();
        // history.goBack()
    } catch (error) {
        console.error(error);
    }
}

function* refreshMTAs() {
    const progress = yield startProgress();
    yield getMTAs();
    yield endProgress(progress);
}

function* showMTADetails(action) {
    const { MTA } = action.payload;
    if (MTA.isNew) {
        yield put(actions.setMTAProps({ MTADetails: MTA }));
        history.push(path.MTA.MTADetail);
    }
    else {
        const body = {
            Id: MTA.Id
        };
        const djrResp = yield Api.MTA.getMTAByIdUsingPOST(body);
        yield put(actions.setMTAProps({ MTADetails: djrResp }));
        history.push(path.MTA.MTADetail);
    }

}



export default function* saga() {
    yield all([
        takeLatest(actions.GET_MTAS, getMTAs),
        takeLatest(actions.GET_MTAS_COMBINE, getMTACombine),
        takeLatest(actions.GET_MTANOTES, getmtaNotes),
        takeLatest(actions.ADD_MTA, addMTA),
        takeLatest(actions.ADDMTANOTE, addMTANote),
        takeLatest(actions.REMOVE_MTA, removeMTA),
        takeLatest(actions.REFRESH_MTAS, refreshMTAs),
        takeLatest(actions.MTA_DETAILS, showMTADetails),
    ]);
}
