import AddIcon from '@mui/icons-material/Add';
import { Container, Fab } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreedRequestList from './Creed-Request-List';
import DeleteCreedReqDialog from './DeleteCreedReqDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        position: "relative",
        overflow: "auto",
    },
    ul: {
        padding: 0,
        display: "grid",
        "grid-template-columns": "repeat(auto-fit, minmax(300px, 300px))",
        "grid-gap": "5px"
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    item: {
        border: "1px solid rgb(216,216,216)",
        // padding: "10px",
        borderRadius: "6px",
        marginBottom: "16px",
    },
    addItem: {
        border: "1px solid rgb(216,216,216)",
        padding: "10px",
        borderRadius: "6px",
        marginBottom: "16px",
    },
    bottomContainer: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        bottom: '60px',
        left: '0px'
    }
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const dispatch = useDispatch();
    const onItemSelect = (creedRequest) => {
        dispatch(actions.showCreedRequestDetails(creedRequest));
    };



    const onItemRateReview = (creedRequest) => {
        // dispatch(actions.removeCreedRequest({
        //     creedRequest: creedRequest,
        //     requestType: props.requestType,
        //     goBack: false
        // }));
        // dispatch(actions.cancelCreedRequest({
        //     creedRequest: creedRequest,
        //     requestType: props.requestType,
        //     goBack: false
        // }));
    };


    const [selItem, setselItem] = React.useState(null)
    const [isDelete, setIsDelete] = React.useState(false)

    const onItemCancel = (creedRequest) => {
        setselItem(creedRequest);
        setopenRemoveProjectDialog(true);
        setIsDelete(false);
    };


    const onItemDelete = (creedRequest) => {
        setselItem(creedRequest);
        setopenRemoveProjectDialog(true);
        setIsDelete(true);
    };
    const [openRemoveProjectDialog, setopenRemoveProjectDialog] = React.useState(false);


    const onRemoveConfirm = () => {
        if (isDelete) {
            dispatch(actions.removeCreedRequest({
                creedRequest: selItem,
                requestType: props.requestType,
                goBack: false
            }));
        }

        else {

            dispatch(actions.cancelCreedRequest({
                creedRequest: selItem,
                requestType: props.requestType,
                goBack: false
            }));
        }
        setopenRemoveProjectDialog(false);
    };
    const closeDialog = () => {
        setselItem(null);
        setopenRemoveProjectDialog(false);
    };
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                    <CreedRequestList
                        onItemSelect={onItemSelect}
                        onItemDelete={onItemDelete}
                        onItemCancel={onItemCancel}
                        onItemRateReview={onItemRateReview} {...props}></CreedRequestList>
                    <DeleteCreedReqDialog
                        creedRequest={selItem || {}}
                        open={openRemoveProjectDialog}
                        onClose={closeDialog}
                        onRemoveConfirm={onRemoveConfirm}
                        isDelete={isDelete}
                    ></DeleteCreedReqDialog>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ClientHome() {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const { LoginUserRole } = useSelector(prop("user"));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const NewCreedRequest = (client = {}) => {
        dispatch(actions.showCreedRequestDetails({ ...client, isNew: true }));
    };

    return (
        <Container disableGutters style={{ height: '100%', position: 'relative' }} maxWidth={false}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Upcoming Request" {...a11yProps(0)} />
                    <Tab label="Completed Request" {...a11yProps(1)} />
                    <Tab label="Canceled Request" {...a11yProps(2)} />
                    <Tab label="All Request" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} requestType='Upcoming'>
                {/* Upcoming Request */}
            </TabPanel>
            <TabPanel value={value} index={1} requestType='Completed'>
                {/* Completed Request */}
            </TabPanel>
            <TabPanel value={value} index={2} requestType='Canceled'>
                {/* Completed Request */}
            </TabPanel>
            <TabPanel value={value} index={3} requestType='All'>
                {/* Completed Request */}
            </TabPanel>
            {
                (LoginUserRole === "admin" || LoginUserRole === "clientuser") &&
                <>
                    <Box sx={{ '& > :not(style)': { m: 1 } }} className={classes.bottomContainer}>
                        <Fab variant="extended" size="medium" color="primary" aria-label="add" onClick={NewCreedRequest}>
                            <AddIcon sx={{ mr: 1 }} />
                            Request Coverage
                        </Fab>
                    </Box>
                </>


            }

        </Container>
    );
}
