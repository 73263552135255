import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemAvatar } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

export default function SubContractorListItem({
  subContractor,
  onClick,
  itemClasses,
}) {
  const { Name } = subContractor;
  const handleClick = (e) => onClick && onClick(e);

  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      className={itemClasses}
      data-testid={"li-item-" + subContractor.id}
    >
      <ListItemAvatar>
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
        primary={Name}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
