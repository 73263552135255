/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Holiday from "../model/Holiday";
import APIEndPoints from "./ApiEndpoints";

/**
 * CardsController service.
 * @module api/HolidayControllerApi
 * @version 1.0
 */
export default class HolidayControllerApi {
  /**
   * Constructs a new HolidayControllerApi.
   * @alias module:api/HolidayControllerApi
   * @class
   * @param {module:ApiClient} [apiHoliday] Optional API holiday implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiHoliday) {
    this.apiHoliday = apiHoliday || ApiClient.instance;
    this.endPoints = new APIEndPoints();
  }

  /**
   * getHoliday
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Holiday>} and HTTP response
   */
  getHolidaysUsingGETWithHttpInfo() {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = [Holiday];
    return this.apiHoliday.callApi(
      this.endPoints.holiday.GetAll.URI,
      this.endPoints.holiday.GetAll.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getHoliday
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Holiday>}
   */
  getHolidaysUsingGET() {
    return this.getHolidaysUsingGETWithHttpInfo().then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * getAddHoliday
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Holiday>} and HTTP response
   */
  addHolidayWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = Holiday;
    return this.apiHoliday.callApi(
      this.endPoints.holiday.Post.URI,
      this.endPoints.holiday.Post.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getAddHoliday
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Holiday>}
   */
  addHoliday(body) {
    return this.addHolidayWithHttpInfo(body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  removeHoliday(body) {
    return this.removeHolidayWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  removeHolidayWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = Holiday;
    return this.apiHoliday.callApi(
      this.endPoints.holiday.Delete.URI,
      this.endPoints.holiday.Delete.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }
}
