import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import BgView from "components/common/BgView";
import { prop } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicMTASpecificationTable from "./DynamicMTASpecificationTable";

const FIXED_COLUMNS = [

    {
        id: "Agency",
        label: "Agency",
        sortable: false,
        department: ['mta', 'dob']
    },
    {
        id: "ProjectNo",
        label: "Contract No",
        sortable: false,
        department: ['mta', 'dob']
    },
    {
        id: "ProjectName",
        label: "Project Name",
        sortable: false,
        department: ['mta', 'dob']
    }, {
        id: "ProjectStatus",
        label: "Status",
        sortable: false,
        department: ['mta', 'dob']
    },
    // {
    //     id: "DOB40HSSM",
    //     label: "DOB 40H \n SSM",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "OSHA30HR",
    //     label: "OSHA  \n 30HR",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "ASBESTOSINSPECTOR",
    //     label: "Asbestos \n  Inspector",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "AMPPC3C5",
    //     label: "AMPP  \n C3/C5",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "ASSPMEMBERSHIP",
    //     label: "ASSP  \n Membership",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "NYCTTRACKSAFETYTRAINED",
    //     label: "NYCT Track  \n Safety trained",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "LIRRTRACKCARD",
    //     label: "LIRR  \n Track \n  Card",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "MNRCARD",
    //     label: "MNR \n  CARD",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "AMTRACKCARD",
    //     label: "AMTRACK  \n Card",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "SIRCARD",
    //     label: "SIR  \n  Card",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "CSXTRAINING",
    //     label: "CSX  \n Training",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "CHST",
    //     label: "CHST",
    //     sortable: false,
    //     department: ['mta']

    // },

    // {
    //     id: "NINTYDROWNYCT",
    //     label: "90D ROW \n  NYCT",
    //     sortable: false,
    //     department: ['mta']

    // },

    // {
    //     id: "NINTYDROWMNR",
    //     label: "90D ROW \n  MNR",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "NINTYDROWLIRR",
    //     label: "90D ROW  \n LIRR",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "NINTYDROWAMTRACK",
    //     label: "90D ROW  \n AMTRACK",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "NINTYDROWCSX",
    //     label: "90D ROW \n CSX",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "TWOYSAFETYCOORDINATOR",
    //     label: "2Y Safety  \n Coordinator",
    //     sortable: false,
    //     department: ['mta']

    // },
    // {
    //     id: "FIVEYSAFETYCOORDINATOR",
    //     label: "5Y Safety \n  Exp",
    //     sortable: false,
    //     department: ['mta']

    // }

]

const selectedDepartmentValue = 'mta';
export default function MtaSpecificationView() {
    const dispatch = useDispatch();
    const { projects, projectSpecifications } = useSelector(prop('project'));
    const { creeds } = useSelector(prop('creed'));
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);


    const updateColumns = () => {
        dispatch(actions.getProjects());
        dispatch(actions.getSpecification());
        let columns = [];
        columns.push(...FIXED_COLUMNS.filter(k => k.department?.includes(selectedDepartmentValue?.toLowerCase())));
        let creedColumns = []
        if (creeds && creeds.length > 0) {
            creedColumns = creeds.filter(k => k.MTASpecification && k.Department?.toLowerCase() === selectedDepartmentValue?.toLowerCase()).map(k => ({
                id: `${k.Id}`,
                label: `${k.CreedName}`,
                sortable: true,
                type: 'creed'
            }));
            columns = [...columns, ...creedColumns];

        }
        setColumns(columns)
    }
    useEffect(() => {
        updateColumns();
    }, [creeds])

    useEffect(() => {
        let tempData = [];
        if (projects && projects.length > 0) {

            tempData = projects
                .filter(k => selectedDepartmentValue ? k?.Department?.toLowerCase() === selectedDepartmentValue.toLowerCase() : true);

            for (let i = 0; i < tempData.length; i++) {
                let tempCurProjectSpecifications = projectSpecifications.filter(k => k.Project.Id === tempData[i].Id) || [];
                let creedValues = {}
                tempCurProjectSpecifications.reduce((a, v) => {
                    a[v.Creed.Id] = v.Value;
                    return a
                }, creedValues);

                tempData[i] = { ...tempData[i], ...creedValues };

            }
            setData(tempData);
        }
    }, [projects, projectSpecifications]);



    return (
        <BgView>
            <BackAppBar />
            <DynamicMTASpecificationTable
                data={data}
                columns={columns}
            />
        </BgView>

    );
}

