import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Container, Grid, Stack, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import userRoles from "assets/data/roles";
import BackAppBar from "components/app/BackAppBar";
import FlexBox from 'components/common/FlexBox';
import PrimaryButton from 'components/common/PrimaryButton';
import ViewTitle from "components/common/ViewTitle";
import PhoneValidator from 'components/common/input/PhoneValidator';
import TextValidator from 'components/common/input/TextValidator';
import UserCreedList from 'components/users/User-Creeds-List';
import { chain, isEmpty, trim } from 'lodash';
import { prop } from "ramda";
import React, { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  form: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  column: {
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 50%',
      padding: theme.spacing(2),
    },
  },
}));
export default function PersonalInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { FirstName = "",
    Email = "",
    Role = "",
    MobileNo = "",
    WorkPhone = "",
    IsActive = true,
    isNew = false,
    Id = null,
    Client = null,
    UserCreeds = [],
    SSTExpire = null,
    SSTCard = '' } = useSelector(prop('user'));
  const [form, setForm] = useState({
    FirstName: FirstName,
    Email: Email,
    IsActive: IsActive,
    Role: Role ? userRoles.find(k => k.Id === Role) : null,
    MobileNo: MobileNo,
    WorkPhone: WorkPhone,
    Id: Id,
    Client: Client,
    UserCreeds: UserCreeds,
    SSTExpire: SSTExpire,
    SSTCard: SSTCard
  });

  const hasEmptyFields =
    form?.Role?.Id === "staff"
      ? chain({
        ...form,
      })
        .omit(["Id"])
        .mapValues(trim)
        .some(isEmpty)
        .value()
      : chain({
        ...form,
      })
        .omit(["Id", "Skill", "Team", "SSTCard", "SSTExpiry"])
        .mapValues(trim)
        .some(isEmpty)
        .value();



  const onAdd = async () => {
    let body = { ...form };
    body.Role = body?.Role?.Id ? body.Role.Id : "";

    if (body.Role !== 'staff') {
      delete body.UserCreeds;
    }

    try {
      // Assuming actions.addUser returns a Promise
      await dispatch(actions.addUser(body));
      await dispatch(actions.getProfile())
      // Code after a successful addUser operation can go here
    } catch (error) {
      // Handle errors if addUser fails
      console.error("Error adding user:", error);
    }
  };


  const disabled = false;
  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "IsActive" || prop === "IsFieldStaff") {
      updateField(prop, e.target.checked);
    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = false;

  return (
    <Container>
      <BackAppBar />
      <ViewTitle>Personal Info</ViewTitle>
      <Container className={classes.container}>
        <ValidatorForm instantValidate={true} autoComplete="off">
          <Grid container spacing={2} className={classes.form}>
            <Grid item xs={12} sm={6} className={classes.column}>

              <TextValidator
                autoComplete='none'
                name="FirstName"
                label="Full Name"
                value={form?.FirstName}
                margin="normal"
                fullWidth
                disabled={disabled}
                data-testid="input-user-name"
                onChange={handleChange("FirstName")}
                onBlur={handleBlur("FirstName")}
                validators={["required"]}
                errorMessages={["this field is required"]}

              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.column}>
              <TextValidator
                autoComplete='new-password'
                name="Email"
                label="Email"
                value={form?.Email}
                margin="normal"

                fullWidth
                disabled={true}
                onChange={handleChange("Email")}
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "Email is not valid"]}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.column}>
              <PhoneValidator
                defaultCountry="us"
                disableAreaCodes
                countryCodeEditable={false}
                label="Phone number"
                value={form?.MobileNo}
                onChange={(value) => updateField("MobileNo", value)}
                onBlur={handleBlur("MobileNo")}
                fullWidth
                disabled={disabled}
                margin="normal"
                validators={["required", "isPhone"]}
                errorMessages={[
                  "Phone number is required",
                  "The Mobile number is not valid",
                ]}
                inputProps={{
                  autoComplete: 'new-password'
                }}
                data-testid="input-MobileNo-number"
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.column}>
              <PhoneValidator
                defaultCountry="us"
                disableAreaCodes
                countryCodeEditable={false}
                label="Work Phone number"
                value={form?.WorkPhone}
                onChange={(value) => updateField("WorkPhone", value)}
                onBlur={handleBlur("WorkPhone")}
                fullWidth
                disabled={disabled}
                margin="normal"
                validators={["required", "isPhone"]}
                errorMessages={[
                  "Phone number is required",
                  "The Phone number is not valid",
                ]}
                inputProps={{
                  autoComplete: 'new-password'

                }}
                data-testid="input-WorkPhone-number"
              />
            </Grid>
            {form?.Role?.Id === 'staff' &&
              <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete='none'
                  name="SSTCard"
                  label="SST Card"
                  value={form?.SSTCard}
                  margin="normal"
                  fullWidth
                  disabled={true}
                  data-testid="input-user-name"
                  onChange={handleChange("SSTCard")}
                  onBlur={handleBlur("SSTCard")}
                  errorMessages={["this field is required"]}

                />
              </Grid>
            }
            {form?.Role?.Id === 'staff' &&
              <Grid item xs={12} sm={6} className={classes.column}>
                <LocalizationProvider dateAdapter={
                  AdapterDateFns}>
                  <Stack spacing={4}>
                    <MobileDatePicker
                      name="SSTExpire"
                      label="SST Expiry"
                      disabled={true}
                      value={form?.SSTExpire}
                      onChange={handleChange("SSTExpire")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            }
            {form?.Role?.Id === 'staff' &&
              <Grid item xs={12} sm={12} md={12} className={classes.column}>
                <UserCreedList
                  readonly={true}
                  userCreeds={form.UserCreeds || []}
                  updateUserCreeds={(newValue) => {
                    updateField("UserCreeds", newValue);
                  }}>

                </UserCreedList>
              </Grid>
            }
          </Grid>
        </ValidatorForm>
      </Container>
      <FlexBox justifyContent="center">
        <PrimaryButton
          // color="secondary"
          onClick={onAdd}
          data-testid="btn-save-user"
        >
          Save
        </PrimaryButton>
      </FlexBox>
    </Container>
  );
}




