import NumberField from "components/common/input/NumberField";
import React from 'react';

export default function CodeValidatorField(props) {
  return (
    <NumberField
      name="zip"
      label="Verification Code"
      margin="normal"
      fullWidth
      minLength={5}
      validators={['required', 'matchRegexp:^\\d{4}$']}
      errorMessages={['Verification Code is required', 'Verification Code is not valid']}
      {...props}
    />
  );
}
