import { noop } from "lodash";
import React from 'react';
import { TextValidator } from "react-material-ui-form-validator";

export default function NumberField({ value, minLength, inputProps, onChange = noop, ...rest }) {

  const handleChange = (e) => {
    const v = e.target.value;
    const validLength = !minLength || v.length <= minLength
    if (validLength) {
      onChange(e)
    }
  };

  return (
    <TextValidator
      autoComplete='new-password'
      type="number"
      value={value}
      onChange={handleChange}
      inputProps={{
        pattern: '\\d*',
        inputMode: 'numeric',
        minLength,
        ...inputProps
      }}
      {...rest}
    />
  );
}
