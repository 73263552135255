import {payloadAction, simpleAction} from "utils/stateUtils";

export const CORDOVA_SET_PROPS = 'CORDOVA_SET_PROPS';
export const CORDOVA_READY = 'CORDOVA_READY';

export const CORDOVA_CHECK_SECURE_STORAGE = 'CORDOVA_CHECK_SECURE_STORAGE';
export const CORDOVA_SAVE_SECURE_KEY = 'CORDOVA_SAVE_SECURE_KEY';
export const CORDOVA_LOAD_SECURE_KEY = 'CORDOVA_LOAD_SECURE_KEY';

export const CORDOVA_CHECK_CONNECTION_REQUESTED = 'CORDOVA_CHECK_CONNECTION_REQUESTED';
export const CORDOVA_PING_CONNECTION = 'CORDOVA_PING_CONNECTION';

export const CORDOVA_APP_PAUSED = 'CORDOVA_APP_PAUSED';
export const CORDOVA_APP_RESUMED = 'CORDOVA_APP_RESUMED';
export const CORDOVA_BACK_PRESSED = 'CORDOVA_BACK_PRESSED';

export const setCordovaProps = payloadAction(CORDOVA_SET_PROPS)
export const setCordovaReady = payloadAction(CORDOVA_READY)

export const checkSecureStorage = simpleAction(CORDOVA_CHECK_SECURE_STORAGE)
export const saveSecureKey = (key, value) => payloadAction(CORDOVA_SAVE_SECURE_KEY)({key, value})
export const loadSecureKey = (key) => payloadAction(CORDOVA_LOAD_SECURE_KEY)({key})

export const appPaused = simpleAction(CORDOVA_APP_PAUSED)
export const appResumed = simpleAction(CORDOVA_APP_RESUMED)
export const backPressed = simpleAction(CORDOVA_BACK_PRESSED)
export const pingConnection = simpleAction(CORDOVA_PING_CONNECTION)

export const setOffline = () => setCordovaProps({isOffline: true});
export const checkConnection = simpleAction(CORDOVA_CHECK_CONNECTION_REQUESTED);
