import {combineReducers, propsReducer, resetReducer} from "utils/stateUtils";
import {ERROR_SET_PROPS, HIDE_ERROR_DRAWER} from 'actions/error';

const DEFAULT_STATE = {
  // locationErrorMessage: "No location available to load feed data"
}


export default combineReducers(
    propsReducer(ERROR_SET_PROPS, DEFAULT_STATE),
    resetReducer(HIDE_ERROR_DRAWER)
)