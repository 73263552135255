import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'fixed',
    zIndex: theme.zIndex.tooltip * 2,
    backdropFilter: 'blur(4px)',
  },
}));

export default function ScreenBlur() {
  const classes = useStyles();
  return <div className={classes.root}/>
}