import React from 'react';
import {AppBar, Toolbar} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'white',
  },
  toolbar: {
    padding: theme.spacing(0, 1)
  },
  logo: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 48,
    right: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const TopAppBar = React.forwardRef((props, ref) => {
  const {logo, leftNav, rightNav, children} = props;
  const classes = useStyles();
  return (
      <>
        <AppBar elevation={0}
                ref={ref}
                className={classes.appBar}
                color="default">
          <Toolbar disableGutters className={classes.toolbar}>
            <div className={classes.logo}>{logo}</div>
            <div>{leftNav}</div>
            <Box flexGrow={2} p={1}>{children}</Box>
            {rightNav}
          </Toolbar>
        </AppBar>
        <Toolbar/>
      </>
  );
});

export default TopAppBar;