import React from "react";
import BackAppBar from "components/app/BackAppBar";
import { useSelector } from "react-redux";
import { prop } from "ramda";
import BgView from "components/common/BgView";
import LeaveDetails from "./LeaveDetails";

export default function LeaveView() {
  const { leaveDetails } = useSelector(prop('leave'));
  return (
    <BgView>
      <BackAppBar />
      <LeaveDetails Leave={leaveDetails} />
    </BgView>
  );
}
