import { styled } from "@mui/styles";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch } from "react-redux";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import * as actions from "actions";
function convertDataForBigCalendar(data) {
    let events = [];

    data.forEach(entry => {
        const user = entry.User;
        Object.keys(entry).forEach(date => {
            if (date !== "User" && Array.isArray(entry[date]) && entry[date].length > 0) {
                entry[date].forEach(task => {
                    const startTime = moment(date + ' ' + task.StartTime, 'YYYYMMDD HH:mm');
                    let endTime = moment(date + ' ' + task.EndTime, 'YYYYMMDD HH:mm');

                    endTime = endTime.isBefore(startTime) ? endTime.add(1, 'd') : endTime;
                    if (task.Status !== 'Cancelled') {
                        events.push({
                            title: user.Name + " - " + task.ProjectName,
                            start: startTime.toDate(),
                            end: endTime.toDate(),
                            location: task.Location,
                            type: task.Type,
                            pcName: task.PCName,
                            pcEmail: task.PCEmail,
                            pcPhone: task.PCPhone,
                            djr: task.DJR,
                            hasInspectionReport: task.hasInspectionReport,
                            status: task.Status,
                            user: user,
                            id: task.Id
                        });
                    }

                });
            }
        });
    });

    return events;
}
const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            // backgroundColor: 'lightblue',
        },
    })


const localizer = momentLocalizer(moment);
const StyledCalendar = styled(Calendar)(({ theme }) => ({
    // Apply your custom styles here using the theme
    "& .rbc-time-view": {
        backgroundColor: theme.palette.background.paper,
    },
    "& .rbc-today": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));
const SchedulerCalender = ({ data, onDateChange, view = 'week', onView }) => {
    const [events, setEvents] = useState([
        {
            start: moment().toDate(),
            end: moment()
                .add(1, "days")
                .toDate(),
            title: "Some title"
        }
    ]);
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        if (data.length > 0) {
            const tempEvents = convertDataForBigCalendar(data);
            setEvents(tempEvents);
        }
    }, [data])


    const onNavigate = useCallback((newDate) => {
        setDate(newDate)
        let start = moment(newDate).startOf('month');
        let end = moment(newDate).endOf('month')
        // onDateChange({ start, end })
    }, [setDate])
    const onRangeChange = useCallback((range) => { //range: Date[] | { start: Date; end: Date }
        if (Array.isArray(range) && range.length > 0) {
            onDateChange({ start: moment(range[0]).startOf('day'), end: moment(range[range.length - 1]).endOf('day') })
        } else {
            onDateChange(range)
        }
        // window.alert((JSON.stringify(range)))
    }, [])

    const handleEventClick = (event) => {
        // Do something with the clicked event
        console.log('Clicked event:', event);
        onViewTask(event);
    };

    const dispatch = useDispatch();
    const onViewTask = (data, day, user) => {
        if (data.type === "Task") {
            dispatch(actions.getTaskById({ Id: data.id }));
        }
        // else {
        //   let task = {
        //     ...data,
        //     AssignedTo: user,
        //     StartDate: parseDateWithTZ(data.StartDate),
        //     EndDate: parseDateWithTZ(data.EndDate),
        //     StartTime: parseDateWithTZ(data.StartDate),
        //     EndTime: parseDateWithTZ(data.EndDate),

        //   };
        //   task["User"] = task.AssignedTo;
        //   dispatch(actions.showTaskDetails(task));
        // }
    };

    const { components } = useMemo(
        () => ({
            components: {
                timeSlotWrapper: ColoredDateCellWrapper,
            },
        }),
        []
    )
    return (

        <Calendar
            components={components}
            localizer={localizer}
            defaultView={view}
            events={events}
            style={{ height: "calc(100vh - 230px)" }}
            date={date}
            startAccessor="start"
            endAccessor="end"
            // views={['month', 'week', 'day']}
            onNavigate={onNavigate}
            onRangeChange={onRangeChange}
            showMultiDayTimes
            onSelectEvent={handleEventClick}
            onView={onView}
        />
    );
};

export default SchedulerCalender;
