import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const SUBCONTRACTOR_SET_PROPS = "SUBCONTRACTOR_SET_PROPS";

export const GET_SUBCONTRACTORS = "GET_SUBCONTRACTORS";
export const ADD_SUBCONTRACTOR = "ADD_SUBCONTRACTOR";
export const REMOVE_SUBCONTRACTOR = "REMOVE_SUBCONTRACTOR";
export const REFRESH_SUBCONTRACTORS = "REFRESH_SUBCONTRACTORS";
export const SUBCONTRACTOR_DETAILS = "SUBCONTRACTOR_DETAILS";

export const setSubContractorProps = payloadAction(SUBCONTRACTOR_SET_PROPS);

export const getSubContractors = simpleAction(GET_SUBCONTRACTORS);
export const addSubContractor = payloadAction(ADD_SUBCONTRACTOR);
export const removeSubContractor = payloadAction(REMOVE_SUBCONTRACTOR);
export const refreshVirtualCard = simpleAction(REFRESH_SUBCONTRACTORS);
export const showSubContractorDetails = (subContractor) =>
  createAction(SUBCONTRACTOR_DETAILS, { subContractor });
