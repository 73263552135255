import {
    all, put, takeLatest
} from "redux-saga/effects";

import path from "routesPath";
import {
    endProgress,
    failProgress,
    startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

function* getRosters() {
    const progress = yield startProgress();
    try {
        const rosters = yield Api.Roster.getRostersUsingGET();
        yield put(actions.setRosterProps({ rosters }));
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}
export function* getWeekdays() {
    const progress = yield startProgress();
    try {
        const weekDays = yield Api.Roster.getWeekDaysUsingGET();
        yield put(actions.setRosterProps({ weekDays }));
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}
export function* getTimeSlots() {
    const progress = yield startProgress();
    try {
        const timeSlots = yield Api.Roster.getTimeSlotsUsingGET();
        yield put(actions.setRosterProps({ timeSlots }));
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}

function* addRoster(action) {
    const progress = yield startProgress();
    try {
        const body = action.payload;
        const Roster = yield Api.Roster.addRoster(body);
        yield getRosters();
        // history.goBack()
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}
function* removeRoster(action) {
    try {
        const id = action.payload.id;
        const Roster = yield Api.Roster.removeRoster(id);

        history.goBack()
    } catch (error) {
        console.error(error);
    }
}

function* refreshRosters() {
    const progress = yield startProgress();
    yield getRosters();
    yield endProgress(progress);
}

function* showRosterDetails(action) {
    const { Roster } = action.payload;
    if (Roster.isNew) {
        yield put(actions.setRosterProps({ RosterDetails: Roster }));
        history.push(path.Roster.RosterDetail);
    }
    else {
        const body = {
            Id: Roster.Id
        };
        const djrResp = yield Api.Roster.getRosterByIdUsingPOST(body);
        yield put(actions.setRosterProps({ RosterDetails: djrResp }));
        history.push(path.Roster.RosterDetail);
    }

}



export default function* saga() {
    yield all([
        takeLatest(actions.GET_ROSTERS, getRosters),
        takeLatest(actions.GET_WEEKDAYS, getWeekdays),
        takeLatest(actions.GET_TIMESLOTS, getTimeSlots),
        takeLatest(actions.ADD_ROSTER, addRoster),
        takeLatest(actions.REMOVE_ROSTER, removeRoster),
        takeLatest(actions.REFRESH_ROSTERS, refreshRosters),
        takeLatest(actions.ROSTER_DETAILS, showRosterDetails),
    ]);
}
