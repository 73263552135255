/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Creed from '../model/Creed';
import APIEndPoints from "./ApiEndpoints";

/**
* CardsController service.
* @module api/CreedControllerApi
* @version 1.0
*/
export default class CreedControllerApi {

    /**
    * Constructs a new CreedControllerApi. 
    * @alias module:api/CreedControllerApi
    * @class
    * @param {module:ApiClient} [apiCreed] Optional API creed implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiCreed) {
        this.apiCreed = apiCreed || ApiClient.instance;
        this.endPoints = new APIEndPoints();
    }




    /**
     * getCreed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Creed>} and HTTP response
     */
    getCreedsUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [Creed];
        return this.apiCreed.callApi(
            this.endPoints.creed.GetAll.URI,
            this.endPoints.creed.GetAll.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getCreed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Creed>}
     */
    getCreedsUsingGET() {
        return this.getCreedsUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
     * getAddCreed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Creed>} and HTTP response
     */
    addCreedWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = Creed;
        return this.apiCreed.callApi(
            this.endPoints.creed.Post.URI,
            this.endPoints.creed.Post.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddCreed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Creed>}
     */
    addCreed(body) {
        return this.addCreedWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
 * getCreed
 * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Creed>} and HTTP response
 */
    removeCreedWithHttpInfo(id) {
        let postBody = null;

        let pathParams = {
            id: id
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = Creed;
        return this.apiCreed.callApi(
            this.endPoints.creed.Delete.URI,
            this.endPoints.creed.Delete.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getCreed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Creed>}
     */
    removeCreed(id) {
        return this.removeCreedWithHttpInfo(id)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    getCreedByIdUsingPOST(body) {
        return this.getCreedByIdUsingPOSTWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }

    getCreedByIdUsingPOSTWithHttpInfo(body) {

        let postBody = body;

        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = Creed;
        return this.apiCreed.callApi(
            this.endPoints.creed.GetById.URI,
            this.endPoints.creed.GetById.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }


    saveOrderWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };


        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = Creed;
        return this.apiCreed.callApi(
            this.endPoints.creed.SaveOrder.URI,
            this.endPoints.creed.SaveOrder.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddProject
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Project>}
     */
    saveOrder(body) {
        return this.saveOrderWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }





}
