import AddIcon from "@mui/icons-material/Add";
import { Box, Container, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserListItem from "./UserListItem";

import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import SearchBar from "components/common/SearchBar";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
}));

export default function UserList({ height, onItemSelect, onAddNewUser }) {
  const classes = useStyles();
  const handleClick = (user) => () => !!onItemSelect && onItemSelect(user);
  const handelNewUserClick = () => !!onAddNewUser && onAddNewUser();
  const { users, LoginUserRole, seletedRole } = useSelector(prop("user"));
  const [searchText, setSearchText] = React.useState('');

  const [filterUserbasedOnRole, setfilterUserbasedOnRole] = useState();
  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getUsers());
    dispatch(actions.getCreeds());
    dispatch(actions.getClients());
  };

  useEffect(updateAll, []);


  useEffect(() => {
    updateField(seletedRole);
  }, [users, seletedRole, searchText]);


  const updateField = (value) => {
    dispatch(actions.setUserProps({ seletedRole: value }));
    let filterdList = users;

    if (searchText.length > 0) {
      filterdList = users.filter(k => k.Email?.toLowerCase().includes(searchText)
        || k.FirstName?.toLowerCase().includes(searchText) || k.MobileNo?.toLowerCase().includes(searchText));
    }


    value?.Id === "all"
      ? setfilterUserbasedOnRole(filterdList)
      : setfilterUserbasedOnRole(filterdList.filter((k) => k.Role === value?.Id ||
        k.Departments?.some(d => d.Department?.toLowerCase() === value?.Id.toLowerCase())));


  };
  const categorys = dropDownOption.StaffCategorys;

  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }
  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            ICC Personnel
          </strong>
        </Typography>
        {/* <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many icc personnel as you want and assign there role.
        </Typography> */}
      </Box>
      {LoginUserRole === "admin" ? (
        <>
          <Box mt={1}>
            <Autocomplete
              id="role-type-select"
              options={[
                {
                  Id: "all",
                  Name: "All",
                },
                ...categorys,
              ]}
              defaultValue={seletedRole}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.Name}
              value={seletedRole}
              onChange={(_event, newValue) => {
                updateField(newValue);
              }}
            />
          </Box>
        </>
      ) : (
        <></>
      )}

      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of ICC Personnel
          </strong>
        </Typography>

        <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>

      </Box>
      <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
          {LoginUserRole === "admin" &&
            <ListItem
              dense
              disableGutters
              onClick={handelNewUserClick}
              className={classes.item}
              data-testid={"li-add-user"}
            >
              <ListItemAvatar>
                <AddIcon />
              </ListItemAvatar>
              <ListItemText
                primary={"Add a ICC Personnel"}
                primaryTypographyProps={{ className: "bold" }}
              />
            </ListItem>
          }
          {filterUserbasedOnRole?.map((user) => (
            <UserListItem
              key={user.Id}
              user={user}
              onClick={handleClick(user)}
              itemClasses={classes.item}
            />
          ))}


        </ul>
      </List>
    </Container>
  );
}
