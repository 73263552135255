import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

import withStyles from '@mui/styles/withStyles';
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  SnackbarContent,
} from '@mui/material';
import { Warning } from '@mui/icons-material';

import { hideErrorDrawer } from 'actions/error';
import FlexBox from "../common/FlexBox";
import Box from "@mui/material/Box";
import history from "../../history";
import path from "routesPath";

const styles = theme => ({
  avatar: {
    position: 'fixed',
    top: theme.spacing(2),
    backgroundColor: theme.palette.error.dark,
  },
  list: {
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(6),
    maxHeight: theme.spacing(30),
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  message: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    userSelect: 'text'
  },
  firstMessage: {
    marginTop: 0,
  },
  networkSnackbarContent: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
});

const mapStateToProps = state => {
  const { messages, expected, originalError, locationErrorMessage } = state.error;
  return {
    messages: _.isEmpty(messages) ? undefined : _.isArray(messages) ? messages : [messages],
    originalError,
    expected,
    locationErrorMessage,
    version: state.version,
    isOffline: state.cordova.isOffline,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    hideErrorDrawer,
  },
  dispatch,
);

class ErrorDrawer extends React.PureComponent {
  hideError = () => {
    const { hideErrorDrawer } = this.props;
    hideErrorDrawer();
  };

  getMessage = () => {
    const { messages, originalError, version, location } = this.props;

    const ver = _.keys(version)
      .map(k => `${k}: ${version[k]}`)
      .join(', ');

    return `App version:\r\n${ver}\r\n\r\nLocation:\r\n${JSON.stringify(location)}\r\n\r\nMessage:\r\n${_.join(
      messages,
      '\r\n',
    )}\r\n\r\nError:\r\n${JSON.stringify(originalError, Object.getOwnPropertyNames(originalError))}`;
  };

  renderUnexpectedWarning = () => {
    const { classes, messages } = this.props;
    return (
      <>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classNames(classes.avatar, classes.warning)}>
              <Warning />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            className={classNames(classes.message, classes.firstMessage)}
            primary="Oops, something did not work as intended"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
        {messages &&
          messages.map((message, i) => (
            <React.Fragment key={i}>
              <Divider light variant="inset" />
              <ListItem>
                <ListItemAvatar>
                  <span />
                </ListItemAvatar>
                <ListItemText
                  primary={message}
                  primaryTypographyProps={{ variant: 'body2' }}
                  className={classes.message}
                />
              </ListItem>
            </React.Fragment>
          ))}
      </>
    );
  };

  renderExpectedWarning = () => {
    const { classes, messages } = this.props;
    return (
      messages &&
      messages.map((message, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <Divider light variant="inset" />}
          <ListItem>
            <ListItemAvatar>
              {i === 0 ? (
                <Avatar className={classNames(classes.avatar, classes.warning)}>
                  <Warning />
                </Avatar>
              ) : (
                <span />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={message}
              primaryTypographyProps={{ variant: 'body2' }}
              className={classNames(classes.message, i === 0 && classes.firstMessage)}
            />
          </ListItem>
        </React.Fragment>
      ))
    );
  };

  showLocationInfo = () => history.push(path.error.location);

  render() {
    const { classes, messages, isOffline, locationErrorMessage } = this.props;
    return (
      <>
        <Drawer anchor="top" open={!_.isEmpty(messages)} onClose={this.hideError}>
          <List disablePadding className={classes.list}>
            {this.renderExpectedWarning()}
          </List>
          <FlexBox justifyContent="flex-end" m={2}>
            <Box mr={2}>
              <Button color="primary" variant="text" onClick={this.hideError}>
                OK
              </Button>
            </Box>
          </FlexBox>
        </Drawer>

        <Snackbar key="location"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!isOffline && Boolean(locationErrorMessage)}>
          <SnackbarContent
            aria-describedby="no-location-message"
            message={<span id="no-location-message">{locationErrorMessage}</span>}
            action={
              <Button color="inherit"
                size="small"
                onClick={this.showLocationInfo}>
                Learn More
              </Button>
            }
          />
        </Snackbar>

        <Snackbar key="network" anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={isOffline}>
          <SnackbarContent
            aria-describedby="network-message-id"
            message={<span id="network-message-id">You are offline. Check your Internet connection</span>}
            className={classes.networkSnackbarContent}
          />
        </Snackbar>
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(ErrorDrawer);
