class Fingerprint {

  setUseLocalStorage = () => {
    this.useLocalStorage = true;
  }

  checkFingerprint = () => new Promise((resolve, reject) => {
    if (window.Fingerprint) {
      window.Fingerprint.isAvailable(resolve, reject);
    } else {
      reject(new Error("window.Fingerprint isn't available"));
    }
  });

  show = (title) => new Promise((resolve, reject) => {
    console.log("Show biometric unlock")
    if (this.useLocalStorage) {
      resolve();
    } else {
      window.Fingerprint.show({title}, resolve, reject);
    }
  });

  registerSecret = (secret, title) => new Promise((resolve, reject) => {
    const params = {
      title,
      secret,
      invalidateOnEnrollment: true
    };
    window.Fingerprint.registerBiometricSecret(params, resolve, reject);
  });

  loadSecret = (title) => new Promise((resolve, reject) => {
    window.Fingerprint.loadBiometricSecret({title}, resolve, reject);
  });

}

const fingerprint = new Fingerprint();
export default fingerprint;
