import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const PROJECT_SET_PROPS = "PROJECT_SET_PROPS";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_SPECIFICATION = "GET_SPECIFICATION";
export const PROJECT_SAVE_ORDER = "PROJECT_SAVE_ORDER";
export const ADD_PROJECT = "ADD_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const REFRESH_PROJECTS = "REFRESH_PROJECTS";
export const PROJECT_DETAILS = "PROJECT_DETAILS";
export const USERS_PROJECT = "USERS_PROJECT";
export const UPDATE_PROJECT_FIELD = "UPDATE_SPECIFICATION";
export const UPDATE_SPECIFICATION = "UPDATE_PROJECT_FIELD";

export const setProjectProps = payloadAction(PROJECT_SET_PROPS);


export const updateProjectField = payloadAction(UPDATE_PROJECT_FIELD);
export const updateSpecification = payloadAction(UPDATE_SPECIFICATION);

export const getProjects = simpleAction(GET_PROJECTS);
export const getSpecification = simpleAction(GET_SPECIFICATION);
export const getUsersProjects = simpleAction(USERS_PROJECT);
export const addProject = payloadAction(ADD_PROJECT);
export const removeProject = payloadAction(REMOVE_PROJECT);
export const refreshVirtualCard = simpleAction(REFRESH_PROJECTS);
export const saveProjectOrder = payloadAction(PROJECT_SAVE_ORDER);
export const showProjectDetails = (project) =>
  createAction(PROJECT_DETAILS, { project });
