import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Replace with the actual icon
import AssignmentIcon from '@mui/icons-material/Assignment'; // Replace with the actual icon
import BusinessIcon from '@mui/icons-material/Business'; // Replace with the actual icon
import GroupIcon from '@mui/icons-material/Group'; // Replace with the actual icon
import LockIcon from '@mui/icons-material/Lock'; // Replace with the actual icon
import NotificationsIcon from '@mui/icons-material/Notifications'; // Replace with the actual icon
import ReportIcon from '@mui/icons-material/Report'; // Replace with the actual icon
import VpnKeyIcon from '@mui/icons-material/VpnKey'; // Replace with the actual icon
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  SwipeableDrawer
} from "@mui/material";

import React from "react";
import history from "../../history";


import * as actions from "actions";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import FlexBox from "components/common/FlexBox";
import IOSSwitch from "components/common/input/IOSSwitch";
import PrimaryButton from "components/common/PrimaryButton";
import { prop } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import path from "routesPath";
import { abbr } from "utils/strUtils";
const useStyles = makeStyles((theme) => ({
  top: {
    padding: theme.spacing(5),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
  item: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  icon: {
    minWidth: 36,
    marginTop: -3,
    "& path": {
      fill: theme.palette.text.secondary,
    },
  },
  bottomBox: {
    position: "fixed",
    bottom: 10,
    right: 10,
    paddingRight: theme.spacing(4),
    fontWeight: "bold",
    // fontSize: "0.75rem",
  },
}));

const menuOptions = [
  {
    title: "Personal Info",
    icon: AccountCircleIcon,
    path: path.profile.personalInfo,
    roles: ['admin', 'staff', 'clientuser']
  },
  {
    title: "Users",
    icon: GroupIcon,
    path: path.profile.userAdministration,
    roles: ['admin']
  },
  {
    title: "Credentials",
    icon: VpnKeyIcon,
    path: path.profile.credAdministration,
    roles: ['admin']
  },
  {
    title: "Clients",
    icon: BusinessIcon,
    path: path.profile.clientAdministration,
    roles: ['admin']
  },
  {
    title: "MTA Approval",
    icon: AssignmentIcon,
    path: path.profile.matApproval,
    roles: ['admin']
  },
  {
    title: "MTA Project Specification",
    icon: AssignmentIcon,
    path: path.profile.matProjectSpecification,
    roles: ['admin']
  },
  {
    title: "Report",
    icon: ReportIcon,
    path: path.profile.report,
    roles: ['admin']
  },
  {
    title: "Alerts",
    icon: NotificationsIcon,
    path: path.profile.alerts,
    roles: ['admin', 'staff', 'clientuser']
  },
  {
    title: "Change Password",
    icon: LockIcon,
    path: path.user.userUpdatePassword,
    roles: ['admin', 'staff', 'clientuser']
  },
];

// const menuOptions = [
//   {
//     title: "Personal Info",
//     icon: ProfileIcon,
//     path: path.profile.personalInfo,
//     roles: ['admin', 'staff', 'clientuser']
//   },
//   {
//     title: "Users",
//     icon: ProfileIcon,
//     path: path.profile.userAdministration,
//     roles: ['admin']
//   },
//   // {
//   //   title: "Agency/Owner",
//   //   icon: ProfileIcon,
//   //   path: path.profile.clientAdministration,
//   //
//   // },

//   {
//     title: "Credentials",
//     icon: ProfileIcon,
//     path: path.profile.credAdministration,
//     roles: ['admin']

//   },
//   {
//     title: "Clients",
//     icon: ProfileIcon,
//     path: path.profile.clientAdministration,
//     roles: ['admin']

//   },
//   // {
//   //   title: "Projects",
//   //   icon: ProfileIcon,
//   //   path: path.profile.projectAdministration,
//   //   roles: ['admin']

//   // },
//   // {
//   //   title: "SubContractor",
//   //   icon: ProfileIcon,
//   //   path: path.profile.subContractorAdministration,
//   //
//   // },
//   {
//     title: "MTA Approval",
//     icon: FileIcon,
//     path: path.profile.matApproval,
//     roles: ['admin']

//   },
//   {
//     title: "Report",
//     icon: FileIcon,
//     path: path.profile.report,
//     roles: ['admin']

//   },
//   // {
//   //   title: "Vacation/DayOff",
//   //   icon: ProfileIcon,
//   //   path: path.profile.leaveAdministration,
//   //   availableForStaff: true,
//   // },
//   // {
//   //   title: "Holiday",
//   //   icon: ProfileIcon,
//   //   path: path.profile.holidayAdministration,
//   //   availableForStaff: true,
//   // },
//   {
//     title: "Alerts",
//     icon: NotificationIcon,
//     path: path.profile.alerts,
//     roles: ['admin', 'staff', 'clientuser']
//   },
//   {
//     title: "Change Password",
//     icon: ProfileIcon,
//     path: path.user.userUpdatePassword,
//     roles: ['admin', 'staff', 'clientuser']
//   },
// ];

export default function ProfileDrawer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { Name, Email, LoginUserRole } = useSelector(prop("user"));
  const { version } = useSelector(prop("app"));

  const goTo = (path) => () => path && history.push(path);
  const logout = () => {
    dispatch(actions.logout());
  };
  const toListItem = ({ title, icon, path, swtch }) => (
    <ListItem
      key={title}
      button={!!path}
      onClick={goTo(path)}
      className={classes.item}
    >
      <ListItemIcon className={classes.icon}>
        <SvgIcon component={icon} color="secondary" />
      </ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ variant: "body2", className: "bold" }}
      />

      {!swtch && <ChevronRightIcon />}
      {swtch && (
        <ListItemSecondaryAction>
          <IOSSwitch />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );

  return (
    <SwipeableDrawer anchor="right" {...props}>
      <div className={classes.top}>
        <FlexBox>
          <Avatar className={classes.avatar}>{abbr(Name)}</Avatar>
          <Box sx={{ pl: 1 }}>
            <Typography variant="body2" className="bold">
              {Name}
            </Typography>
            {/* <Typography variant="body2" className="bold">
              {Email}
            </Typography> */}
          </Box>
        </FlexBox>
      </div>
      <List>
        {menuOptions
          .filter(
            (ele) => ele.roles.includes(LoginUserRole)
          )
          .map(toListItem)}
      </List>
      <PrimaryButton
        onClick={logout}
        data-testid="btn-add-task"
      >
        Logout
      </PrimaryButton>
    </SwipeableDrawer>
  );
}
