/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import { getNameStr } from 'utils/strUtils';
/**
 * The SubContractor model module.
 * @module model/SubContractor
 * @version 1.0
 */
class SubContractor {
  /**
   * Constructs a new <code>SubContractor</code>.
   * @alias module:model/SubContractor
   */
  constructor() {
    SubContractor.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>SubContractor</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubContractor} obj Optional instance to populate.
   * @return {module:model/SubContractor} The populated <code>SubContractor</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SubContractor();

      if (data.hasOwnProperty("FirstName")) {
        obj["FirstName"] = ApiClient.convertToType(data["FirstName"], "String");
      }
      if (data.hasOwnProperty("LastName")) {
        obj["LastName"] = ApiClient.convertToType(data["LastName"], "String");
      }

      if (data.hasOwnProperty("Email")) {
        obj["Email"] = ApiClient.convertToType(data["Email"], "String");
      }
      if (data.hasOwnProperty("MobileNo")) {
        obj["MobileNo"] = ApiClient.convertToType(data["MobileNo"], "String");
      }
      if (data.hasOwnProperty("Id")) {
        obj["Id"] = ApiClient.convertToType(data["Id"], "String");
      }
      obj['Name'] = getNameStr(data);
    }
    return obj;
  }
}

/**
 * subContractor first name
 * @member {String} FirstName
 */
SubContractor.prototype["FirstName"] = undefined;

/**
 * subContractor first name
 * @member {String} LastName
 */
SubContractor.prototype["LastName"] = undefined;
/**
 * subContractor first name
 * @member {String} Email
 */
SubContractor.prototype["Email"] = undefined;

/**
 * @member {String} MobileNo
 */
SubContractor.prototype["MobileNo"] = undefined;

/**
 * @member {String} Id
 */
SubContractor.prototype["Id"] = undefined;

SubContractor.prototype['Name'] = undefined;

export default SubContractor;
