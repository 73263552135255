import { Box, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import TextValidator from "components/common/input/TextValidator";
import { chain, isEmpty, trim } from "lodash";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import ClientUsersList from "./Client-Users-List";
import RemoveClientDialog from "./RemoveClientDialog";
// import NavigationBar from "components/common/input/NavigationBar";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  form: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  // column: {
  //   [theme.breakpoints.up('sm')]: {
  //     flex: '0 0 50%',
  //     padding: theme.spacing(2),
  //   },
  // },
}));
export default function ClientDetails({ Client = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const client = Client || {};
  // const { userName = "", role = "", MobileNo = "", isNew = false,id=null } = user;
  const { Name = '', Email = '', MobileNo = '', isNew = false, Id = null, ClientUsers = [] } = client;
  const [form, setForm] =
    useState({
      Name: Name,
      Email: Email,
      MobileNo: MobileNo,
      Id: Id,
      ClientUsers: ClientUsers
    });
  const hasEmptyFields = chain({
    ...form,
  })
    .omit(["Id", "Email", "MobileNo", "ClientUsers"])

    .mapValues(trim)
    .some(isEmpty)
    .value();
  const [openRemoveClientDialog, setopenRemoveClientDialog] = React.useState(false);
  const onRemove = () => { setopenRemoveClientDialog(true) };
  const onAdd = () => {
    let body = { ...form, goBack: true }
    dispatch(actions.addClient(body));
  };
  const onRemoveConfirm = () => { dispatch(actions.removeClient(form)); }
  const closeDialog = () => { setopenRemoveClientDialog(false) }

  const disabled = false;

  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    updateField(prop, e && e.target ? e.target.value : e);
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = hasEmptyFields;


  const inputProps = {}
  return (
    <>
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit Client</> : <>Add a Client</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            <Grid container spacing={2} className={classes.form}>
              <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete='new-password'
                  name="Name"
                  label="Client Name"
                  value={form?.Name}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("Name")}
                  onBlur={handleBlur("Name")}

                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  inputProps={{
                    autoComplete: 'new-password'

                  }}
                />
              </Grid>
              { /*<Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete='new-password'
                  name="Email"
                  label="Email"
                  value={form?.Email}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  onChange={handleChange("Email")}
                  validators={["required", "isEmail"]}
                  errorMessages={["this field is required", "Email is not valid"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <PhoneValidator
                  defaultCountry="us"
                  disableAreaCodes
                  countryCodeEditable={false}
                  label="Phone number"
                  value={form?.MobileNo}
                  onChange={(value) => updateField("MobileNo", value)}
                  onBlur={handleBlur("MobileNo")}
                  fullWidth
                  disabled={disabled}
                  margin="normal"
                  validators={["required", "isPhone"]}
                  errorMessages={[
                    "Phone number is required",
                    "The Mobile number is not valid",
                  ]}
                  InputProps={inputProps}
                  data-testid='input-MobileNo-number'
                />
              </Grid>
             */ }
              <Grid item xs={8}>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classes.column} >
                <ClientUsersList
                  clientUsers={form.ClientUsers || []}
                  updateClientUsers={(newValue) => {
                    updateField("ClientUsers", newValue);
                  }}></ClientUsersList>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Container>
        <FlexBox justifyContent="center">
          {!isNew ?

            <PrimaryButton
              onClick={onRemove}
              disabled={submitDisabled}
              data-testid="btn-remove-task"
              color="error"
            >
              Remove
            </PrimaryButton>
            : ''}
        </FlexBox>
        <FlexBox justifyContent="center">
          {!isNew ? (
            <>
              <PrimaryButton
                onClick={onAdd}
                disabled={submitDisabled}
                data-testid="btn-remove-user"
              >
                Save
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton onClick={onAdd} data-testid="btn-add-user" disabled={submitDisabled}>
              Add Client
            </PrimaryButton>
          )}
        </FlexBox>
        <RemoveClientDialog open={openRemoveClientDialog} onClose={closeDialog} onRemoveConfirm={onRemoveConfirm}></RemoveClientDialog>
      </Container>
    </>
  );
}
