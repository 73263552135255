import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import BgView from "components/common/BgView";
import moment from "moment";
import { prop } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatFullDate, formattedTime } from "utils/strUtils";
import DynamicRosterTable from "./DynamicRosterTable";
const RECOMMENDED_COLUMN = [{

    id: 'Recommended',
    label: 'Recommended',
    sortable: true
}
];
const PROJECT_COLUMNS = [{
    id: "ProjectCoverageHistoryCount",
    label: "Project Coverage History",
    sortable: true,
},
{
    id: "ProjectRating",
    label: "Project Rating",
    sortable: true,
}]
const FIXED_COLUMNS = [

    {
        id: "Rating",
        label: "Rating",
        sortable: true,
        department: ['mta', 'dob']
    },
    {
        id: "SafetyPersonal",
        label: "Job Position",
        sortable: true,
        department: ['dob']
    },
    {
        id: "Rate",
        label: "Rate",
        sortable: true,
        department: ['mta', 'dob']
    }, {
        id: "Phone",
        label: "Phone",
        sortable: true,
        department: ['mta', 'dob']
    },
    {
        id: "EmploymentType",
        label: "Employment Type",
        sortable: true,
        department: ['mta', 'dob']

    },
    {
        id: "Email",
        label: "Email",
        sortable: true,
        department: ['mta', 'dob']

    },
    {
        id: "Address",
        label: "Address",
        sortable: true,
        department: ['mta', 'dob']

    },
    {
        id: "Note",
        label: "Note",
        sortable: true,
        department: ['mta', 'dob']

    },
    {
        id: "Mon",
        label: "Mon",

        sortable: true,
        department: ['dob']
    },
    {
        id: "Tue",
        label: "Tue",
        sortable: true,
        department: ['dob']
    },
    {
        id: "Wed",
        label: "Wed",
        sortable: true,
        department: ['dob']
    },
    {
        id: "Thu",
        label: "Thu",
        sortable: true,
        department: ['dob']
    },
    {
        id: "Fri",
        label: "Fri",
        sortable: true,
        department: ['dob']
    },
    {
        id: "Sat",
        label: "Sat",
        sortable: true,
        department: ['dob']
    },
    {
        id: "Sun",
        label: "Sun",
        sortable: true,
        department: ['dob']
    },

    {
        id: "SSTCard",
        label: "SST Card",
        sortable: true,
        type: 'creed',
        department: ['mta', 'dob']

    }
    // {
    //     id: "NINTYDROWNYCT",
    //     label: "90D ROW \n  NYCT",
    //     sortable: true,
    //     department: ['mta']

    // },
    // {
    //     id: "NINTYDROWMNR",
    //     label: "90D ROW \n  MNR",
    //     sortable: true,
    //     department: ['mta']

    // },
    // {
    //     id: "NINTYDROWLIRR",
    //     label: "90D ROW \n  LIRR",
    //     sortable: true,
    //     department: ['mta']

    // },
    // {
    //     id: "NINTYDROWAMTRACK",
    //     label: "90D ROW  \n AMTRACK",
    //     sortable: true,
    //     department: ['mta']

    // },
    // {
    //     id: "NINTYDROWCSX",
    //     label: "90D ROW \n  CSX",
    //     sortable: true,
    //     department: ['mta']

    // },
    // {
    //     id: "TWOYSAFETYCOORDINATOR",
    //     label: "2Y Safety \n  Coordinator",
    //     sortable: true,
    //     department: ['mta']

    // },
    // {
    //     id: "FIVEYSAFETYCOORDINATOR",
    //     label: "5Y Safety \n  Exp",
    //     sortable: true,
    //     department: ['mta']

    // }

]
export default function RosterView({
    selectedDepartmentValue,
    disabledIds = [],
    showRecommended = false,
    projectUserRatings = false,
    alowEdit,
    showCheckColumn,
    selectedRows,
    setSelectedRows,
    handleCheckboxChange,
    allowDepartmentChange,
    tableHeight,
    creedRecommendation,
    creedRequest }) {
    const dispatch = useDispatch();
    const { projects } = useSelector(prop('project'));
    const { mtas } = useSelector(prop('mta'));
    const { rosters } = useSelector(prop('roster'));
    const { users, LoginUserRole, seletedRole } = useSelector(prop("user"));
    const { creeds } = useSelector(prop('creed'));
    const employmentTypes = dropDownOption.EmploymentTypes;
    useEffect(() => {
        // dispatch(actions.getProjects());
        dispatch(actions.getRosters());
        // dispatch(actions.getUsers());

    }, [])
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const updateColumns = () => {
        let columns = [];
        if (showRecommended) {
            columns.push(...RECOMMENDED_COLUMN)
        }

        columns.push({
            id: "Name",
            label: "Name",
            sortable: true
        })
        if (projectUserRatings) {
            columns.push(...PROJECT_COLUMNS)
        }
        columns.push(...FIXED_COLUMNS.filter(k => k.department?.includes(selectedDepartmentValue?.toLowerCase())));
        let creedColumns = []
        if (creeds.length > 0) {
            creedColumns = creeds.filter(k => k.ShowInRoaster && k.Department?.toLowerCase() === selectedDepartmentValue?.toLowerCase()).map(k => ({
                id: `${k.Id}`,
                label: `${k.CreedName}`,
                sortable: true,
                type: 'creed',
                creedFieldType: k.CreedFieldType
            }))
            columns = [...columns, ...creedColumns];
        }
        setColumns(columns)
    }
    useEffect(() => {
        updateColumns();
    }, [creeds, selectedDepartmentValue])

    useEffect(() => {
        const getFormattedTime = (time) => formattedTime(time);
        const getDaySlots = (rosters, dayName) => (
            rosters?.filter(k => k.Weekday?.DayName === dayName)?.map(k => ({
                Label: `${k.TimeSlot?.SlotName} (${getFormattedTime(k.TimeSlot?.StartTime)} - ${getFormattedTime(k.TimeSlot?.EndTime)})`,
                BackgroundColor: k.TimeSlot.BackgroundColor,
                TextColor: k.TimeSlot.TextColor
            }))
        );

        const getBackgroundColor = (date, noExpiration = false) => {
            if (noExpiration) {
                return { TextColor: 'white', BackgroundColor: 'blue' }
            }
            if (moment(new Date()).add(1, 'days').isAfter(date)) {
                return { TextColor: 'white', BackgroundColor: 'red' }
            }
            if (moment(new Date()).add(15, 'days').isAfter(date)) {
                return { TextColor: '#750909', BackgroundColor: '#ffd6d6' }
            }
            if (moment(new Date()).add(30, 'days').isAfter(date)) {
                return { TextColor: 'black', BackgroundColor: 'yellow' }
            }
            return { TextColor: 'white', BackgroundColor: 'green' }
        }
        const getUserData = (user) => {
            const currUserRosters = rosters?.filter(k => k.User?.Id == user.Id) || [];
            const isRecomdendate = creedRecommendation?.some(k => k.User?.Id == user.Id)
            const uniqueDays = new Set(currUserRosters.map(k => k.Weekday?.DayName));
            let userCreeds = {};
            if (user.UserCreeds?.length > 0) {
                userCreeds = user.UserCreeds.reduce((acc, curr) => {
                    acc[curr.Creed.Id] = {
                        Expiration: curr.ExpirationDate ? formatFullDate(curr.ExpirationDate) : '',
                        LicenseNo: curr.LicenseNo,
                        Link: curr.Link,
                        HasCertification: curr.HasCertification,
                        ...getBackgroundColor(curr.ExpirationDate, curr.NoExpiration)
                    };
                    return acc
                }, {})
            }

            let ProjectCoverageHistoryCount = 0;
            if (creedRequest && creedRequest.Project) {
                ProjectCoverageHistoryCount = user.ProjectVisitHistory.find(k => k.pid === creedRequest.Project.Id)?.count || 0
            }

            return {
                ...user,
                Name: user.Name,
                Note: user.Note,
                UserId: user.Id,
                SafetyPersonal: user.UserCreeds,
                Rate: user.Rate,
                Phone: user.MobileNo,
                EmploymentType: user.EmploymentType ? employmentTypes.find(k => k.Id === user.EmploymentType)?.Name : null,
                Email: user.Email,
                Address: user.Address,
                SSTCard: { LicenseNo: user.SSTCard || null, Expiration: user.SSTExpire ? formatFullDate(user.SSTExpire) : null, ...getBackgroundColor(user.SSTExpire) },
                MasterRoster: currUserRosters,
                Mon: getDaySlots(currUserRosters, 'Mon'),
                Tue: getDaySlots(currUserRosters, 'Tue'),
                Wed: getDaySlots(currUserRosters, 'Wed'),
                Thu: getDaySlots(currUserRosters, 'Thu'),
                Fri: getDaySlots(currUserRosters, 'Fri'),
                Sat: getDaySlots(currUserRosters, 'Sat'),
                Sun: getDaySlots(currUserRosters, 'Sun'),
                Availability: Array.from(uniqueDays),
                Recommended: isRecomdendate ? "Yes" : "No",
                ProjectCoverageHistoryCount: ProjectCoverageHistoryCount,
                ...userCreeds
            };
        };


        let tempData = [];

        if (users && users.length > 0) {
            tempData = users
                .filter(k => k.Role == 'staff')
                // .filter(k => ['mta', 'dob'].includes(k?.Department?.toLowerCase()))
                .filter(k => k.Departments?.some(d => ['mta', 'dob'].includes(d.Department?.toLowerCase())))
                // .filter(k => selectedDepartmentValue ? k?.Department?.toLowerCase() === selectedDepartmentValue.toLowerCase() : true)
                .filter(k => selectedDepartmentValue ? k?.Departments?.some(d => d.Department?.toLowerCase() == selectedDepartmentValue.toLowerCase()) : true)
                .map(user => getUserData(user))
                .sort((a, b) => b.ProjectCoverageHistoryCount - a.ProjectCoverageHistoryCount);

            setData(tempData);
        }
    }, [users, rosters, selectedDepartmentValue, creedRecommendation]);


    const getWeekDayTimeSlots = (data) => {
        let temp = []
        if (data && data.length > 0) {
            data.forEach(k => {
                temp = [...temp, ...k.WeekDayTimeSlots]
            })
        }
        return temp
    }

    return (
        <BgView>
            {/* <BackAppBar /> */}
            <DynamicRosterTable
                data={data}
                columns={columns}
                alowEdit={alowEdit}
                selectedDepartmentValue={selectedDepartmentValue}
                showCheckColumn={showCheckColumn}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                disabledIds={disabledIds}
                handleCheckboxChange={handleCheckboxChange}
                allowDepartmentChange={allowDepartmentChange}
                tableHeight={tableHeight}
            />
        </BgView>

    );
}

