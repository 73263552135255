import React from "react";
import BackAppBar from "components/app/BackAppBar";
import { useSelector } from "react-redux";
import { prop } from "ramda";
import BgView from "components/common/BgView";
import HolidayDetails from "./HolidayDetails";

export default function HolidayView() {
  const { holidayDetails } = useSelector(prop('holiday'));
  return (
    <BgView>
      <BackAppBar />
      <HolidayDetails Holiday={holidayDetails} />
    </BgView>
  );
}
