import Api from "services/api";
import {
  all,
  put,
  select,
  takeLatest,
  call,
  takeLeading,
  delay,
} from "redux-saga/effects";
import * as actions from "actions";
import { endProgress, failProgress, startProgress } from "sagas/common";
import {
  camelCase,
  chain,
  filter,
  get,
  map,
  orderBy,
  reject,
  set,
} from "lodash";

import { formatDate, formatUnixDate } from "utils/strUtils";
import history from "../history";
import path from "routesPath";
import { openInAppBrowser } from "utils/cordova";

function* getDJRs() {
  const progress = yield startProgress();
  try {
    const tx = yield Api.djr.getDJRsUsingGET();

    const djrs = chain(tx)
      .map((t) => set(t, "time", t.djrDateTime.getTime()))
      .map((t) => set(t, "date", formatDate(t.djrDateTime)))
      .map((t) => set(t, "unixDate", formatUnixDate(t.djrDateTime)))
      .groupBy("unixDate")
      .mapValues((v) => orderBy(v, "time", "desc"))
      .value();

    yield put(actions.setDJRProps({ djrs }));
    yield endProgress(progress);
  } catch (error) {
    console.error(error);
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "getDJRsFailed"));
  }
}

function* refreshDJRs() {
  const progress = yield startProgress();
  yield getDJRs();
  yield endProgress(progress);
}

function* showTxDetails(action) {
  // api call
  const progress = yield startProgress();
  try {
    const { djr } = action.payload;
    const body = {
      Id: djr.Id
    };
    const djrResp = yield Api.djr.getDjrByIdUsingPOST(body);
    yield put(actions.setDJRProps({ djrDetails: djrResp }));
    history.push(path.DJR);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }

  // const { djr } = action.payload;
  // yield put(actions.setDJRProps({ djrDetails: djr }));
  // history.push(path.DJR);
}

function showAllDJR() {
  history.push(path.allDJRs);
}

function* saveDJR(action) {
  const progress = yield startProgress();
  try {
    const data = yield Api.djr.saveDJR(action.payload);
    history.push(path.DJRSummary);
    delay(300);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "djrError"));
  }
}

function* updateJobStaus(action) {
  const progress = yield startProgress();
  try {
    
    const data = yield Api.djr.updateJobStatus(action.payload);
    const djrResp = yield Api.djr.getDjrByIdUsingPOST({Id:action.payload.ScheduleId});
    yield put(actions.setDJRProps({ djrDetails: djrResp }));
    delay(300);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "djrError"));
  }
}

function* uploadDjr(action) {
  const progress = yield startProgress();
  try {
    const data = yield Api.djr.uploadDJR(action.payload.file, action.payload.file);
    yield Api.djr.linkDjrToUploadDJR({ ...action.payload.schedule, UploadId:data[0].UploadId })
    history.push(path.DJRSummary);
    delay(300);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "djrError"));
  }
}


function* uploadInspection(action) {
  const progress = yield startProgress();
  try {
    const data = yield Api.djr.uploadInspection(action.payload.file, action.payload.file);
    yield Api.djr.linkToUploadInspection({ ...action.payload.schedule, UploadId:data[0].UploadId });
    history.push(path.DJRSummary);
    delay(300);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "inspectionError"));
  }
}


function* getDJRSummary() {
  const progress = yield startProgress();
  try {
    const data = yield Api.djr.getDJRSummary();
    yield put(actions.setDJRProps({ djrSummary: data }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "djrError"));
  }
}

function* djrTypeSelect(action) {
  const { type } = action.payload;
  yield put(actions.setDJRProps({ type: type }));
  history.push(path.allDJRs);
}

function* getDJRByType(action) {
  const progress = yield startProgress();
  try {
    const { type } = action.payload;
    const data = yield Api.djr.getDJRsByType(type);
    const djrs = chain(data)
      .map((t) => set(t, "time", t.StartDate.getTime()))
      .map((t) => set(t, "date", formatDate(t.StartDate)))
      .map((t) => set(t, "unixDate", formatUnixDate(t.StartDate)))
      .groupBy("unixDate")
      .mapValues((v) => orderBy(v, "time", "desc"))
      .value();
    yield put(actions.setDJRProps({ djrs: djrs }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "djrError"));
  }
}

function* downloadDJRs(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const tasks = yield Api.djr.dowloandDJRByIdUsingPOST(body);
    // to open in new tab
    // window.open(tasks);
    openInAppBrowser(tasks);
    // const url = window.URL.createObjectURL(new Blob([tasks]));

    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", `FileName.pdf`);

    // // Append to html link element page
    // document.body.appendChild(link);

    // // Start download
    // link.click();

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* downloadInspectionReport(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const tasks = yield Api.djr.dowloandInspectionByScheduleIdUsingPOST(body);
    openInAppBrowser(tasks);

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(actions.GET_DJRS, getDJRs),
    takeLatest(actions.REFRESH_DJRS, refreshDJRs),
    takeLatest(actions.DOWNLOAD_DJR, downloadDJRs),
    takeLatest(actions.DJR_DETAILS, showTxDetails),
    takeLatest(actions.SHOW_ALL_DJR, showAllDJR),
    takeLatest(actions.SAVE_DJR, saveDJR),
    takeLatest(actions.UPLOAD_DJR, uploadDjr),
    takeLatest(actions.UPDATE_JOB_STATUS, updateJobStaus),

    takeLatest(actions.UPLOAD_INSPECTION, uploadInspection),
    takeLatest(actions.DOWNLOAD_INSPECTION, downloadInspectionReport),

    takeLatest(actions.GET_DJR_SUMMARY, getDJRSummary),

    takeLatest(actions.GET_DJRS_BY_TYPE, getDJRByType),
    takeLatest(actions.DJR_TYPE_SELECT, djrTypeSelect),
  ]);
}
