import _ from 'lodash';

import {
  AuthControllerApi,
  NotificationControllerApi,
} from 'api/mobile';
import MobileApiClient from "api/mobile/ApiClient";
import AlertControllerApi from 'api/mobile/api/AlertsController';
import ClientControllerApi from 'api/mobile/api/ClientsControllerApi';
import ContractorControllerApi from 'api/mobile/api/ContractorsControllerApi';
import CreedControllerApi from 'api/mobile/api/CreedControllerApi';
import CreedRequestControllerApi from 'api/mobile/api/CreedRequestControllerApi';
import DJRControllerApi from 'api/mobile/api/DJRControllerApi';
import HolidayControllerApi from 'api/mobile/api/HolidayControllerApi';
import LeaveControllerApi from 'api/mobile/api/LeaveControllerApi';
import LoginControllerApi from 'api/mobile/api/LoginControllerApi';
import MTAControllerApi from 'api/mobile/api/MtaController';
import ProjectControllerApi from 'api/mobile/api/ProjectControllerApi';
import ReportControllerApi from 'api/mobile/api/ReportController';
import RosterControllerApi from 'api/mobile/api/RosterController';
import SubContractorControllerApi from 'api/mobile/api/SubContractorControllerApi';
import TaskControllerApi from 'api/mobile/api/TaskControllerApi';
import UserControllerApi from 'api/mobile/api/UsersControllerApi';
import AuthControllerMock from "api/mobile/mock/AuthControllerMock";


const USE_DEV_API = !!process.env.REACT_APP_USE_DEV_API;
const MOCK_API = !!process.env.REACT_APP_MOCK_API;
const MOCK_AUTH = MOCK_API || !!process.env.REACT_APP_MOCK_AUTH;

const API_PROFILE = USE_DEV_API ? '_DEV' : '';

let ORIGIN = process.env['REACT_APP_API_MOBIlE' + API_PROFILE];

export function getOrigin() {
  return ORIGIN;
}


function setBasePath(apis, pathVar, name, profile) {
  const basePath = process.env[pathVar + profile];
  if (!MOCK_API && !_.isEmpty(basePath)) {
    apis.forEach(api => _.set(api, ['apiClient', 'basePath'], basePath));
    console.info(`${name} API set to ${basePath}`);
  }
}

class Api {
  constructor() {
    this.auth = MOCK_AUTH
      ? new AuthControllerMock()
      : new AuthControllerApi();

    this.notification = new NotificationControllerApi();
    this.user = new UserControllerApi();
    this.client = new ClientControllerApi();
    this.login = new LoginControllerApi();
    this.contractor = new ContractorControllerApi();
    this.project = new ProjectControllerApi();
    this.creed = new CreedControllerApi();
    this.subContractor = new SubContractorControllerApi();
    this.task = new TaskControllerApi();
    this.alert = new AlertControllerApi();
    this.djr = new DJRControllerApi();
    this.holiday = new HolidayControllerApi();
    this.leave = new LeaveControllerApi();
    this.report = new ReportControllerApi();
    this.creedRequest = new CreedRequestControllerApi();
    this.MTA = new MTAControllerApi();
    this.Roster = new RosterControllerApi();
    this.initBasePath('REACT_APP_API_MOBIlE');
  }

  initBasePath(pathVar, profile = API_PROFILE) {
    setBasePath(
      [
        this.auth,
        this.customer,
        this.notification,
        this.transaction,
        this.user,
        this.client,
        this.login,
        this.project,
        this.holiday,
        this.contractor,
        this.subContractor,
        this.task,
        this.alert,
        this.leave,
        this.djr,
        this.creed,
        this.creedRequest,
        this.MTA,
        this.Roster
      ],
      pathVar,
      'mobile',
      profile,
    );
  }

  setAccessToken(accessToken) {
    MobileApiClient.instance.authentications['password_grant'] = { type: 'bearer', accessToken }
  }

  enableDemoMode() {
    ORIGIN = process.env['REACT_APP_API_MOBIlE_DEMO'];
    this.initBasePath('REACT_APP_API_MOBIlE', '_DEMO');
  }
}

const api = new Api();

export default api;
