import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const MTA_SET_PROPS = "MTA_SET_PROPS";

export const GET_MTAS = "GET_MTAS";
export const GET_MTAS_COMBINE = "GET_MTAS_COMBINE";
export const GET_MTANOTES = "GET_MTANOTES";
export const ADD_MTA = "ADD_MTA";
export const REMOVE_MTA = "REMOVE_MTA";
export const ADDMTANOTE = "ADDMTANOTE";
export const REFRESH_MTAS = "REFRESH_MTAS";
export const MTA_DETAILS = "MTA_DETAILS";
export const MTA_USERS = "MTA_USERS";

export const setMTAProps = payloadAction(MTA_SET_PROPS);

export const getMTAs = simpleAction(GET_MTAS);
export const getMTACombine = simpleAction(GET_MTAS_COMBINE);
export const getMTANotes = simpleAction(GET_MTANOTES);
export const addMTA = payloadAction(ADD_MTA);
export const removeMTA = payloadAction(REMOVE_MTA);
export const addMTANote = payloadAction(ADDMTANOTE);
export const refreshVirtualCard = simpleAction(REFRESH_MTAS);
export const showMTADetails = (MTA) =>
    createAction(MTA_DETAILS, { MTA });

export const getMTAUsers = (MTA) =>
    createAction(MTA_USERS, { MTA });