import { Box, Button, Container, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import { prop } from "ramda";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
    },
    feedbackFormContainer: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
}));

export default function JobDetailsView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { jobDetails } = useSelector(prop('creedRequest'));
    const { id } = useParams();

    useEffect(() => {
        console.log("JobDetailsView component mounted");
        console.log("Feedback data set:", jobDetails);
    }, [jobDetails]);

    useEffect(() => {
        dispatch(actions.getJobDetailsByNotifyId({ Id: id }));
    }, []);

    return (
        <div className={classes.root}>
            {/* <h1>Coverage</h1> */}
            <Container>
                <Box mb={3}>
                    <Typography variant="h6" mb={1}>
                        Coverage Request
                    </Typography>
                    <br />
                    <Typography variant="body" mb={1}>
                        <strong>Client: </strong> {jobDetails?.ClientName}
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body" mb={1}>
                        <strong>Project Name: </strong>{jobDetails?.Project}
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body" mb={2}>
                        <strong>Job Position: </strong>{jobDetails?.CreedName}
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body" mb={1}>
                        <strong>Site Location: </strong>  {jobDetails?.Location}
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body" mb={1}>
                        <strong>Date of Coverage: </strong> {jobDetails?.ShortDate}
                    </Typography>
                    <br />
                    <br />

                    <Typography variant="body" mb={1} mt={2}>
                        <strong>Working Hours Needed: </strong>{jobDetails?.StartTime} to {jobDetails?.EndTime}
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body" mb={2} mt={1}>
                        Please select the appropriate link for your availability below:
                    </Typography>
                    <br /> <br />
                    <Box mb={2} mt={1}>
                        <Link href={jobDetails?.AvailableLink} underline="none">
                            <Button variant="contained" color="success" sx={{ marginRight: 1 }}>
                                Mark Available
                            </Button>
                        </Link>
                        <Link href={jobDetails?.UnAvailableLink} underline="none">
                            <Button variant="contained" color="secondary">
                                Mark Unavailable
                            </Button>
                        </Link>
                    </Box>
                    <br />
                    <Typography variant="body1">
                        <strong>Note:</strong> This inquiry does not confirm this project has been assigned to you; a follow-up message will be sent upon confirmation.
                    </Typography>
                </Box>

                {/* <Box mt={4}>
                    <Typography variant="body">
                        Best regards,
                        <br />
                        ICC Admin
                    </Typography>
                </Box> */}
            </Container>

        </div>
    );
}
