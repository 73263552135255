/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CreedRequest from './CreedRequest';
import User from './User';
/**
 * The UserCreedsNotify model module.
 * @module model/UserCreedsNotify
 * @version 1.0
 */
class UserCreedsNotify {
    /**
     * Constructs a new <code>UserCreedsNotify</code>.
     * @alias module:model/UserCreedsNotify
     */
    constructor() {

        UserCreedsNotify.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>UserCreedsNotify</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserCreedsNotify} obj Optional instance to populate.
     * @return {module:model/UserCreedsNotify} The populated <code>UserCreedsNotify</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserCreedsNotify();

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }


            if (data.hasOwnProperty('AvailableLink')) {
                obj['AvailableLink'] = ApiClient.convertToType(data['AvailableLink'], 'String');
            }
            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('ClientName')) {
                obj['ClientName'] = ApiClient.convertToType(data['ClientName'], 'String');
            }
            if (data.hasOwnProperty('Project')) {
                obj['Project'] = ApiClient.convertToType(data['Project'], 'String');
            }
            if (data.hasOwnProperty('ProjectNo')) {
                obj['ProjectNo'] = ApiClient.convertToType(data['ProjectNo'], 'String');
            }
            if (data.hasOwnProperty('Location')) {
                obj['Location'] = ApiClient.convertToType(data['Location'], 'String');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'String');
            }
            if (data.hasOwnProperty('Comments')) {
                obj['Comments'] = ApiClient.convertToType(data['Comments'], 'String');
            }

            if (data.hasOwnProperty('ShortDate')) {
                obj['ShortDate'] = ApiClient.convertToType(data['ShortDate'], 'String');
            }
            if (data.hasOwnProperty('StartTime')) {
                obj['StartTime'] = ApiClient.convertToType(data['StartTime'], 'String');
            }
            if (data.hasOwnProperty('EndTime')) {
                obj['EndTime'] = ApiClient.convertToType(data['EndTime'], 'String');
            }
            if (data.hasOwnProperty('Date')) {
                obj['Date'] = ApiClient.convertToType(data['Date'], 'String');
            } if (data.hasOwnProperty('CreedName')) {
                obj['CreedName'] = ApiClient.convertToType(data['CreedName'], 'String');
            }




            if (data.hasOwnProperty('UnAvailableLink')) {
                obj['UnAvailableLink'] = ApiClient.convertToType(data['UnAvailableLink'], 'String');
            }
            if (data.hasOwnProperty('IsNotified')) {
                obj['IsNotified'] = ApiClient.convertToType(data['IsNotified'], 'Boolean');
            }
            if (data.hasOwnProperty('Status')) {
                obj['Status'] = ApiClient.convertToType(data['Status'], 'String');
            }
            if (data.hasOwnProperty('CreedRequest')) {
                obj['CreedRequest'] = ApiClient.convertToType(data['UserCreedsNotify'], CreedRequest);
            }
            if (data.hasOwnProperty('User')) {
                obj['User'] = ApiClient.convertToType(data['User'], User);
            }
        }
        return obj;
    }


}

/**
 * project first name
 * @member {String} UserCreedsNotifyName
 */
UserCreedsNotify.prototype['UserCreedsNotifyName'] = undefined;

UserCreedsNotify.prototype['Id'] = undefined;
/**
 * @member {Object} UserCreedsNotifyClients
 */



export default UserCreedsNotify;

