import React from 'react';
import IconButton from "@mui/material/IconButton";
import { ArrowBack } from "@mui/icons-material";
import { ArrowBackIos } from '@mui/icons-material';
import history from '../../history';
import LogoAppBar from "./LogoAppBar";
import TopAppBar from "./TopAppBar";
import makeStyles from '@mui/styles/makeStyles';
const useStyle = makeStyles({
  icon: {
    fontSize: '1rem'
  },
});
export default function BackAppBar({ showLogo, backPath, onBack = history.goBack, customBackAction, ...rest }) {
  const classes = useStyle();
  const handleBack = () => {
    if (customBackAction) {
      customBackAction();
    } else {
      if (backPath) {
        history.push(backPath);
      } else {
        onBack();
      }
    }
  }

  const Bar = showLogo ? LogoAppBar : TopAppBar;

  const back = (
    <IconButton
      color="primary"
      aria-label="Back"
      onClick={handleBack}
      data-testid='btn-back'
      className={classes.icon}
      size="large">
      <ArrowBackIos /> Back
    </IconButton>
  );

  return (
    <Bar {...rest} leftNav={back} />
  );
}
