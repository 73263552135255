/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

 import ApiClient from '../ApiClient';
 import { getNameStr } from 'utils/strUtils';
 /**
  * The Contractor model module.
  * @module model/Contractor
  * @version 1.0
  */
 class Contractor {
     /**
      * Constructs a new <code>Contractor</code>.
      * @alias module:model/Contractor
      */
     constructor() { 
         
         Contractor.initialize(this);
     }
 
     /**
      * Initializes the fields of this object.
      * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
      * Only for internal use.
      */
     static initialize(obj) { 
     }
 
     /**
      * Constructs a <code>Contractor</code> from a plain JavaScript object, optionally creating a new instance.
      * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
      * @param {Object} data The plain JavaScript object bearing properties of interest.
      * @param {module:model/Contractor} obj Optional instance to populate.
      * @return {module:model/Contractor} The populated <code>Contractor</code> instance.
      */
     static constructFromObject(data, obj) {
         if (data) {
             obj = obj || new Contractor();
 
             if (data.hasOwnProperty('FirstName')) {
                 obj['FirstName'] = ApiClient.convertToType(data['FirstName'], 'String');
             }
             if (data.hasOwnProperty('LastName')) {
                 obj['LastName'] = ApiClient.convertToType(data['LastName'], 'String');
             }
        
             if (data.hasOwnProperty('Email')) {
                obj['Email'] = ApiClient.convertToType(data['Email'], 'String');
            }
            if (data.hasOwnProperty('MobileNo')) {
                obj['MobileNo'] = ApiClient.convertToType(data['MobileNo'], 'String');
            }
             
             if (data.hasOwnProperty('Id')) {
                 obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
             }
             obj['Name'] = getNameStr(data);
         }
         return obj;
     }
 
 
 }
 
 /**
  * client first name
  * @member {String} FirstName
  */
 Contractor.prototype['FirstName'] = undefined;
 
 /**
  * client first name
  * @member {String} LastName
  */
  Contractor.prototype['LastName'] = undefined;
 /**
  * client first name
  * @member {String} Email
  */
  Contractor.prototype['Email'] = undefined;

  /**
 * @member {String} MobileNo
 */
Contractor.prototype['MobileNo'] = undefined;

  /**
  * rolw
  * @member {module:model/Contractor.Role} Role
  */
   Contractor.prototype['Role'] = undefined;
 
 /**
  * @member {String} Id
  */
  Contractor.prototype['Id'] = undefined;
  Contractor.prototype['Name'] = undefined;
 /**
  * Allowed values for the <code>Role</code> property.
  * @enum {String}
  * @readonly
  */
 Contractor['Role'] = {
 
     /**
      * value: "MANAGER"
      * @const
      */
     "MANAGER": "MANAGER",
 
     /**
      * value: "SALESPERSON"
      * @const
      */
     "SALESPERSON": "SALESPERSON",
 
 };
 
 
 
 export default Contractor;
 
 