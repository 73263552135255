import React from 'react';
import Dialog from "@mui/material/Dialog";
import {SlideUp} from "components/common/transitions";
import makeStyles from '@mui/styles/makeStyles';
import DialogContent from "@mui/material/DialogContent";
import {Box, Button, DialogActions, Typography} from "@mui/material";
import FingerprintIcon from '@mui/icons-material/Fingerprint';

const useStyle = makeStyles({
  icon: {
    fontSize: 50
  },
});

export default function BiometricPromptDialog({open, onRetry}) {
  const classes = useStyle();
  return (
      <Dialog open={open}
              TransitionComponent={SlideUp}>
        <DialogContent>
          <Box textAlign="center" mb={2}>
            <FingerprintIcon color="secondary" className={classes.icon}/>
          </Box>
          <Typography gutterBottom>
            Please enable biometric screenlock to use this application.
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button variant="contained"
                  color="primary"
                  onClick={onRetry}>
            Try again
          </Button>
        </DialogActions>
      </Dialog>
  );
}