import {
  all, put, takeLatest
} from "redux-saga/effects";
import path from "routesPath";
import {
  endProgress,
  failProgress,
  startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";


export function* getCreeds() {
  const progress = yield startProgress();
  try {
    const creeds = yield Api.creed.getCreedsUsingGET();
    yield put(actions.setCreedProps({ creeds }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addCreed(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const creed = yield Api.creed.addCreed(body);
    history.goBack()
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeCreed(action) {
  try {
    const id = action.payload.id;
    const creed = yield Api.creed.removeCreed(id);

    history.goBack()
  } catch (error) {
    console.error(error);
  }
}

function* refreshCreeds() {
  const progress = yield startProgress();
  yield getCreeds();
  yield endProgress(progress);
}

function* showCreedDetails(action) {
  const { creed } = action.payload;
  if (creed.isNew) {
    yield put(actions.setCreedProps({ creedDetails: creed }));
    history.push(path.creed.creedDetail);
  }
  else {
    const body = {
      Id: creed.Id
    };
    const djrResp = yield Api.creed.getCreedByIdUsingPOST(body);
    yield put(actions.setCreedProps({ creedDetails: djrResp }));
    history.push(path.creed.creedDetail);
  }

}
function* saveOrder(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const project = yield Api.creed.saveOrder(body);
    yield refreshCreeds();
    // history.goBack()
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}


export default function* saga() {
  yield all([
    takeLatest(actions.GET_CREEDS, getCreeds),
    takeLatest(actions.ADD_CREED, addCreed),
    takeLatest(actions.CREED_SAVE_ORDER, saveOrder),
    takeLatest(actions.REMOVE_CREED, removeCreed),
    takeLatest(actions.REFRESH_CREEDS, refreshCreeds),
    takeLatest(actions.CREED_DETAILS, showCreedDetails),
  ]);
}
