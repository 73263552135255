import {getOrigin} from "services/api";

const openInOldAppBrowser = (url, closeColor, toolbarColor) => {
  const features = [
    'location=no',
    'hidenavigationbuttons=yes',
    'hideurlbar=yes',
    `closebuttoncolor=${closeColor}`,
    'toolbar=yes',
    `toolbarcolor=${toolbarColor}`,
    `usewkwebview=yes`,
    'disallowoverscroll=yes',
    'enableViewportScale=yes',
  ];
  window.open(url, '_blank', features.join(','));
};

export function openInAppBrowser(url, closeColor = '#30306f', toolbarColor = '#ffffff') {
  if (window.device.platform === 'browser') {
    window.open(url, '_blank');
  } else if (!window.SafariViewController) {
    openInOldAppBrowser(url, closeColor, toolbarColor);
  } else {
    window.SafariViewController.isAvailable(available => {
      if (available) {
        window.SafariViewController.show(
          { url, tintColor: closeColor },
          () => {
            // if (result.event === 'opened') {
            //   console.log('opened');
            // } else if (result.event === 'loaded') {
            //   console.log('loaded');
            // } else if (result.event === 'closed') {
            //   console.log('closed');
            // }
          },
          error => {
            console.error(error);
          },
        );
      } else {
        openInOldAppBrowser(url, closeColor, toolbarColor);
      }
    });
  }
}

function openLegalDoc(type) {
  openInAppBrowser(getOrigin() + '/api/v1/docs/' + type);
}

export function openESignConsent() {
  openLegalDoc('E_SIGN_CONSENT');
}

export function openPrivacyPolicy() {
  openLegalDoc('MOBILE_APP_PRIVACY');
}

export function openTermsOfService() {
  openLegalDoc('TERMS');
}

export function openPrepaidCardTerms() {
  openLegalDoc('PREPAID_CARD_TNC');
}

export function openTempDoc(docId) {
  openInAppBrowser(getOrigin() + '/api/v1/docs/temp/' + docId);
}
