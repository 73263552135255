import { StyledEngineProvider, ThemeProvider, adaptV4Theme, createTheme } from "@mui/material/styles";
import React from "react";

const BRAND_COLOR = process.env["REACT_APP_THEME_BRAND_COLOR"] || "#f05423";

const lineGray = "#d8d8d8";

export const theme1 = createTheme(adaptV4Theme({
  palette: {
    mode: "light",
    primary: {
      main: "#f05423",
    },
    secondary: {
      main: "#7d7d8a",
    },
    warning: {
      main: "#eed202",
    },
    text: {
      primary: "#2c2c2c",
      secondary: "#6e6e6e",
    },
    common: {
      brand: BRAND_COLOR,

      dark: "#363640",
      gray: "#7d7d8a",
      lightGray: "#f6f6f6",
      lineGray,
      bgGray: "#f3f3f5",

      green: "#71bd39",
      red: "#eb001b",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    vendors: {
      venmo: "#3396ce",
      squareCash: "#33d633",
    },
  },

  typography: {
    fontFamily: "Lato, Arial, sans-serif",
    h1: {
      fontFamily: "Montserrat, Arial, sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "Montserrat, Arial, sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: "Montserrat, Arial, sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Montserrat, Arial, sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h5: {
      fontFamily: "Montserrat, Arial, sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h6: {
      fontFamily: "Montserrat, Arial, sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },

  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: ".8em",
      },
    },
    MuiButton: {
      root: {
        fontSize: "1rem",
        borderRadius: 5,
        textTransform: "none",
      },
      sizeSmall: {
        fontSize: ".9rem",
      },
      sizeLarge: {
        fontSize: "1.2rem",
      },
      outlinedPrimary: {
        borderColor: "#f05423",
        "&:hover": {
          borderColor: "#f05423",
        },
      },
      containedSecondary: {
        color: "#fff",
        backgroundColor: "#eb001b"
      },
      containedPrimary:{
        backgroundColor: "#f05423!important"
      }
    },

    MuiDialogActions: {
      root: {
        justifyContent: 'space-around',
        padding: "8px 24px",

        "& .MuiButton-root": {
          flex: 1,
        },
      },
      "> MuiButton": {
        background: 'red',
        root: {
          flex: '1',
          background: 'red',
        }
      }
    },
    MuiFab: {
      extended: {
        padding: "0 24px",
        fontSize: "1rem",
        textTransform: "none",
        borderRadius: 10,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#f6f6f6",
      },
      notchedOutline: {
        borderColor: lineGray,
      },
      input: {
        padding: 12,
      },
    },
    MuiDrawer: {
      paperAnchorBottom: {
        maxHeight: "90%",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      paperAnchorRight: {
        maxWidth: "80vw",
        minWidth: 250,
        width: "100%",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: lineGray,
      },
    },

    MuiListItem: {
      root: {
        padding: '12px 16px', // Example: Set padding for list items
        // backgroundColor: '#f6f6f6', // Example: Set background color for list items
        // borderBottom: `1px solid ${lineGray}`, // Example: Set border bottom color
        '&:hover': {
          backgroundColor: '#e0e0e0', // Example: Set background color on hover
        },
        divider: {
          borderBottomColor: lineGray,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: "bold",
      },
    },
    MuiListItemAvatar: {
      root: {
        alignItems: "center",
        display: "flex",
        minWidth: "40px",
      },
    },
    MuiTextField: {
      input: {
        padding: '16.5px 14px',
      },
    },
    // MuiTableCell: {
    //   root: {
    //     padding: '15px'
    //   },
    //   head: {
    //     // Customize the header cell styles
    //     fontWeight: 'bold',
    //     backgroundColor: '#e0e0e0', // Example: Set header background color
    //   },
    // },
    MuiTableCell: {
      root: {
        // Customize the table cell styles
        padding: '5px',
        borderBottom: '1px solid #ddd',
      },
      head: {
        // Customize the header cell styles
        fontWeight: 'bold',
        backgroundColor: '#e0e0e0',
        fontFamily: 'Montserrat, Arial, sans-serif',
        textTransform: 'uppercase',
        wordBreak: 'keep-all',
        whiteSpace: 'normal',
      },
      body: {
        color: '#333',
        fontFamily: 'Lato, Arial, sans-serif',
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#fafafa', // Example: Set striped rows
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: 72,
        textTransform: 'none',
        fontWeight: 'bold',
        fontFamily: 'Montserrat, Arial, sans-serif',
        textTransform: 'uppercase',
        '&.Mui-selected': {
          color: '#f05423', // Example: Set color for selected tab
        },
        '&:not(.Mui-selected)': {
          color: '#7d7d8a', // Example: Set color for non-selected tabs
        },
      },
    },
  },

  props: {
    initialHeight: () => window.innerHeight,
    scrollContainer: document.getElementById("root"),
  }
})
);

export default function Theme({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme1}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
