import {
  all, put, select, takeLatest
} from "redux-saga/effects";

import { prop } from "ramda";
import path from "routesPath";
import {
  endProgress,
  failProgress,
  startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

export function* getProjects() {
  const progress = yield startProgress();
  try {
    const projects = yield Api.project.getProjectsUsingGET();
    yield put(actions.setProjectProps({ projects }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

export function* getSpecification() {
  const progress = yield startProgress();
  try {
    const projectSpecifications = yield Api.project.getSpecificationUsingGET();
    yield put(actions.setProjectProps({ projectSpecifications }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addProject(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const project = yield Api.project.addProject(body);
    history.goBack()
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeProject(action) {
  try {
    const id = action.payload.Id;
    const project = yield Api.project.removeProject(id);

    history.goBack()
  } catch (error) {
    console.error(error);
  }
}

function* refreshProjects() {
  const progress = yield startProgress();
  yield getProjects();
  yield endProgress(progress);
}

function* showProjectDetails(action) {
  const { project } = action.payload;
  if (project.isNew) {
    yield put(actions.setProjectProps({ projectDetails: project }));
    history.push(path.project.projectDetail);
  }
  else {
    const body = {
      Id: project.Id
    };
    const djrResp = yield Api.project.getProjectByIdUsingPOST(body);
    yield put(actions.setProjectProps({ projectDetails: djrResp }));
    history.push(path.project.projectDetail);
  }

}

function* getUsersProject() {
  const userRes = yield Api.project.getUsersProjectUsingGET();
  yield put(actions.setProjectProps({ usersProject: userRes }));
}
function* saveOrder(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const project = yield Api.project.saveOrder(body);
    yield refreshProjects();
    // history.goBack()
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* updateProjectField(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const project = yield Api.project.updateProjectField(body);
    const { projects } = yield select(prop('project'))

    const idx = projects.findIndex(k => k.Id == body.Project.Id);
    if (idx != -1) {
      for (let key in body.Data) {
        if (projects[idx].hasOwnProperty(key)) {
          projects[idx][key] = body.Data[key]
        }
      }
    }

    yield put(actions.setProjectProps({ projects: JSON.parse(JSON.stringify(projects)) }));
    //history.goBack()
    // yield refreshProjects();
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* updateSpecification(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const project = yield Api.project.updateSpecification(body);
    yield getSpecification();
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(actions.GET_PROJECTS, getProjects),
    takeLatest(actions.GET_SPECIFICATION, getSpecification),
    takeLatest(actions.ADD_PROJECT, addProject),
    takeLatest(actions.PROJECT_SAVE_ORDER, saveOrder),
    takeLatest(actions.REMOVE_PROJECT, removeProject),
    takeLatest(actions.REFRESH_PROJECTS, refreshProjects),
    takeLatest(actions.PROJECT_DETAILS, showProjectDetails),
    takeLatest(actions.USERS_PROJECT, getUsersProject),
    takeLatest(actions.UPDATE_PROJECT_FIELD, updateProjectField),
    takeLatest(actions.UPDATE_SPECIFICATION, updateSpecification),
  ]);
}
