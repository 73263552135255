/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The InitResponse model module.
 * @module model/InitResponse
 * @version 1.0
 */
class InitResponse {
    /**
     * Constructs a new <code>InitResponse</code>.
     * @alias module:model/InitResponse
     */
    constructor() { 
        
        InitResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>InitResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InitResponse} obj Optional instance to populate.
     * @return {module:model/InitResponse} The populated <code>InitResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InitResponse();

            if (data.hasOwnProperty('smsId')) {
                obj['smsId'] = ApiClient.convertToType(data['smsId'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} smsId
 */
InitResponse.prototype['smsId'] = undefined;






export default InitResponse;

