import _ from 'lodash';
import {all, put, select, takeEvery} from "redux-saga/effects";

import * as actions from 'actions/error';
import * as cordovaActions from 'actions/cordova';
import {isConnectionError} from "utils/errorUtils";
import {prop} from "ramda";
import {getErrorResponse, getErrors} from "utils/httpUtils";

function getErrorMessages(error) {
  const errorMessages = getErrors(error);
  if (!_.isEmpty(errorMessages)) {
    // return errorMessages.map(er => er.errorDescription || er.message || er.defaultMessage);
    return errorMessages;
  }
  console.error('[Unhandled error]', error);
  return "Sorry, something did not work as intended. Please try again later";
}

function* showError(action) {
  const { error } = action.payload;

  //TODO handle accessToken error

  if (isConnectionError(error)) {
    yield put(cordovaActions.checkConnection());
  } else {
    const messages = getErrorMessages(error);

    const {code, stack} = error;
    const httpError = getErrorResponse(error);
    const originalError = httpError || {code, stack};

    yield put(actions.setErrorProps({ messages, originalError }));
  }
}

function* showDebugMessage(action) {
  const {isDeveloper = false} = yield select(prop('app'));
  if (isDeveloper) {
    const {message} = action.payload;
    yield put(actions.showMessage(message));
  }
}

export default function* saga() {
  yield all([
    takeEvery(actions.SHOW_ERROR_DRAWER, showError),
    takeEvery(actions.SHOW_DEBUG_MESSAGE, showDebugMessage),
  ]);
};