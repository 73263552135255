import {payloadAction} from "utils/stateUtils";

export const SHOW_ERROR_DRAWER = 'SHOW_ERROR_DRAWER';
export const SHOW_DEBUG_MESSAGE = 'SHOW_DEBUG_MESSAGE';
export const HIDE_ERROR_DRAWER = 'HIDE_ERROR_DRAWER';
export const ERROR_SET_PROPS = 'ERROR_SET_PROPS';

export const setErrorProps = payload => ({
  type: ERROR_SET_PROPS,
  payload,
});

export const showErrorDrawer = (error, method, withoutDrawer) => ({
  type: SHOW_ERROR_DRAWER,
  payload: {
    error,
    method,
    withoutDrawer,
  },
});

export const showMessage = messages => setErrorProps({ messages, expected: true});

export const hideErrorDrawer = () => setErrorProps({ messages: null, expected: null, originalError: null });

export const showDebugMessage = message => payloadAction(SHOW_DEBUG_MESSAGE)({message});