import BackAppBar from "components/app/BackAppBar";
import ClientList from "components/client/ClientList";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "actions";
import history from "../../history";
export default function ClientAdministartionView() {
 const dispatch = useDispatch();
  const clientDetails = (client) => {
    dispatch(actions.showClientDetails(client));
  };
  const newClientClick = (client = {}) => {
    dispatch(actions.showClientDetails({ ...client, isNew: true }));
  };
  return (
    <>
      <BackAppBar />
      <ClientList onItemSelect={clientDetails} onAddNewClient={newClientClick} />
    </>
  );
}
