import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import CreedList from "components/creed/CreedList";
import React from "react";
import { useDispatch } from "react-redux";
export default function CreedAdministartionView() {
  const dispatch = useDispatch();
  const clientDetails = (client) => {
    dispatch(actions.showCreedDetails(client));
  };
  const newCreedClick = (client = {}) => {
    dispatch(actions.showCreedDetails({ ...client, isNew: true }));
  };
  return (
    <>
      <BackAppBar />
      <CreedList onItemSelect={clientDetails} onAddNewCreed={newCreedClick} />
    </>
  );
}
