import {propsReducer} from "utils/stateUtils";
import {LEAVE_SET_PROPS} from "actions";

const DEFAULT_STATE = {
  leaves:[],
  leaveDetails:null,
  selectedUser:{}
};

export default propsReducer(LEAVE_SET_PROPS, DEFAULT_STATE);
