import React from "react";
import BackAppBar from "components/app/BackAppBar";
import { useSelector } from "react-redux";
import { prop } from "ramda";
import BgView from "components/common/BgView";
import ContractorDetails from "./ContractorDetails";

export default function ContractorView() {
  const { contractorDetails } = useSelector(prop('contractor'));
  return (
    <BgView>
      <BackAppBar />
      <ContractorDetails Contractor={contractorDetails} />
    </BgView>
  );
}
