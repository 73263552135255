import { PROJECT_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
  projects:[],
  projectSpecifications: [],
  usersProject: [],
  projectDetails: null, selectedDepartmentValue: 'mta',
};

export default propsReducer(PROJECT_SET_PROPS, DEFAULT_STATE);
