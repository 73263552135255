import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Autocomplete, Checkbox, Container, FormControlLabel, FormGroup, Stack, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import defaultValue from "assets/data/default-value";


import PrimaryButton from "components/common/PrimaryButton";
import TextValidator from "components/common/input/TextValidator";
import { chain, isEmpty, trim } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  ".MuiDialog-paper": {
    // backgroundColor:'red'
    margin: "5px",
    width: "100%",
    borderRadius: "10px",
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: "100vh",

    backgroundColor: theme.palette.background.paper,
  },
  title: {
    display: "flex",
    justifyItems: "center",
    justifyContent: "center",
  },
  top: {

    backgroundColor: theme.palette.background.paper,
  },
  container: {
    // position: "relative",

    padding: theme.spacing(2),
  },
  bottomBox: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px" }} {...other}>
      <strong> {children}</strong>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{

            position: "absolute!important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddCreedDialogue({ handleClose, open, data, onAddCreed, departments = [] }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { creeds } = useSelector(prop('creed'));
  const {
    Creed = null,
    ExpirationDate = null,
    LicenseNo = null,
    Link = null,
    NoExpiration = false,
    HasCertification = false,
    isNew = false,
    Id = null,
    IsExpired = false
  } = data;
  const [form, setForm] = useState({});
  useEffect(() => {
    setForm({
      Creed: Creed || null,
      ExpirationDate: ExpirationDate
        ? new Date(moment(ExpirationDate).format(defaultValue.dateTimeFormat))
        : null,
      Id: Id,
      LicenseNo: LicenseNo,
      Link: Link,
      IsExpired: IsExpired,
      NoExpiration: NoExpiration,
      HasCertification: HasCertification,
    });
  }, [data])

  const hasEmptyFields = chain({
    ...form,
  })
    .omit(["Id", "LicenseNo", "Link", "ExpirationDate"])
    .mapValues(trim)
    .some(isEmpty)
    .value();

  const onAdd = () => {
    let body = { ...form };

    onAddCreed(body);
  };

  const disabled = false;
  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {

    if (prop === "NoExpiration" || prop == 'HasCertification') {
      updateField(prop, e.target.checked);
    } else if (prop === 'BackgroundColor' || prop === 'TextColor') {
      updateField(prop, e.hex);

    } else {
       updateField(prop, e && e.target ? e.target.value : e);
     }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());
  const submitDisabled = hasEmptyFields;


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.title}
        >
          Add credentials
        </BootstrapDialogTitle>
        <DialogContent>
          <Container className={classes.container}>
            <ValidatorForm instantValidate={true} autoComplete="off">
              <Autocomplete
                id="creed"
                name="Credentials"
                options={creeds.filter(k => departments.some(dep => dep.Id == k.Department))}
                sx={{ width: "100%" }}
                renderInput={(params) => (

                  <TextField
                    {...params}
                    label="Credentials"
                    variant="standard"
                    margin="normal"
                    fullWidth
                  />
                )}
                getOptionLabel={(option) => `${option.CreedName} (${option.Department})`}

                value={form?.Creed || null}
                onChange={(_event, newValue) => {
                  updateField("Creed", newValue);
                }}
              />
              <LocalizationProvider dateAdapter={
                AdapterDateFns}>
                <Stack spacing={4}>
                  <MobileDatePicker
                    name="ExpirationDate"
                    label="Expiration Date"
                    value={form?.ExpirationDate || null}
                    onChange={handleChange("ExpirationDate")}
                    renderInput={(params) => (

                      <TextField
                        {...params}
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              <TextValidator
                autoComplete='new-password'
                name="LicenseNo"
                label="License No"
                value={form?.LicenseNo}
                margin="normal"
                fullWidth
                onChange={handleChange("LicenseNo")}
              />
              <TextValidator
                autoComplete='new-password'
                name="Link"
                label="Link"
                value={form?.Link}
                margin="normal"
                fullWidth
                onChange={handleChange("Link")}
              />
            </ValidatorForm>
            <FormGroup sx={{ m: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultValue='true'
                    checked={form?.NoExpiration}
                    onChange={handleChange("NoExpiration")}
                  />
                }
                label="No Expiration"
              />
            </FormGroup>
            <FormGroup sx={{ m: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultValue='true'
                    checked={form?.HasCertification}
                    onChange={handleChange("HasCertification")}
                  />
                }
                label="Has Certification"
              />
            </FormGroup>
          </Container>
        </DialogContent>
        <DialogActions>
          <PrimaryButton autoFocus onClick={onAdd} disabled={submitDisabled}>
            Save
          </PrimaryButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
