import React, { useEffect, useState } from 'react';
import { Route, Switch } from "react-router-dom";
import { routes } from "routes";
import ProgressSpinner from "./ProgressSpinner";
import { useDispatch, useSelector } from "react-redux";
import { prop } from "ramda";
import BiometricPromptDialog from "components/app/BiometricPromptDialog";
import * as actions from 'actions';
import ScreenBlur from "components/app/ScreenBlur";
// import { initializeApp } from 'firebase/app';
// import { firebaseConfig, messaging, getToken, onMessage } from 'firebaseConfig';
// const app = initializeApp(firebaseConfig);
export default function App() {
  const progress = useSelector(prop('progress'));
  const { notSecureDevice, blurForAuth } = useSelector(prop('cordova'));

  const [fcmToken, setFcmToken] = useState(null);
  const showBiometricPrompt = notSecureDevice === true;

  const dispatch = useDispatch();
  const handleBiometricRetry = () => dispatch(actions.checkSecureStorage())

  // useEffect(() => {
  //   if ('Notification' in window) {
  //     Notification.requestPermission()
  //       .then((permission) => {
  //         if (permission === 'granted') {
  //           console.log('Notification permission granted.');
  //           // Get the FCM token
  //           getToken(messaging)
  //             .then((currentToken) => {
  //               if (currentToken) {
  //                 alert("token",currentToken)
  //                 console.log('FCM Token:', currentToken);
  //                 setFcmToken(currentToken); //
  //                 // Send the token to your server
  //               //  sendTokenToServer(currentToken);
  //               } else {
  //                 console.log('No registration token available. Request permission to generate one.');
  //               }
  //             })
  //             .catch((err) => {
  //               alert(err);
  //               console.log('An error occurred while retrieving token. ', err);
  //             });
  //         } else {
  //           console.log('Unable to get permission to notify.');
  //         }
  //       });
  //   } else {
  //     console.log('Notification API not supported in this browser.');
  //   }

  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     // Show notification when the app is in the foreground
  //     const { title, body } = payload.notification;
  //     new Notification(title, { body });
  //   });
  // }, []);

  return (
    <>
      {/* <div>FCM Token:</div>      {fcmToken && <div>FCM Token: {fcmToken}</div>} */}
      <Switch>
        {routes.map((route, idx) => <Route {...route} key={idx} exact />)}
      </Switch>

      <ProgressSpinner {...progress} />

      <BiometricPromptDialog open={showBiometricPrompt} onRetry={handleBiometricRetry} />

      {blurForAuth && <ScreenBlur />}
    </>
  );
}
