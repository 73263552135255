/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DJRSummary from "../model/DJRSummary";
import ScheduleWithDJRs from "../model/ScheduleWithDJRs";
import Uploads from "../model/Uploads";
import APIEndPoints from "./ApiEndpoints";

/**
* CardsController service.
* @module api/DJRControllerApi
* @version 1.0
*/
export default class DJRControllerApi {

  /**
  * Constructs a new DJRControllerApi. 
  * @alias module:api/DJRControllerApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
    this.endPoints = new APIEndPoints();
  }




  /**
   * getDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/DJR>} and HTTP response
   */
  getDJRSummaryWithHttpInfo() {
    let postBody = null;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ['*/*'];
    let returnType = DJRSummary;
    return this.apiClient.callApi(
      this.endPoints.DJR.GetSummary.URI,
      this.endPoints.DJR.GetSummary.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * getDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/DJR>}
   */
  getDJRSummary() {
    return this.getDJRSummaryWithHttpInfo()
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * getDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/DJR>} and HTTP response
   */
  getDJRsByTypeWithHttpInfo(type) {
    let postBody = null;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ['*/*'];
    let returnType = [ScheduleWithDJRs];
    let queryStr = `?page=1&limit=550&type=${type}`
    return this.apiClient.callApi(
      this.endPoints.DJR.GetDJRsByType.URI + queryStr,
      this.endPoints.DJR.GetDJRsByType.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * getDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/DJR>}
   */
  getDJRsByType(type) {
    return this.getDJRsByTypeWithHttpInfo(type)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
 * getsaveDJR
 * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Client>} and HTTP response
 */
  saveDJRWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ['*/*'];
    let returnType = DJRSummary;
    return this.apiClient.callApi(
      this.endPoints.DJR.Save.URI,
      this.endPoints.DJR.Save.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * getsaveDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
   */
  saveDJR(body) {
    return this.saveDJRWithHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }



 /**
 * updateJobStatus
 * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Client>} and HTTP response
 */
  updateJobStatusWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ['*/*'];
    let returnType = DJRSummary;
    return this.apiClient.callApi(
      this.endPoints.DJR.UpdateJobStatus.URI,
      this.endPoints.DJR.UpdateJobStatus.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * getsaveDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
   */
  updateJobStatus(body) {
    return this.updateJobStatusWithHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }

  dowloandDJRByIdUsingPOST(body) {
    return this.dowloandDJRByIdUsingPOSTWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  dowloandDJRByIdUsingPOSTWithHttpInfo(body) {

    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = Blob;
    return this.apiClient.callApi(
      this.endPoints.DJR.DownloadByURL.URI,
      this.endPoints.DJR.DownloadByURL.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }


  getDjrByIdUsingPOST(body) {
    return this.getDjrByIdUsingPOSTWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  getDjrByIdUsingPOSTWithHttpInfo(body) {

    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = ScheduleWithDJRs;
    return this.apiClient.callApi(
      this.endPoints.DJR.GetById.URI,
      this.endPoints.DJR.GetById.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }


  uploadDJRHttpInfo(body,fp) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = fp;

    let authNames = ["password_grant"];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['*/*'];
    let returnType = [Uploads];
    return this.apiClient.callApi(
      this.endPoints.DJR.Upload.URI,
      this.endPoints.DJR.Upload.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * getsaveDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
   */
  uploadDJR(body,fp) {
    return this.uploadDJRHttpInfo(body,fp)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }



  linkDjrToUploadDJRHttpInfo(body) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ['*/*'];
    let returnType = DJRSummary;
    return this.apiClient.callApi(
      this.endPoints.DJR.LinkDJR.URI,
      this.endPoints.DJR.LinkDJR.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * getsaveDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
   */
  linkDjrToUploadDJR(body) {
    return this.linkDjrToUploadDJRHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  dowloandInspectionByScheduleIdUsingPOST(body) {
    return this.dowloandInspectionBySchedulePOSTWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  dowloandInspectionBySchedulePOSTWithHttpInfo(body) {

    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = Blob;
    return this.apiClient.callApi(
      this.endPoints.DJR.DownloadInspectionPDFByURL.URI,
      this.endPoints.DJR.DownloadInspectionPDFByURL.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }


  uploadInspectionHttpInfo(body,fp) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = fp;

    let authNames = ["password_grant"];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['*/*'];
    let returnType = [Uploads];
    return this.apiClient.callApi(
      this.endPoints.DJR.Upload.URI,
      this.endPoints.DJR.Upload.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * uploadInspection
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
   */
  uploadInspection(body,fp) {
    return this.uploadInspectionHttpInfo(body,fp)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }



  linkInspectionToUploadDJRHttpInfo(body) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ['*/*'];
    let returnType = DJRSummary;
    return this.apiClient.callApi(
      this.endPoints.DJR.LinkInspection.URI,
      this.endPoints.DJR.LinkInspection.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * linkToUploadInspection
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
   */
  linkToUploadInspection(body) {
    return this.linkInspectionToUploadDJRHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }
}
