import ListItem from "@mui/material/ListItem";
import React from "react";
import RecipeReviewCard from "./Card-Item";


export default function CreedRequestListItem({ creedRequest = {}, onEditClick, onDeleteClick, onRateReviewClick, onCancelClick, itemClasses }) {



  return (
    <ListItem
      dense
      disableGutters
      // onClick={handleClick}
      className={itemClasses}
      data-testid={'li-item-' + creedRequest.id}
    >
      {/* <ListItemAvatar>
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
        primary={Name}
        primaryTypographyProps={{ className: "bold" }}
      /> */}
      <RecipeReviewCard creedRequest={creedRequest}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        onCancelClick={onCancelClick}
        onRateReviewClick={onRateReviewClick}
      >

      </RecipeReviewCard>
    </ListItem>
  );
}
