import AddIcon from '@mui/icons-material/Add';
import { Box, Container, Fab, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import SearchBar from "components/common/SearchBar";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreedListItem from "./CreedListItem";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
    listStyle: 'none',
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
    cursor: 'grab',

  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function CreedList({ height, onItemSelect, onAddNewCreed }) {
  const classes = useStyles();
  const handleClick = (creed) => () => !!onItemSelect && onItemSelect(creed);
  const handelNewCreedClick = () => !!onAddNewCreed && onAddNewCreed();


  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getCreeds());
  }

  useEffect(updateAll, []);
  const { creeds } = useSelector(prop('creed'));

  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = React.useState('');
  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }

  useEffect(() => {
    let filterdList = creeds ? creeds : [];
    if (searchText.length > 0) {
      filterdList = filterdList.filter(k => k.CreedName?.toLowerCase().includes(searchText)
        || k.CreedNo?.toLowerCase().includes(searchText));
    }
    console.log(filterdList)
    setFilteredList(filterdList)
  }, [creeds, searchText]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let updatedList = Array.from(creeds); // Use the actual list
    const movedUserId = filteredList[result.source.index].Id;
    const movedUserIndex = updatedList.findIndex(user => user.Id === movedUserId);

    if (movedUserIndex !== -1) {
      const [movedItem] = updatedList.splice(movedUserIndex, 1);
      updatedList.splice(result.destination.index, 0, movedItem);
      updatedList = updatedList.map((user, index) => ({ ...user, Order: index + 1 }));
      dispatch(actions.saveCreedOrder(updatedList));
    }

  };

  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            Credential administration
          </strong>
        </Typography>
        {/* <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many Credentials as you want.
        </Typography> */}
      </Box>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of Credentials
          </strong>
        </Typography>
        <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
      </Box>


      {/* <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
          <ListItem
            dense
            disableGutters
            onClick={handelNewCreedClick}
            className={classes.item}
            data-testid={'li-add-user'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a Credential'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem>
          {filteredList?.map((creed) => (
            <CreedListItem
              key={creed.Id}
              creed={creed}
              onClick={handleClick(creed)}
              itemClasses={classes.item}

            />
          ))}
        </ul>
      </List> */}



      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <List
              className={classes.root}
              style={{ height }}
              dense={false}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <ul className={classes.ul}>
                {filteredList?.map((creed, index) => (
                  <Draggable key={creed.Id} draggableId={creed.Id.toString()} index={index} >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <CreedListItem
                          key={creed.Id}
                          creed={creed}
                          onClick={handleClick(creed)}
                          itemClasses={classes.item}

                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </ul>
              {provided.placeholder}
            </List>
          )}
        </Droppable>

        {/* Floating Add Button */}
        <Fab color="primary" className={classes.fab} onClick={handelNewCreedClick} data-testid={"floating-add-button"}>
          <AddIcon />
        </Fab>

      </DragDropContext>

    </Container>
  );
}
