
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


 import ApiClient from "../ApiClient";
 import APIEndPoints from "./ApiEndpoints";
 
 /**
 * CardsController service.
 * @module api/ReportControllerApi
 * @version 1.0
 */
 export default class ReportControllerApi {
 
     /**
     * Constructs a new ReportControllerApi. 
     * @alias module:api/ReportControllerApi
     * @class
     * @param {module:ApiClient} [apiReport] Optional API report implementation to use,
     * default to {@link module:ApiClient#instance} if unspecified.
     */
     constructor(apiReport) {
         this.apiReport = apiReport || ApiClient.instance;
         this.endPoints = new APIEndPoints();
     }
 
 
 
 
    
     downloadReport(body) {
        return this.downloadReportWithHttpInfo(body).then(function (
          response_and_data
        ) {
          return response_and_data.data;
        });
      }
    
      downloadReportWithHttpInfo(body) {
    
        let postBody = body;
    
        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {};
    
        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = Blob;
        return this.apiReport.callApi(
          this.endPoints.Report.Download.URI,
          this.endPoints.Report.Download.Type,
          pathParams,
          queryParams,
          headerParams,
          formParams,
          postBody,
          authNames,
          contentTypes,
          accepts,
          returnType,
          null
        );
      }
  
 
 
 }
 