import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const HOLIDAY_SET_PROPS = "HOLIDAY_SET_PROPS";

export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const REMOVE_HOLIDAY = "REMOVE_HOLIDAY";
export const REFRESH_HOLIDAYS = "REFRESH_HOLIDAYS";
export const HOLIDAY_DETAILS = "HOLIDAY_DETAILS";

export const setHolidayProps = payloadAction(HOLIDAY_SET_PROPS);

export const getHolidays = simpleAction(GET_HOLIDAYS);
export const addHoliday = payloadAction(ADD_HOLIDAY);
export const removeHoliday = payloadAction(REMOVE_HOLIDAY);
export const refreshVirtualCard = simpleAction(REFRESH_HOLIDAYS);
export const showHolidayDetails = (holiday) =>
  createAction(HOLIDAY_DETAILS, { holiday });
