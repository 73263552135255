import AddIcon from '@mui/icons-material/Add';
import { Box, Container, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import SearchBar from "components/common/SearchBar";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubContractorListItem from "./SubContractorListItem";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
}));

export default function SubContractorList({ height, onItemSelect, onAddNewSubContractor }) {
  const classes = useStyles();
  const handleClick = (subContractor) => () => !!onItemSelect && onItemSelect(subContractor);
  const handelNewSubContractorClick = () => !!onAddNewSubContractor && onAddNewSubContractor();


  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getSubContractors());
  }

  useEffect(updateAll, []);
  const { subContractors } = useSelector(prop('subContractor'));


  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }

  useEffect(() => {
    let filterdList = subContractors ? subContractors : [];
    if (searchText.length>0) {
     filterdList = filterdList.filter(k=>k.FirstName?.toLowerCase().includes(searchText));
    } 
    setFilteredList(filterdList)
  }, [subContractors, searchText]);

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            SubContractor administration
          </strong>
        </Typography>
        {/* <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many subContractors as you want.
        </Typography> */}
      </Box>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of subContractors
          </strong>
        </Typography>
        <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>

      </Box>
      <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>

        <ListItem
            dense
            disableGutters
            onClick={handelNewSubContractorClick}
            className={classes.item}
            data-testid={'li-add-user'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a SubContractor'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem>
          {filteredList?.map((subContractor) => (
            <SubContractorListItem
              key={subContractor.Id}
              subContractor={subContractor}
              onClick={handleClick(subContractor)}
              itemClasses={classes.item}
              
            />
          ))}

        </ul>
      </List>
    </Container>
  );
}
