import { all, put, takeLatest, select } from "redux-saga/effects";

import * as actions from "../actions";
import Api from "services/api";
import { endProgress, failProgress, startProgress } from "sagas/common";
import { prop } from "ramda";
import history from "../history";
import path from "routesPath";
import { saveAs } from 'file-saver';



function* downloadReport(action) {
    const progress = yield startProgress();
    try {
      const body = action.payload;
      const tasks = yield Api.report.downloadReport(body);
      const csvData = new Blob([tasks], { type: 'text/csv;charset=utf-8;' });
      saveAs(csvData, "report.csv");
      yield put(actions.setCordovaProps({ isCheckConnectionProcessing: false, isOffline: false }));

      yield endProgress(progress);
    } catch (error) {
      yield failProgress(progress);
      yield put(actions.showErrorDrawer(error, error.error));
    }
}

export default function* saga() {
  yield all([
    takeLatest(actions.DOWNLOAD_REPORT, downloadReport),
  ]);
}
