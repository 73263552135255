
const set = key => value => window.localStorage.setItem(key, value);
const preset = (key, value) => () => set(key)(value);
const get = key => () => window.localStorage.getItem(key);
const remove = key => () => window.localStorage.removeItem(key);

const setObject = key => value => set(key)(JSON.stringify(value));
const getObject = key => () => {
  const o = window.localStorage.getItem(key);
  return o ? JSON.parse(o) : undefined;
}


const storage = {

  setHasAuth: preset('hasAuth', true),
  clearHasAuth: remove('hasAuth'),
  hasAuth: get('hasAuth'),
  getObject: getObject,
  setObject: setObject,
  set: set,
  get: get
}

export default storage;
