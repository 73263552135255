import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class ListItem extends React.Component {
  state = {
    editing: false,
    expanded: null,
  }
  
 
  // Will put task on the screen depending on editing mode
  renderTasks() {
    // Formatting for prop passing - shortening
    const { Location, isComplete, classes } = this.props;

    const taskStyle = {
      cursor: 'pointer',
      fontWeight: 'bold',
      color: isComplete ? '#54f446' : '#f43838'
    }

    // if is editing - show text input box
    if (this.state.editing) {
      return (
        <div key={Location}>
          <form onSubmit={this.handleSave.bind(this)}>
            <input name={Location} type="text" defaultValue={Location} ref="editInput"/>
          </form>
        </div>
      )
    }

    // if not editing - just show text
    return (
      <Typography  key={Location}>
        {Location}
      </Typography>
      
    )
  }


  // Will put buttons on screen depending on editing mode
  renderButtons() {
    // if editing - show these buttons (Cancel, Delete, Save)
    if (this.state.editing) {
      return (
        <div>
          <Button variant='contained' sx={{mr:'5px!important'}} onClick={this.handleToggleEdit.bind(this)}>Cancel</Button>
          <Button  variant='contained' sx={{mr:'5px!important'}} onClick={this.handleDelete.bind(this)}>Delete</Button>
          <Button variant='contained' onClick={this.handleSave.bind(this)}>Save</Button>
        </div>
      )
    }

    // if not editing - only show Edit button
    return (
      <div>
        <Button variant='contained' onClick={this.handleToggleEdit.bind(this)}>Edit</Button>
      </div>
    )
  }

  // Overall output to screen
  render() {
    const { index } = this.props;
    const { expanded } = this.state;
    return (
      <Accordion key={index} expanded={expanded === 'panel' + (index + 1)} onChange={this.handleChange('panel' + (index + 1))}>
        <AccordionSummary key={index} expandIcon={<ExpandMoreIcon />} sx={{width:'100%',m:'1'}}
        
      
        >
          
          <Typography sx={{pl:'10px'}}>{this.renderTasks()}</Typography>
        </AccordionSummary>
        <AccordionDetails key={index}>
          {this.renderButtons()}
        </AccordionDetails>
      </Accordion>
      
    )
  }

  handleChange = panel => (event, expanded) => {
    if(event.target.classList.contains('MuiSvgIcon-root')){
    this.setState({
      expanded: expanded ? panel : false,
    });}
  };

  //Change title colour on click
  handleToggleComplete() {
    const taskToToggle = this.props;
    this.props.toggleTask(taskToToggle);
  }

  // Will trigger deleteTask() in app.js
  handleDelete() {
    const taskToDelete = this.props.Location;
    this.props.deleteTask(taskToDelete);
    // need to re-toggle edit when deleting to stop editmode being applied to next in line
    this.handleToggleEdit();
  }

  // Will trigger saveTask() in app.js
  handleSave(event) {
    event.preventDefault();

    const oldTask = this.props.Location;
    const newTask = this.refs.editInput.value;    
    this.props.saveTask(oldTask, newTask);

    // Set editing mode back to false
    this.setState({editing: false});
  }

  // Triggers edit locally
  handleToggleEdit() {
    this.setState(prevState => ({ editing: !prevState.editing}));
  }
}

export default ListItem;