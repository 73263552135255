import * as actions from "actions";
import ProjectList from "components/project/ProjectList";
import React from "react";
import { useDispatch } from "react-redux";
export default function ProjectAdministartionView() {
  const dispatch = useDispatch();
  const clientDetails = (client) => {
    dispatch(actions.showProjectDetails(client));
  };
  const newProjectClick = (client = {}) => {
    dispatch(actions.showProjectDetails({ ...client, isNew: true }));
  };
  return (
    <>
      {/* <BackAppBar /> */}
      <ProjectList onItemSelect={clientDetails} onAddNewProject={newProjectClick} />
    </>
  );
}
