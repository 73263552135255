/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import AuthData from "../model/AuthData";
import InitResponse from "../model/InitResponse";
import LoginResponse from "../model/LoginResponse";
import APIEndPoints from "./ApiEndpoints";
/**
 * AuthController service.
 * @module api/AuthControllerApi
 * @version 1.0
 */
export default class AuthControllerApi {
  /**
   * Constructs a new AuthControllerApi.
   * @alias module:api/AuthControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
    this.endPoints = new APIEndPoints();
  }

  /**
   * generateJWKS
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link String} and HTTP response
   */
  generateJWKSUsingGETWithHttpInfo() {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = "String";
    return this.apiClient.callApi(
      this.endPoints.auth.JWKS.URI,
      this.endPoints.auth.JWKS.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * generateJWKS
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link String}
   */
  generateJWKSUsingGET() {
    return this.generateJWKSUsingGETWithHttpInfo().then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * getVersion
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link String} and HTTP response
   */
  getVersionUsingGETWithHttpInfo() {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = "String";
    return this.apiClient.callApi(
      this.endPoints.auth.Version.URI,
      this.endPoints.auth.Version.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getVersion
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link String}
   */
  getVersionUsingGET() {
    return this.getVersionUsingGETWithHttpInfo().then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * Login using a phone and a one-time-password
   * @param {module:model/LoginRequest} req req
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResponse} and HTTP response
   */
  loginUsingPOSTWithHttpInfo(req) {
    let postBody = req;
    // verify the required parameter 'req' is set
    if (req === undefined || req === null) {
      throw new Error(
        "Missing the required parameter 'req' when calling loginUsingPOST"
      );
    }

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["*/*"];
    let returnType = LoginResponse;
    return this.apiClient.callApi(
      this.endPoints.auth.Login.URI + `/${postBody.phone}`,
      this.endPoints.auth.Login.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Login using a phone and a one-time-password
   * @param {module:model/LoginRequest} req req
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResponse}
   */
  loginUsingPOST(req) {
    return this.loginUsingPOSTWithHttpInfo(req).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }



  /**
   * Refreshes JWT token
   * @param {module:model/RefreshRequest} req req
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AuthData} and HTTP response
   */
  refreshTokenUsingPOSTWithHttpInfo(req) {
    let postBody = req;
    // verify the required parameter 'req' is set
    if (req === undefined || req === null) {
      throw new Error(
        "Missing the required parameter 'req' when calling refreshTokenUsingPOST"
      );
    }

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["*/*"];
    let returnType = AuthData;
    return this.apiClient.callApi(
      this.endPoints.auth.Refresh.URI,
      this.endPoints.auth.Refresh.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Refreshes JWT token
   * @param {module:model/RefreshRequest} req req
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AuthData}
   */
  refreshTokenUsingPOST(req) {
    return this.refreshTokenUsingPOSTWithHttpInfo(req).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * Request OTP
   * @param {module:model/InitRequest} req req
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InitResponse} and HTTP response
   */
  sendOTPRequestUsingPOSTWithHttpInfo(req) {
    let postBody = req;
    // verify the required parameter 'req' is set
    if (req === undefined || req === null) {
      throw new Error(
        "Missing the required parameter 'req' when calling sendOTPRequestUsingPOST"
      );
    }

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["*/*"];
    let returnType = InitResponse;
    return this.apiClient.callApi(
      this.endPoints.auth.Init.URI,
      this.endPoints.auth.Init.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Request OTP
   * @param {module:model/InitRequest} req req
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InitResponse}
   */
  sendOTPRequestUsingPOST(req) {
    return this.sendOTPRequestUsingPOSTWithHttpInfo(req).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }
}
