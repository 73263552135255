import * as actions from "../actions";
import { put, select } from "redux-saga/effects";
import { prop } from "ramda";
import history from "../history";
import Api from "services/api";
import path from "routesPath";
import jwt_decode from "jwt-decode";

export function* startProgress() {
  const progressAction = actions.showProgress();
  yield put(progressAction);
  return progressAction.payload.id;
}

export function* endProgress(progressId) {
  if (progressId) {
    yield put(actions.hideProgress(progressId));
  }
}

export function* failProgress(progressId) {
  if (progressId) {
    yield put(actions.hideProgress(progressId, true));
  }
}

export function* getAccessToken() {
  try {
    const { auth } = yield select(prop('user'));
    const { accessToken, refreshToken } = auth || {};
    const accessExpires = getAccessExpiry(accessToken);
    if (accessToken && isTokenAlive(accessExpires)) {
      return auth;
    }

    let newTokens;

    if (refreshToken) {
      newTokens = yield refreshAccessToken(auth);
    }

    if (!newTokens) {
      history.push(path.login);
      throw new Error('Authorization required');
    }
    return newTokens;
  }
  catch (e) {
    history.push(path.login);
    throw new Error('Authorization required');
  }
}

const TOKEN_EXPIRATION_TIME = 1000 * 60 * 59;

function isTokenAlive(accessExpires) {
  // return Date.now() - accessExpires < TOKEN_EXPIRATION_TIME;
  return Date.now() > accessExpires;
}

function getAccessExpiry(accessToken) {
  if (accessToken) {
    const decoded = jwt_decode(accessToken);
    const { exp = Date.now() } = decoded;
    return exp;
  }
}
function* refreshAccessToken(auth) {
  try {
    const { device } = yield select(prop('cordova'));
    const body = { deviceId: device.uuid, refreshToken: auth.refreshToken }
    const data = yield Api.auth.refreshTokenUsingPOST(body);
    return data;
  } catch (error) {
    console.error(error);
  }
}






