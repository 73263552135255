import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    styled
} from '@mui/material';
import PrimaryButton from 'components/common/PrimaryButton';
import React, { useEffect, useState } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    ".MuiDialog-paper": {
        margin: "5px",
        width: "100%",
        borderRadius: "10px",
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px" }} {...other}>
            <strong>{children}</strong>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const EditNoteDialog = ({ handleClose, open, data, onUpdate }) => {
    const [note, setNote] = useState('');

    useEffect(() => {
        if (data && data.Note) {
            setNote(data.Note);
        } else {
            setNote('');
        }
    }, [data]);

    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleSave = async () => {
        try {
            let dataForSave = {
                Id: data.Id,
                Note: note
            };
            onUpdate(dataForSave);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Add Note
            </BootstrapDialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="note"
                    label="Note"
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={note}
                    onChange={handleNoteChange}
                />
            </DialogContent>

            <DialogActions>
                <PrimaryButton autoFocus onClick={handleSave}>
                    Save Changes
                </PrimaryButton>
            </DialogActions>
        </BootstrapDialog>
    );
};

export default EditNoteDialog;
