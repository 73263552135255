import BackAppBar from "components/app/BackAppBar";
import ContractorList from "components/contractor/ContractorList";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "actions";
import history from "../../history";
export default function ContractorAdministartionView() {
 const dispatch = useDispatch();
  const contractorDetails = (contractor) => {
    dispatch(actions.showContractorDetails(contractor));
  };
  const newContractorClick = (contractor = {}) => {
    dispatch(actions.showContractorDetails({ ...contractor, isNew: true }));
  };
  return (
    <>
      <BackAppBar />
      <ContractorList onItemSelect={contractorDetails} onAddNewContractor={newContractorClick} />
    </>
  );
}
