/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
 * The Roster model module.
 * @module model/Roster
 * @version 1.0
 */
class Roster {
    /**
     * Constructs a new <code>Roster</code>.
     * @alias module:model/Roster
     */
    constructor() {

        Roster.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>Roster</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Roster} obj Optional instance to populate.
     * @return {module:model/Roster} The populated <code>Roster</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Roster();

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }

            if (data.hasOwnProperty('TimeSlot')) {
                obj['TimeSlot'] = ApiClient.convertToType(data['TimeSlot'], 'Object');
            }
            if (data.hasOwnProperty('User')) {
                obj['User'] = ApiClient.convertToType(data['User'], 'Object');
            }
            if (data.hasOwnProperty('Weekday')) {
                obj['Weekday'] = ApiClient.convertToType(data['Weekday'], 'Object');
            }
        }
        return obj;
    }


}


/**
 * @member {String} Id
 */
Roster.prototype['Id'] = undefined;


export default Roster;

