import {get} from "lodash";

export function getErrorResponse(error) {
  return get(error, 'response.body');
}

export function getErrorStatus(error) {
  return get(getErrorResponse(error), 'status');
}

export function isForbiddenErrorStatus(error) {
  return getErrorStatus(error) === 403;
}

export function getErrors(error) {
  return get(getErrorResponse(error), 'error');

}

export function getErrorCode(error) {
  return get(getErrors(error), '[0].errorCode');
}

