import StarIcon from '@mui/icons-material/Star';
import { Box, Button, Card, CardContent, Grid, Rating, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
const labels: { [index: string]: string } = {
    0.5: 'Needs Improvement',
    1: 'Needs Improvement',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};
function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}
const FeedbackForm = ({ feedbackData }) => {
    const [rating, setRating] = useState(feedbackData.Rating);
    const [comment, setComment] = useState(feedbackData.Comment);
    const [hover, setHover] = React.useState(-1);
    const handleRatingChange = (event, newValue) => {
        setRating(newValue);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleSubmit = () => {
        // Handle form submission
        console.log('Submitting feedback:', { rating, comment });
        // You can perform API call here to submit feedback
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={1} mb={1}>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            Project Name: {feedbackData?.CreedRequest?.Project?.ProjectName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Job Name: {feedbackData?.CreedRequest?.Creed?.CreedName}
                        </Typography> </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Start Date: {new Date(feedbackData?.CreedRequest?.Schedule?.StartDate).toLocaleString()}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            End Date: {new Date(feedbackData?.CreedRequest?.Schedule?.EndDate).toLocaleString()}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Employee Name: {feedbackData?.CreedRequest?.Schedule?.AssignedTo?.FirstName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Work Scope: {feedbackData?.CreedRequest?.WorkScope}
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Feedback
                        </Typography>
                    </Grid> */}
                    <Grid item xs={6}>
                        <TextField
                            label="Comment"
                            multiline
                            rows={4}
                            value={comment}
                            onChange={handleCommentChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* <Rating
                            name="rating"
                            value={rating}
                            onChange={handleRatingChange}
                            precision={1}
                        /> */}
                        <Box
                            sx={{
                                width: 200,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >Rating:
                            <Rating
                                name="hover-feedback"
                                value={rating}
                                precision={0.5}
                                getLabelText={getLabelText}
                                onChange={handleRatingChange}
                                onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                            {rating !== null && (
                                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
                            )}
                        </Box>
                    </Grid>

                </Grid>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit Feedback
                </Button>
            </CardContent>
        </Card>
    );
};

export default FeedbackForm;
