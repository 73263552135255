import { Autocomplete, Box, Container, TextField, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserListItem from "./UserListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
}));

export default function UserList({ height, onItemSelect, onAddNewUser }) {
  const classes = useStyles();
  const handleClick = (user) => () => !!onItemSelect && onItemSelect(user);
  const handelNewUserClick = () => !!onAddNewUser && onAddNewUser();
  const { users, LoginUserRole, seletedRole } = useSelector(prop("user"));


  const [filterUserbasedOnRole, setfilterUserbasedOnRole] = useState();
  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getUsers());
  };

  useEffect(updateAll, []);


  useEffect(() => {
    updateField(seletedRole);
  }, [users,seletedRole]);

  const updateField = (value) => {
    dispatch(
      actions.setUserProps({ seletedRole: value })
    );
    value.Id === "all"
      ? setfilterUserbasedOnRole(users)
      : setfilterUserbasedOnRole(users.filter((ele) => ele.Role === value.Id || value.Id.includes(ele.StaffCategory)));
  };
  const categorys = dropDownOption.StaffCategorys;

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
          Vacation/DayOff administration
          </strong>
        </Typography>
        {/* <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many Vacation/DayOff as you want and assign to a user.
        </Typography> */}
      </Box>
      {LoginUserRole === "admin" ? (
        <>
          <Box mt={1}>
            <Autocomplete
              id="role-type-select"
              options={[
                {
                  Id: "all",
                  Name: "All",
                },
                ...categorys
              ]}
              defaultValue={seletedRole}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.Name}
              value={seletedRole}
              onChange={(_event, newValue) => {
                updateField( newValue);
              }}
            />
          </Box>
        </>
      ) : (
        <></>
      )}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of users
          </strong>
        </Typography>
      </Box>
      <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
          {filterUserbasedOnRole?.map((user) => (
            <UserListItem
              key={user.Id}
              user={user}
              onClick={handleClick(user)}
              itemClasses={classes.item}
              
            />
          ))}

          {/* <ListItem
            dense
            disableGutters
            onClick={handelNewUserClick}
            className={classes.item}
            data-testid={'li-add-user'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a user'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem> */}
        </ul>
      </List>
    </Container>
  );
}
