/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


 import { AlertResponse } from "..";
import ApiClient from "../ApiClient";
import Alert from '../model/Alert';
import APIEndPoints from "./ApiEndpoints";
 
 /**
 * CardsController service.
 * @module api/AlertControllerApi
 * @version 1.0
 */
 export default class AlertControllerApi {
 
   /**
   * Constructs a new AlertControllerApi. 
   * @alias module:api/AlertControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
   constructor(apiClient) {
     this.apiClient = apiClient || ApiClient.instance;
     this.endPoints = new APIEndPoints();
   }
 
 
 
 
   /**
    * getAlerts
    * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Alert>} and HTTP response
    */
   getAlertsUsingGETWithHttpInfo() {
     let postBody = null;
 
     let pathParams = {
     };
     let queryParams = {
     };
     let headerParams = {
     };
     let formParams = {
     };
 
     let authNames = ["password_grant"];
     let contentTypes = [];
     let accepts = ['*/*'];
     let returnType = [Alert];
     return this.apiClient.callApi(
       this.endPoints.Alert.GetAll.URI,
       this.endPoints.Alert.GetAll.Type,
       pathParams, queryParams, headerParams, formParams, postBody,
       authNames, contentTypes, accepts, returnType, null
     );
   }
 
   /**
    * getAlerts
    * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Alert>}
    */
   getAlertsUsingGET() {
     return this.getAlertsUsingGETWithHttpInfo()
       .then(function (response_and_data) {
         return response_and_data.data;
       });
   }

   GetNewCountGETWithHttpInfo() {
     let postBody = null;

     let pathParams = {
     };
     let queryParams = {
     };
     let headerParams = {
     };
     let formParams = {
     };

     let authNames = ["password_grant"];
     let contentTypes = [];
     let accepts = ['*/*'];
     let returnType = "Number";
     return this.apiClient.callApi(
       this.endPoints.Alert.GetNewCount.URI,
       this.endPoints.Alert.GetNewCount.Type,
       pathParams, queryParams, headerParams, formParams, postBody,
       authNames, contentTypes, accepts, returnType, null
     );
   }

   /**
    * getAlerts
    * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Alert>}
    */
   GetNewCount() {
     return this.GetNewCountGETWithHttpInfo()
       .then(function (response_and_data) {
         return response_and_data.data;
       });
   }


   /**
       * tartAlert
       * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AlertResponse>} and HTTP response
       */
   startAlertWithHttpInfo(body) {
     let postBody = body;
 
     let pathParams = {
     };
     let queryParams = {
     };
     let headerParams = {
     };
     let formParams = {
     };
 
     let authNames = ["password_grant"];
     let contentTypes = [];
     let accepts = ['*/*'];
     let returnType = AlertResponse;
     return this.apiClient.callApi(
       this.endPoints.Alert.NewAlert.URI,
       this.endPoints.Alert.NewAlert.Type,
       pathParams, queryParams, headerParams, formParams, postBody,
       authNames, contentTypes, accepts, returnType, null
     );
   }
 
   /**
    * startAlert
    * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AlertResponse>}
    */
   startAlert(body) {
     return this.startAlertWithHttpInfo(body)
       .then(function (response_and_data) {
         return response_and_data.data;
       });
   }
 
 
 }
 