import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Autocomplete, Container, Stack, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import defaultValue from "assets/data/default-value";


import PrimaryButton from "components/common/PrimaryButton";
import { chain, isEmpty, trim } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  ".MuiDialog-paper": {
    // backgroundColor:'red'
    margin: "5px",
    width: "100%",
    borderRadius: "10px",
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: "100vh",

    backgroundColor: theme.palette.background.paper,
  },
  title: {
    display: "flex",
    justifyItems: "center",
    justifyContent: "center",
    fontSize: "24px",
  },
  top: {

    backgroundColor: theme.palette.background.paper,
  },
  container: {
    // position: "relative",

    padding: theme.spacing(2),
  },
  bottomBox: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px" }} {...other}>
      <strong> {children}</strong>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{

            position: "absolute!important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddMTAAppDialogue({ handleClose, open, data = {}, onUpdate, Project }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { creeds } = useSelector(prop('creed'));
  const {
    Creed = null,
    Date = null,
    isNew = false,
    Id = null
  } = data;
  const [form, setForm] = useState({});
  useEffect(() => {
    setForm({
      Creed: Creed || null,
      Date: Date
        ? new Date(moment(Date).format(defaultValue.dateTimeFormat))
        : null,
      Id: Id,
    });
  }, [data])

  const hasEmptyFields = chain({
    ...form,
  })
    .omit(["Id", "Date"])
    .mapValues(trim)
    .some(isEmpty)
    .value();

  const onAdd = () => {
    let body = { ...form };
    onUpdate(body);
  };

  const disabled = false;
  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    updateField(prop, e && e.target ? e.target.value : e);
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());
  const submitDisabled = hasEmptyFields;


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.title}
        >
          Credentials
        </BootstrapDialogTitle>
        <DialogContent>
          <Container className={classes.container}>
            <ValidatorForm instantValidate={true} autoComplete="off">
              <Autocomplete
                id="creed"
                name="Credentials"
                options={creeds.filter(k => k.Department !== 'dob' && k.InternalUse)}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Credentials"
                    variant="standard"
                    margin="normal"
                    fullWidth
                  />
                )}
                getOptionLabel={(option) => option.CreedName}
                value={form?.Creed || null}
                onChange={(_event, newValue) => {
                  updateField("Creed", newValue);
                }}
              />
              <LocalizationProvider dateAdapter={
                AdapterDateFns}>
                <Stack spacing={4}>
                  <MobileDatePicker
                    clearable
                    name="Date"
                    label="Date"
                    value={form?.Date || null}
                    onChange={handleChange("Date")}

                    renderInput={(params) => (

                      <TextField
                        {...params}
                        variant="standard"
                        margin="normal"
                        fullWidth

                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </ValidatorForm>
          </Container>
        </DialogContent>
        <DialogActions>
          <PrimaryButton autoFocus onClick={onAdd} disabled={submitDisabled}>
            Save
          </PrimaryButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
