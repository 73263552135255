/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import LoginResponse from "../model/LoginResponse";
import APIEndPoints from "./ApiEndpoints";
/**
 * AuthController service.
 * @module api/LoginControllerApi
 * @version 1.0
 */
export default class LoginControllerApi {
  /**
   * Constructs a new LoginControllerApi.
   * @alias module:api/LoginControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
    this.endPoints = new APIEndPoints();
  }

  loginWithEmailPass(body) {
    return this.loginWithEmailPassWithHttpInfo(body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  loginWithEmailPassWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["*/*"];
    let returnType = LoginResponse;
    return this.apiClient.callApi(
      this.endPoints.auth.Login.URI,
      this.endPoints.auth.Login.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }


}
