import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ListItemAvatar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";


export default function ProjectListItem({ project, onClick, itemClasses }) {

  const { ProjectName, ProjectNo, Location = "", Department = "" } = project;
  const handleClick = (e) => onClick && onClick(e);

  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      className={itemClasses}
      data-testid={'li-item-' + project.id}
    >
      <ListItemAvatar>
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
        secondary={`${ProjectName} - (${Location})`}
        primary={`${ProjectNo} - (${Department?.toUpperCase()})`}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
