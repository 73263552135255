/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
 * The Creed model module.
 * @module model/Creed
 * @version 1.0
 */
class Creed {
    /**
     * Constructs a new <code>Creed</code>.
     * @alias module:model/Creed
     */
    constructor() {

        Creed.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>Creed</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Creed} obj Optional instance to populate.
     * @return {module:model/Creed} The populated <code>Creed</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Creed();

            if (data.hasOwnProperty('CreedName')) {
                obj['CreedName'] = ApiClient.convertToType(data['CreedName'], 'String');
                obj['Name'] = ApiClient.convertToType(data['CreedName'], 'String');
            }
            if (data.hasOwnProperty('Description')) {

                obj['Description'] = ApiClient.convertToType(data['Description'], 'String');
            } if (data.hasOwnProperty('CreedFieldType')) {

                obj['CreedFieldType'] = ApiClient.convertToType(data['CreedFieldType'], 'String');
            }
            if (data.hasOwnProperty('Value')) {

                obj['Value'] = ApiClient.convertToType(data['Value'], 'String');
            }
            if (data.hasOwnProperty('TextColor')) {

                obj['TextColor'] = ApiClient.convertToType(data['TextColor'], 'String');
            }
            if (data.hasOwnProperty('BackgroundColor')) {

                obj['BackgroundColor'] = ApiClient.convertToType(data['BackgroundColor'], 'String');
            }
            if (data.hasOwnProperty('InternalUse')) {

                obj['InternalUse'] = ApiClient.convertToType(data['InternalUse'], 'Boolean');
            }
            if (data.hasOwnProperty('ShowToClients')) {

                obj['ShowToClients'] = ApiClient.convertToType(data['ShowToClients'], 'Boolean');
            }
            if (data.hasOwnProperty('ShowInRoaster')) {

                obj['ShowInRoaster'] = ApiClient.convertToType(data['ShowInRoaster'], 'Boolean');
            }
            if (data.hasOwnProperty('MTASpecification')) {

                obj['MTASpecification'] = ApiClient.convertToType(data['MTASpecification'], 'Boolean');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'Date');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'Date');
            }
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('Department')) {
                obj['Department'] = ApiClient.convertToType(data['Department'], 'String');
            }
            if (data.hasOwnProperty("Order")) {
                obj["Order"] = ApiClient.convertToType(data["Order"], "String");
            }

        }
        return obj;
    }


}

/**
 * project first name
 * @member {String} CreedName
 */
Creed.prototype['CreedName'] = undefined;

Creed.prototype['Id'] = undefined;
/**
 * @member {Object} CreedClients
 */



export default Creed;

