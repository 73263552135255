import { USER_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
  email: '',
  username: '',
  password: '',
  initialized: false,

  auth: {},
  authPhone: '',
  hasAuth: false,

  users: [],
  userDetails: null,
  seletedRole: { Id: "all", Name: "All" },
  LoginUserId: null,
  onboardingStep: 1,
  selectedTab: 'Home'
};

export default propsReducer(USER_SET_PROPS, DEFAULT_STATE);
