import { Autocomplete, Box, Checkbox, Container, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import TextValidator from "components/common/input/TextValidator";
import { chain, isEmpty, trim } from "lodash";
import React, { useState } from "react";
import { SwatchesPicker } from "react-color";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import RemoveCreedDialog from "./RemoveCreedDialog";


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  dateComp: {
    marginTop: 20,
    paddingBottom: 30,
  },
  fullWidth: {
    width: "100%",
  },
}));
export default function CreedDetails({ Creed = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const creed = Creed || {};
  const departments = dropDownOption.Departments;

  const {
    CreedName = "",
    Value = "",
    Id = null,
    Description = null,
    InternalUse = false,
    ShowToClients = false,
    ShowInRoaster = false,
    MTASpecification = false,
    isNew = false,
    BackgroundColor = "#ffffff",
    TextColor = "#000000",
    Department = null,
    CreedFieldType = null

  } = creed;
  const [form, setForm] = useState({
    CreedName: CreedName,
    Value: Value,
    Description: Description,
    InternalUse: InternalUse,
    ShowToClients: ShowToClients,
    ShowInRoaster: ShowInRoaster,
    MTASpecification: MTASpecification,
    Id: Id,
    BackgroundColor: BackgroundColor,
    TextColor: TextColor,
    Department: Department ? departments.find(k => k.Id === Department) : null,
    CreedFieldType: CreedFieldType

  });
  const hasEmptyFields = chain({
    ...form,
  })
    .omit([
      "Id",
      "Department"
    ])
    .mapValues(trim)
    .some(isEmpty)
    .value();
  const [openRemoveCreedDialog, setopenRemoveCreedDialog] = React.useState(false);
  const onAdd = () => {
    let body = { ...form };
    body.Department = body?.Department?.Id ? body.Department.Id : "";

    dispatch(actions.addCreed(body));
  };
  const onRemoveConfirm = () => {
    dispatch(actions.removeCreed(form));
  };
  const closeDialog = () => {
    setopenRemoveCreedDialog(false);
  };

  const disabled = false;

  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "PaymentReceived" || prop === 'InternalUse' || prop === 'ShowToClients' || prop === 'ShowInRoaster' || prop === 'MTASpecification') {
      updateField(prop, e.target.checked);
    } else if (prop === 'BackgroundColor' || prop === 'TextColor') {
      updateField(prop, e.hex);

    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = hasEmptyFields;

  return <>
    <Container className={classes.root}>

      <FlexBox className={classes.top}>
        <Box flexGrow={1} pl={2}>
          <Typography variant="h5">
            <strong data-testid="label-user-administration">
              {!isNew ? <>Edit Credential</> : <>Add a Credential</>}
            </strong>
          </Typography>
        </Box>
      </FlexBox>

      <Container className={classes.container}>
        <ValidatorForm instantValidate={true} autoComplete="off">
          <TextValidator
            autoComplete='new-password'
            name="CreedName"
            label="Credential Name"
            value={form?.CreedName}
            margin="normal"
            fullWidth
            disabled={disabled}
            data-testid="input-creed-name"
            onChange={handleChange("CreedName")}
            onBlur={handleBlur("CreedName")}
          />
          <TextValidator
            autoComplete='new-password'
            name="Value"
            label="Short Name"
            value={form?.Value}
            margin="normal"
            fullWidth
            disabled={disabled}
            data-testid="input-creed-name"
            onChange={handleChange("Value")}
            onBlur={handleBlur("Value")}
          />
          <TextValidator
            autoComplete='new-password'
            name="Description"
            label="Description"
            value={form?.Description}
            margin="normal"
            fullWidth
            disabled={disabled}
            data-testid="input-user-name"
            onChange={handleChange("Description")}
            onBlur={handleBlur("Description")}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />
          <Grid item xs={12} sm={6} className={classes.column}>
            <Autocomplete
              id="department"
              options={departments}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.Name}
              value={form?.Department}
              onChange={(_event, newValue) => {
                updateField("Department", newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.column}>
            <Autocomplete
              id="CreedFieldType"
              options={['Date', 'YesNo']}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="FieldType"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              // getOptionLabel={(option) => option.Name}
              value={form?.CreedFieldType}
              onChange={(_event, newValue) => {
                updateField("CreedFieldType", newValue);
              }}
            />
          </Grid>
          {
            form?.Department?.Id === 'mta' &&
          <FormGroup sx={{ m: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultValue='true'
                  checked={form?.InternalUse}
                  onChange={handleChange("InternalUse")}
                />
              }
                label="MTA Internal Use"
              />
            </FormGroup>
          }
          <FormGroup sx={{ m: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultValue='true'
                  checked={form?.ShowInRoaster}
                  onChange={handleChange("ShowInRoaster")}
                />
              }
              label="Show In Roster"
            />
          </FormGroup>
          <FormGroup sx={{ m: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultValue='true'
                  checked={form?.ShowToClients}
                  onChange={handleChange("ShowToClients")}
                />
              }
              label="Show To Clients"
            />
          </FormGroup>
          <FormGroup sx={{ m: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultValue='true'
                  checked={form?.MTASpecification}
                  onChange={handleChange("MTASpecification")}
                />
              }
              label="Show in MTA Specification"
            />
          </FormGroup>

          <FormGroup sx={{ mt: '15px', alignItems: 'start' }} >
            <FormControlLabel
              labelPlacement='top'
              sx={{ alignItems: 'start' }}
              control={
                <SwatchesPicker
                  defaultValue='true'
                  checked={form?.TextColor}
                  color={form.TextColor}
                  onChangeComplete={handleChange("TextColor")}
                />
              }
              label="Text Color"
            />
          </FormGroup>
          <FormGroup sx={{ mt: '15px', alignItems: 'start' }} >
            <FormControlLabel
              labelPlacement='top'
              sx={{ alignItems: 'start' }}
              control={
                <SwatchesPicker
                  defaultValue='true'
                  checked={form?.BackgroundColor}
                  color={form.BackgroundColor}
                  onChangeComplete={handleChange("BackgroundColor")}
                // colors={}
                />
              }
              label="Background Color"
            />
          </FormGroup>
          {/* <CirclePicker onChange={handleChange("Description")}></CirclePicker> */}
        </ValidatorForm>
      </Container>
      <FlexBox justifyContent="center">
        {!isNew ? (
          <>
            <PrimaryButton
              onClick={onAdd}
              data-testid="btn-remove-user"
              disabled={submitDisabled}
            >
              Save
            </PrimaryButton>
          </>
        ) : (
          <PrimaryButton

            onClick={onAdd}
            data-testid="btn-add-user"
            disabled={submitDisabled}
          >
            Add Creed
          </PrimaryButton>
        )}
      </FlexBox>
      <RemoveCreedDialog
        open={openRemoveCreedDialog}
        onClose={closeDialog}
        onRemoveConfirm={onRemoveConfirm}
      ></RemoveCreedDialog>
    </Container>
  </>;
}
