/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


 import ApiClient from "../ApiClient";
 import Leave from '../model/Leave';
 import APIEndPoints from "./ApiEndpoints";
 
 /**
 * CardsController service.
 * @module api/LeaveControllerApi
 * @version 1.0
 */
 export default class LeaveControllerApi {
 
     /**
     * Constructs a new LeaveControllerApi. 
     * @alias module:api/LeaveControllerApi
     * @class
     * @param {module:ApiClient} [apiLeave] Optional API leave implementation to use,
     * default to {@link module:ApiClient#instance} if unspecified.
     */
     constructor(apiLeave) {
         this.apiLeave = apiLeave || ApiClient.instance;
         this.endPoints = new APIEndPoints();
     }
 
 
 
 
     /**
      * getLeave
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Leave>} and HTTP response
      */
      getLeavesUsingPOSTWithHttpInfo(body) {
         let postBody = body;
 
         let pathParams = {
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = [Leave];
         return this.apiLeave.callApi(
             this.endPoints.leave.GetAll.URI,
             this.endPoints.leave.GetAll.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getLeave
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Leave>}
      */
      getLeavesUsingPOST(body) {
         return this.getLeavesUsingPOSTWithHttpInfo(body)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     /**
      * getAddLeave
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Leave>} and HTTP response
      */
     addLeaveWithHttpInfo(body) {
         let postBody = body;
 
         let pathParams = {
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = Leave;
         return this.apiLeave.callApi(
             this.endPoints.leave.Post.URI,
             this.endPoints.leave.Post.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getAddLeave
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Leave>}
      */
     addLeave(body) {
         return this.addLeaveWithHttpInfo(body)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     /**
  * getLeave
  * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Leave>} and HTTP response
  */
     removeLeaveWithHttpInfo(body) {
         let postBody = body;
 
         let pathParams = {};
         
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = Leave;
         return this.apiLeave.callApi(
             this.endPoints.leave.Delete.URI,
             this.endPoints.leave.Delete.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getLeave
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Leave>}
      */
     removeLeave(body) {
         return this.removeLeaveWithHttpInfo(body)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
 
 }
 