import BackAppBar from "components/app/BackAppBar";
import SubContractorList from "components/subContractor/SubContractorList";
import React from "react";
import { useDispatch } from "react-redux";
import * as actions from "actions";
export default function SubContractorAdministartionView() {
 const dispatch = useDispatch();
  const subContractorDetails = (subContractor) => {
    dispatch(actions.showSubContractorDetails(subContractor));
  };
  const newSubContractorClick = (subContractor = {}) => {
    dispatch(actions.showSubContractorDetails({ ...subContractor, isNew: true }));
  };
  return (
    <>
      <BackAppBar />
      <SubContractorList onItemSelect={subContractorDetails} onAddNewSubContractor={newSubContractorClick} />
    </>
  );
}
