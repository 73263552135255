/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Client from '../model/Client';
import ClientUser from "../model/ClientUsers";
import APIEndPoints from "./ApiEndpoints";

/**
* CardsController service.
* @module api/ClientControllerApi
* @version 1.0
*/
export default class ClientControllerApi {

    /**
    * Constructs a new ClientControllerApi. 
    * @alias module:api/ClientControllerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
        this.endPoints = new APIEndPoints();
    }




    /**
     * getClient
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Client>} and HTTP response
     */
    getClientsUsingGETWithHttpInfo() {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [Client];
        return this.apiClient.callApi(
            this.endPoints.client.GetAll.URI,
            this.endPoints.client.GetAll.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getClient
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
     */
    getClientsUsingGET() {
        return this.getClientsUsingGETWithHttpInfo()
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
     * getAddClient
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Client>} and HTTP response
     */
    addClientWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = Client;
        return this.apiClient.callApi(
            this.endPoints.client.Post.URI,
            this.endPoints.client.Post.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddClient
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
     */
    addClient(body) {
        return this.addClientWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
 * getClient
 * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Client>} and HTTP response
 */
    removeClientWithHttpInfo(id) {
        let postBody = null;

        let pathParams = {
            id: id
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = Client;
        return this.apiClient.callApi(
            this.endPoints.client.Delete.URI + '/' + id,
            this.endPoints.client.Delete.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getClient
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
     */
    removeClient(id) {
        return this.removeClientWithHttpInfo(id)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }




    /**
  * getUser
  * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
  */
    getClientUsersUsingGETWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = [ClientUser];
        return this.apiClient.callApi(
            this.endPoints.client.GetClientUsers.URI,
            this.endPoints.client.GetClientUsers.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }

    /**
     * getUser
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
     */
    getClientUsersUsingPOST(body) {
        return this.getClientUsersUsingGETWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }



    getClientByIdUsingPOST(body) {
        return this.getClientByIdUsingPOSTWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }

    getClientByIdUsingPOSTWithHttpInfo(body) {

        let postBody = body;

        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = Client;
        return this.apiClient.callApi(
            this.endPoints.client.GetById.URI,
            this.endPoints.client.GetById.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }
}
