import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const DJR_SET_PROPS = 'DJR_SET_PROPS';

export const GET_DJRS = 'GET_DJRS';
export const GET_DJRS_BY_TYPE = 'GET_DJRS_BY_TYPE';
export const REFRESH_DJRS = 'REFRESH_DJRS';
export const DJR_DETAILS = 'DJR_DETAILS';
export const SHOW_ALL_DJR = 'SHOW_ALL_DJR';
export const SAVE_DJR = 'START_DJR';
export const UPLOAD_DJR = 'UPLOAD_DJR';
export const UPDATE_JOB_STATUS = 'UPDATE_JOB_STATUS';

export const UPLOAD_INSPECTION ='UPLOAD_INSPECTION';
export const DOWNLOAD_INSPECTION ='DOWNLOAD_INSPECTION';


export const DOWNLOAD_DJR = 'DOWNLOAD_DJR';
export const GET_DJR_SUMMARY = 'GET_DJR_SUMMARY';
export const DJR_TYPE_SELECT = 'DJR_TYPE_SELECT';
export const setDJRProps = payloadAction(DJR_SET_PROPS);

export const getDJRs = simpleAction(GET_DJRS);
export const getDJRsByType = payloadAction(GET_DJRS_BY_TYPE);
export const downloadDJR = payloadAction(DOWNLOAD_DJR);

export const refreshVirtualCard = simpleAction(REFRESH_DJRS);
export const showDJRDetails = (djr) => createAction(DJR_DETAILS, { djr });
export const showAllDJR = simpleAction(SHOW_ALL_DJR);
export const getDJRSummary = simpleAction(GET_DJR_SUMMARY);

export const djrTypeSelect = payloadAction(DJR_TYPE_SELECT);


export const saveDJR = payloadAction(SAVE_DJR);
export const updateJobStatus = payloadAction(UPDATE_JOB_STATUS);

export const uplaodDJR = payloadAction(UPLOAD_DJR);

export const uploadInspection = payloadAction(UPLOAD_INSPECTION);
export const downloadInspectionReport = payloadAction(DOWNLOAD_INSPECTION);