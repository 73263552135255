import Paper from '@mui/material/Paper';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import React, { useEffect, useState } from 'react';

import { Box, Checkbox, MenuItem, Select } from '@mui/material';
import dropDownOption from "assets/data/dropdown-options";
import SearchBar from 'components/common/SearchBar';
import { getStatusBackgroundColor, getStatusTextColor } from 'components/mat-approvals/dynamic-mta-table';
import { prop } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    card: {
        marginBottom: 5,
        border: 'none',
        boxShadow: 'none'
    },
    cardContent: {
        padding: "10px 5px!important",
    },
    tableHeight: {
        // maxHeight: 'calc(100vh - 233px)'
        maxHeight: "calc(100vh - 266px)",
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
        // maxWidth:'130px'
        // boxShadow: "5px 2px 5px grey",
        // borderRight: "2px solid black"
    },
    disable: {
        opacity: 0.5,
        pointerEvents: 'none'
    },
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
        }
    }
}));
function DynamicMTASpecificationTable({ data,
    columns,
    tableHeight = 'calc(100vh - 150px)'
}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const { LoginUserRole } = useSelector(prop("user"));
    const [searchText, setSearchText] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const { selectedDepartmentValue } = useSelector(prop('roster'));

    const [selectedItem, setSelectedItem] = React.useState({});

    const [category, setCategory] = useState();
    const departments = dropDownOption.Departments;
    const handleSort = (columnId) => {
        if (orderBy === columnId && order === 'asc') {
            setOrder('desc');
        } else {
            setOrderBy(columnId);
            setOrder('asc');
        }
    };

    const dispatch = useDispatch();



    const onFieldUpdate = (row, key, value) => {
        let tempData = {
            Project: {
                Id: row.Id,
            },
            Creed: {
                Id: key
            },
            Value: value
        }
        dispatch(actions.updateSpecification(tempData));

    };
    const updateTask = () => {
        let filteredList = data ? [...data] : [];
        if (searchText.length > 0) {
            filteredList = filteredList.filter(k => k.Name?.toLowerCase().includes(searchText)
                || k.User?.Email?.toLowerCase().includes(searchText)
                || k.User?.PhoneNo?.toLowerCase().includes(searchText)
            );
        }
        filteredList.sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] < b[orderBy] ? -1 : 1;
            } else {
                return a[orderBy] > b[orderBy] ? -1 : 1;
            }
        });
        setSortedData(filteredList);
    };
    useEffect(() => {
        const selectedCategory = selectedDepartmentValue ? selectedDepartmentValue : departments[0].Id;
        setCategory(selectedCategory)
    }, [])
    useEffect(updateTask, [orderBy, order, searchText, data]);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const classes = useStyles();
    const onSearch = (e) => {
        setSearchText(e.target.value.trim().toLowerCase());
    }



    return (
        <Paper>
            <Box sx={{ p: 1, maxWidth: '580px' }}>
                <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
            </Box>
            <TableContainer sx={{ maxHeight: tableHeight }}>
                <Table stickyHeader aria-label="sticky table" className={classes.table} size='small' padding='none'>
                    <TableHead >
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align="center" style={{ fontWeight: '600', maxWidth: '250px', justifyContent: 'center', alignItems: 'center', zIndex: column.id == 'Name' ? 2 : 1 }}
                                    className={column.id == 'Name' ? classes.sticky : ""}>
                                    {/* <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleSort(column.id)}
                                        style={{ cursor: 'pointer', whiteSpace: 'pre' }}
                                    >
                                        {column.label}
                                    </TableSortLabel> */}
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => ( */}
                        {sortedData.map((row, index) => (
                            <TableRow key={index} >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align="center" className={column.id == 'Name' ? classes.sticky : ""}
                                        style={{
                                            maxWidth: '250px',
                                            backgroundColor: column.id == 'ProjectStatus' ? getStatusBackgroundColor(row.ProjectStatus) : 'white',
                                            color: column.id == 'ProjectStatus' ? getStatusTextColor(row.ProjectStatus) : '#000000',
                                        }} >

                                        <Typography
                                            className={classes.separator}
                                            // onClick={() => onViewTask(cellData, "Monday", row.User)}
                                            variant="caption"
                                            component="div"
                                        >
                                            {(() => {
                                                switch (column.id) {
                                                    case 'ProjectStatus':
                                                        return <>
                                                            <Typography sx={{ display: 'inline', backgroundColor: getStatusBackgroundColor(row.ProjectStatus) }}>{row[column.id]}</Typography></>;
                                                    case 'NINTYDROWNYCT':
                                                    case 'NINTYDROWMNR':
                                                    case 'NINTYDROWLIRR':
                                                    case 'NINTYDROWAMTRACK':
                                                    case 'NINTYDROWCSX':
                                                    case 'TWOYSAFETYCOORDINATOR':
                                                    case 'FIVEYSAFETYCOORDINATOR':
                                                    case 'DOB40HSSM':
                                                    case 'OSHA30HR':
                                                    case 'ASBESTOSINSPECTOR':
                                                    case 'AMPPC3C5':
                                                    case 'ASSPMEMBERSHIP':
                                                    case 'NYCTTRACKSAFETYTRAINED':
                                                    case 'LIRRTRACKCARD':
                                                    case 'MNRCARD':
                                                    case 'AMTRACKCARD':
                                                    case 'CSXTRAINING':
                                                    case 'SIRCARD':
                                                    case 'CHST':
                                                        return <>
                                                            <Checkbox
                                                                checked={row[column.id]}
                                                                onChange={(event) => onFieldUpdate(row, column.id, event.target.checked)}
                                                            /></>;
                                                    default:
                                                        return <>{column.type == 'creed' ?
                                                            <>
                                                                <Select
                                                                    value={String(row[column.id])} // Convert boolean to string
                                                                    onChange={(event) => {
                                                                        const value = event.target.value === 'true'; // Convert string to boolean
                                                                        onFieldUpdate(row, column.id, value);
                                                                    }}
                                                                    renderValue={(selected) => (
                                                                        <span
                                                                            style={{
                                                                                backgroundColor: selected === 'true' ? 'green' : 'transparent',
                                                                                color: selected === 'true' ? 'white' : 'black',
                                                                                padding: '5px',
                                                                                borderRadius: '4px',
                                                                            }}
        >
                                                                            {selected === 'true' ? 'Y' : 'N'}
                                                                        </span>
                                                                    )}
                                                                >
                                                                    <MenuItem
                                                                        value="true"
                                                                        style={{ backgroundColor: 'green', color: 'white' }}
                                                                    >
                                                                        Y
                                                                    </MenuItem>
                                                                    <MenuItem value="false">N</MenuItem>
                                                                </Select>



                                                            </> : row[column.id]}</>;
                                                }
                                            })()}
                                        </Typography>


                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={columns.length} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default DynamicMTASpecificationTable;
