/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
 * The MTA model module.
 * @module model/MTA
 * @version 1.0
 */
class MTA {
    /**
     * Constructs a new <code>MTA</code>.
     * @alias module:model/MTA
     */
    constructor() {

        MTA.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>MTA</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MTA} obj Optional instance to populate.
     * @return {module:model/MTA} The populated <code>MTA</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MTA();

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }

            if (data.hasOwnProperty('Date')) {
                obj['Date'] = ApiClient.convertToType(data['Date'], 'Date');
            }

            if (data.hasOwnProperty('Project')) {
                obj['Project'] = ApiClient.convertToType(data['Project'], 'Object');
            } if (data.hasOwnProperty('User')) {
                obj['User'] = ApiClient.convertToType(data['User'], 'Object');
            } if (data.hasOwnProperty('Creed')) {
                obj['Creed'] = ApiClient.convertToType(data['Creed'], 'Object');
            }



            if (data.hasOwnProperty('__Project__')) {
                obj['__Project__'] = ApiClient.convertToType(data['__Project__'], 'Object');
            } if (data.hasOwnProperty('__User__')) {
                obj['__User__'] = ApiClient.convertToType(data['__User__'], 'Object');
            } if (data.hasOwnProperty('__Creed__')) {
                obj['__Creed__'] = ApiClient.convertToType(data['__Creed__'], 'Object');
            }
        }
        return obj;
    }


}


/**
 * @member {String} Id
 */
MTA.prototype['Id'] = undefined;


export default MTA;

