/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
 * The RecommendationList model module.
 * @module model/RecommendationList
 * @version 1.0
 */
class RecommendationList {
    /**
     * Constructs a new <code>RecommendationList</code>.
     * @alias module:model/RecommendationList
     */
    constructor() {

        RecommendationList.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>RecommendationList</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RecommendationList} obj Optional instance to populate.
     * @return {module:model/RecommendationList} The populated <code>RecommendationList</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RecommendationList();

            if (data.hasOwnProperty('Weekday') && !!data.Weekday) {
                obj['Weekday'] = ApiClient.convertToType(data['Weekday'], 'Object');
            }
            if (data.hasOwnProperty('User') && !!data.User) {
                obj['User'] = ApiClient.convertToType(data['User'], 'Object');
            }
            if (data.hasOwnProperty('Weekday') && !!data.Weekday) {
                obj['Weekday'] = ApiClient.convertToType(data['Weekday'], 'Object');
            }

        }
        return obj;
    }


}

/**
 * project first name
 * @member {String} RecommendationListName
 */
RecommendationList.prototype['RecommendationListName'] = undefined;

RecommendationList.prototype['Id'] = undefined;
/**
 * @member {Object} RecommendationListClients
 */



export default RecommendationList;

