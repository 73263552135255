
export function propsReducer(actionType, defaultState = {}) {
  if (!actionType) throw new Error('actionType is not defined for props reducer');
  return (state = defaultState, {type, payload}) =>
      type === actionType ? {...state, ...payload} : state;
}

export function resetReducer(actionType, defaultState = {}) {
  if (!actionType) throw new Error('actionType is not defined for reset reducer');
  return (state = defaultState, {type}) =>
      type === actionType ? defaultState : state;
}

export function combineReducers(...reducers) {
  return (state, action) => reducers.reduce((s, reducer) => reducer(s, action), state);
}


export const createAction = (type, payload = {}) => ({type, payload});

export const payloadAction = type => (payload = {}) => ({type, payload});

export const simpleAction = type => () => ({type});