import React, { useEffect, useState } from 'react';
import HomeAppBar from "components/home/HomeAppBar";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "react-redux";
import { prop } from "ramda";
import { isEmpty } from 'lodash';
import { Box, Fade, Typography } from "@mui/material";
import BgView from "components/common/BgView";
import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import PrimaryButton from "components/common/PrimaryButton";

import FlexBox from "components/common/FlexBox";
import DJRList from 'components/djr/DJRList';
import PaperTile from 'components/common/PaperTile';
import { formatUSD } from 'utils/strUtils';
import path from 'routesPath';
import history from "../../history";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.common.bgGray
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    total: {
        fontWeight: 'bold'
    },
    total: {
        fontWeight: 'bold'
    },
    showAll: {
        color: theme.palette.primary.main
    },
    paper: {
        padding: theme.spacing(1),
    },
    djrList: {
        marginBottom: theme.spacing(6),
    }
}));

export default function AllDJRView() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { djrs, type='Total', djrSummary } = useSelector(prop('djr'));

    const updateAll = () => {
        dispatch(actions.getDJRsByType({type:type}));
    }

    useEffect(updateAll, []);
    const showDetails = (tx) => dispatch(actions.showDJRDetails(tx));
    const { PendingToday, Total, PendingTillDate } = djrSummary;


    return (
        <>
            <BackAppBar backPath={path.DJRSummary}/>
            <div className={classes.container}>
                {type === "Total" ? (
                    <>
                        <PaperTile className={classes.paper} data-testid='label-total'>
                            <TitleValue primary={'Completed'} secondary={Total} />
                        </PaperTile>
                    </>
                ) : (
                    <></>
                )}

                {type === "PendingTillDate" ? (
                    <>
                        <PaperTile className={classes.paper} data-testid='label-total'>
                            <TitleValue primary={'Pending Till Date'} secondary={PendingTillDate} />
                        </PaperTile>
                    </>
                ) : (
                    <></>
                )}

                {type === "PendingToday" ? (
                    <>
                        <PaperTile className={classes.paper} data-testid='label-total'>
                            <TitleValue primary={'Pending Today'} secondary={PendingToday} />
                        </PaperTile>
                    </>
                ) : (
                    <></>
                )}

                <FlexBox justifyContent="space-between" mt={1}>
                    <Box minWidth={105}>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }} data-testid='label-djr'>
                            DJRs
                        </Typography>
                    </Box>
                </FlexBox>
                <DJRList
                    djrs={djrs} onItemSelect={showDetails} className={classes.djrList} />
            </div>
        </>
    );
}
function TitleValue({ primary, secondary }) {
    const classes = useStyles();
    return (
        <FlexBox alignItems="flex-start" >
            <Box minWidth={105}>
                <Typography variant="h4" className={classes.total}>
                    {primary}-
                </Typography>
            </Box>
            <Typography variant="h4" className={classes.total}>
                {secondary}
            </Typography>
        </FlexBox>
    )
}