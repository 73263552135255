import { TASK_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
  tasks: [],
  selectedDateRange: [],
  taskDetails: null,
  selectedDepartmentValue: 'mta',
  selectedStartOfWeek: null,
  selectedEndOfWeek: null,
};

export default propsReducer(TASK_SET_PROPS, DEFAULT_STATE);
