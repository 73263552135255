import { ROSTER_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
    rosters: [],
    rosterDetails: null,
    timeSlots: [],
    weekDays: [],
    selectedDepartmentValue: 'mta',

};

export default propsReducer(ROSTER_SET_PROPS, DEFAULT_STATE);
