import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Avatar, ListItemAvatar} from "@mui/material";
import {abbr, formatUSD} from "utils/strUtils";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight:'0.5rem'
  },
  amount: {
    flexGrow: 0
  },
  secondartText:{
    maxWidth:'calc(100vw - 160px)'
  }
}));

export default function DjrSummaryListItem({ djr, onClick }) {
  const classes = useStyles();
  const { Name, Count } = djr;
  const displayText = Name + ': ' + Count;
  const handleClick = (e) => onClick && onClick(e);

  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      data-testid={"li-item-" + djr.Name}
    >
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{abbr(Name)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={displayText}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
