import React, { useState, useRef } from "react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Container, Stack, Typography } from "@mui/material";
import PrimaryButton from "./PrimaryButton";
import FlexBox from "components/common/FlexBox";

// import "./App.css";
// import "./sigCanvas.css";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  SignaturePad: {
    //   textAlign: 'left',
    //   lineHeight: 'initial',
    //   fontSize: '0.875rem',
    //   padding: theme.spacing(1),

    border: "1px solid black",
    width: "100%",
    minHeight: "500px",
    background: "white",
  },
  Button: {
    minWidth: "auto",
  },
}));
export default function TakeSignature({
  imageURL,
  setImageURL,
  Label = "Signature",
}) {
  const classes = useStyles();

  const sigCanvas = useRef({});

  /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
  const clear = () => sigCanvas.current.clear();

  /* a function that uses the canvas ref to trim the canvas 
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
  const save = () => {
    console.log(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  return (
    <Container className={classes.root} p={0}>
      {/* <Typography className="bold" data-testid='label-desc'>{Label}</Typography> */}
      {imageURL !== "data:image/png;base64," ? (
        <img
          src={imageURL}
          alt="my signature"
          style={{
            display: "block",
            margin: "0 auto",
            border: "1px solid black",
            width: "150px",
          }}
        />
      ) : null}
      <Popup
        modal
        trigger={<PrimaryButton>Add {Label}</PrimaryButton>}
        closeOnDocumentClick={false}
        style={{ background: "white" }}
      >
        {(close) => (
          <>
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: classes.SignaturePad,
              }}
            />
            {/* Button to trigger save canvas image */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="center"
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.Button}
                onClick={() => {
                  save();
                  close();
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.Button}
                onClick={clear}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.Button}
                onClick={close}
              >
                Close
              </Button>
            </Stack>
          </>
        )}
      </Popup>
    </Container>
  );
}
