import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/fonts.css';
import 'assets/css/main.css';
import App from 'components/app/App';
import * as cordova from './cordova';
import './index.css';

import {Provider} from 'react-redux';
import {HashRouter as Router, Route} from 'react-router-dom';
import configureStore from './store';
import history from './history';
import Theme from "./Theme";
import ErrorBoundary from "./components/error/ErrorBoundary";
import ErrorDrawer from "./components/error/ErrorDrawer";
import {CssBaseline} from "@mui/material";

const store = configureStore();

cordova.initialize(store);

const scrollToTop = () => {
  window.scrollTo(0, 0);
  document.getElementById('root').scrollTop = 0;
};

const ScrollToTop = () => {
  scrollToTop();
  return null;
};

const app = (
    <Provider store={store}>
      <Router history={history} forceRefresh={false} onUpdate={scrollToTop}>
        <Route component={ScrollToTop} />
        <Theme>
          <CssBaseline/>
          <ErrorBoundary>
            <ErrorDrawer />
            <App/>
          </ErrorBoundary>
        </Theme>
      </Router>
    </Provider>
);

const renderReactDom = () => {
  ReactDOM.render(app, document.getElementById('root'));
};

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
    if(window?.screen?.orientation){
      window?.screen?.orientation.unlock()
    }
  
  
  }, false);
} else {
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
