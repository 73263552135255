import {
  Box, Paper, Typography
} from "@mui/material";
import * as actions from "actions";
import Logo from "assets/icons/logo.png";
import TextValidator from "components/common/input/TextValidator";
import PrimaryButton from "components/common/PrimaryButton";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import history from "../../history";
import path from "../../routesPath";

export default function Login() {
  const { username, password } = useSelector(prop('user'));

  const [username1, setUsername] = useState('');
  const [password1, setPassword] = useState('');

  const dispatch = useDispatch();

  const emailHandleChange = (props) => {
    setUsername(props.target.value)
  }

  useEffect(() => {
    if (username) {
      setUsername(username);
    }
  }, [username])
  useEffect(() => {
    if (password) {
      setPassword(password);
    }
  }, [password])
  const passwordHandleChange = (props) => {
    setPassword(props.target.value)
  }

  const goTo = () => history.push(path.profile.userAdministration);

  const handleFormSubmit = event => {
    event.preventDefault();
    // goTo();
    // uncomment when api is done
    dispatch(actions.loginWithEmailPass({ username: username1, password: password1 }));
    return false;
  };
  const handleFormErrors = errors => console.error(errors);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f1f2f7"
    >
      <Paper
        elevation={2}
        sx={{
          width: "100%",
          maxWidth: "400px",
          padding: "50px",
          textAlign: "center",
          margin: "10px"
        }}
      >
        <Box mb={3}>
          <img src={Logo} alt="Logo" style={{ height: "30px" }} />
        </Box>
        <Typography variant="h5" fontWeight="bold" mb={2}>
          Sign in
        </Typography>
        <ValidatorForm
          instantValidate={false}
          onSubmit={handleFormSubmit}
          onError={handleFormErrors} form
        >
          <TextValidator
            autoComplete='new-password'
            required
            name="username"
            label="Email"
            value={username1}
            margin="normal"
            fullWidth
            onChange={emailHandleChange}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
          />
          {<TextValidator
            autoComplete='new-password'
            required
            name="password"
            label="Password"
            value={password1}
            margin="normal"
            fullWidth
            type="password"
            onChange={passwordHandleChange}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />}
          <PrimaryButton type="submit" gutterTop data-testid="btn-send">
            Login
          </PrimaryButton>
          <Box mt={2}>
            <Link to={path.forgotPassword}>Forgot Password?</Link>
            {" | "}
            <a href={`mailto:support@infiniteconsultingcorp.com`}>Contact Support</a>
          </Box>
        </ValidatorForm>

      </Paper>
    </Box>
  );
}
