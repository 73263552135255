import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ListItemAvatar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";


export default function CreedListItem({ creed, onClick, itemClasses }) {

  const { CreedName, CreedNo, Department, InternalUse } = creed;
  const handleClick = (e) => onClick && onClick(e);

  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      className={itemClasses}
      data-testid={'li-item-' + creed.id}
    >
      <ListItemAvatar>
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
        secondary={InternalUse ? "INTERNAL" : Department?.toUpperCase()}
        primary={CreedName}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
