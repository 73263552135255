import AddIcon from '@mui/icons-material/Add';
import { Box, Container, Fab, MenuItem, Select, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import SearchBar from "components/common/SearchBar";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from "react-redux";
import ProjectListItem from "./ProjectListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(2),
  },
}));

export default function ProjectList({ height, onItemSelect, onAddNewProject }) {
  const classes = useStyles();
  const handleClick = (project) => () => !!onItemSelect && onItemSelect(project);
  const handelNewProjectClick = () => !!onAddNewProject && onAddNewProject();
  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getProjects());
  }

  useEffect(updateAll, []);
  useEffect(() => {
    const selectedCategory = selectedDepartmentValue ? selectedDepartmentValue : departments[0].Id;
    setCategory(selectedCategory)
  }, [])
  const { projects, selectedDepartmentValue } = useSelector(prop('project'));

  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = React.useState('');
  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }

  useEffect(() => {
    let filterdList = projects ? projects : [];
    if (searchText.length > 0) {
      filterdList = filterdList.filter(k => k.ProjectName?.toLowerCase().includes(searchText)
        || k.ProjectNo?.toLowerCase().includes(searchText)
        || k.Department?.toLowerCase().includes(searchText) || k.Location?.toLowerCase().includes(searchText));
    }
    if (selectedDepartmentValue) {
      filterdList = filterdList.filter(k => k.Department.toLowerCase() === selectedDepartmentValue.toLowerCase());
    }
    setFilteredList(filterdList)
  }, [projects, searchText, selectedDepartmentValue]);
  const { LoginUserRole, } = useSelector(prop("user"));
  const [category, setCategory] = useState();

  const departments = dropDownOption.Departments;

  const handleDepartmentChange = (event) => {
    dispatch(
      actions.setProjectProps({ selectedDepartmentValue: event.target.value })
    );
    setCategory(event.target.value);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let allProjects = Array.from(projects); // Use the actual list
    const movedUserId = filteredList[result.source.index].Id;
    const nextUser = filteredList[result.destination.index + 1];
    const movedUserIndex = allProjects.findIndex(user => user.Id === movedUserId);


    let moveToIndex = nextUser ? allProjects.findIndex(user => user.Id === nextUser.Id) - 1 : allProjects.length - 1;


    if (movedUserIndex !== -1) {
      const [movedItem] = allProjects.splice(movedUserIndex, 1);
      allProjects.splice(moveToIndex, 0, movedItem);
      allProjects = allProjects.map((proj, index) => ({ ...proj, ProjectOrder: index + 1 }));
      dispatch(actions.saveProjectOrder(allProjects));
    }

  };
  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            Project administration
          </strong>
        </Typography>
        {/* <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many projects as you want.
        </Typography> */}
      </Box>
      {LoginUserRole === "admin" ? (
        <>
          <Box mt={1}>
            <Select
              fullWidth
              defaultValue={departments[0].Id}
              labelId="department-select-standard-label"
              id="department-select-standard"
              value={category || ""}
              onChange={handleDepartmentChange}
              label="Department(s)"
            >
              {departments?.map((department) => (
                <MenuItem value={department.Id}>{department.Name}</MenuItem>
              ))}

            </Select>
          </Box>
        </>
      ) : (
        <></>
      )}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of projects
          </strong>
        </Typography>
        <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
      </Box>
      {/* <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
          <ListItem
            dense
            disableGutters
            onClick={handelNewProjectClick}
            className={classes.item}
            data-testid={'li-add-user'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a Project'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem>
          {filteredList?.map((project) => (
            <ProjectListItem
              key={project.Id}
              project={project}
              onClick={handleClick(project)}
              itemClasses={classes.item}

            />
          ))}
        </ul>
      </List> */}


      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <List
              className={classes.root}
              style={{ height }}
              dense={false}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <ul className={classes.ul}>
                {filteredList?.map((project, index) => (
                  <Draggable key={project.Id} draggableId={project.Id.toString()} index={index} >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <ProjectListItem
                          key={project.Id}
                          project={project}
                          onClick={handleClick(project)}
                          itemClasses={classes.item}

                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </ul>
              {provided.placeholder}
            </List>
          )}
        </Droppable>

        {/* Floating Add Button */}
        <Fab color="primary" className={classes.fab} onClick={handelNewProjectClick} data-testid={"floating-add-button"}>
          <AddIcon />
        </Fab>

      </DragDropContext>
    </Container>
  );
}
