/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Creed from './Creed';
import Project from './Project';
/**
 * The ProjectSpecification model module.
 * @module model/ProjectSpecification
 * @version 1.0
 */
class ProjectSpecification {
    /**
     * Constructs a new <code>ProjectSpecification</code>.
     * @alias module:model/ProjectSpecification
     */
    constructor() {

        ProjectSpecification.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>ProjectSpecification</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProjectSpecification} obj Optional instance to populate.
     * @return {module:model/ProjectSpecification} The populated <code>ProjectSpecification</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProjectSpecification();

            if (data.hasOwnProperty('Creed')) {
                obj['Creed'] = ApiClient.convertToType(data['Creed'], Creed);
            }
            if (data.hasOwnProperty('Project')) {
                obj['Project'] = ApiClient.convertToType(data['Project'], Project);
            }

            if (data.hasOwnProperty('Value')) {
                obj['Value'] = ApiClient.convertToType(data['Value'], String);
            }
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }




        }
        return obj;
    }


}

/**
 * ProjectSpecification first name
 * @member {String} ProjectSpecificationName
 */
ProjectSpecification.prototype['ProjectSpecificationName'] = undefined;

/**
 * ProjectSpecification first name
 * @member {String} Location
 */
ProjectSpecification.prototype['Location'] = undefined;
/**
 * ProjectSpecification first name
 * @member {String} Client
 */
ProjectSpecification.prototype['Client'] = undefined;
/**
 * ProjectSpecification first name
 * @member {String} Contractor
 */
ProjectSpecification.prototype['Contractor'] = undefined;
/**
 * client first name
 * @member {String} ProjectSpecificationType
 */
ProjectSpecification.prototype['ProjectSpecificationType'] = undefined;
/**
* @member {String} SubContractor
*/
ProjectSpecification.prototype['SubContractor'] = undefined;

/**
* @member {Boolean} PaymentReceived
*/
ProjectSpecification.prototype['PaymentReceived'] = undefined;

/**
* @member {String} PaymentDate
*/
ProjectSpecification.prototype['PaymentDate'] = undefined;

/**
* @member {String} ProjectSpecificationNo
*/
ProjectSpecification.prototype['ProjectSpecificationNo'] = undefined;
/**
* @member {String} Landmark
*/
ProjectSpecification.prototype['Landmark'] = undefined;

/**
* rolw
* @member {module:model/ProjectSpecification.Role} Role
*/
ProjectSpecification.prototype['Role'] = undefined;

/**
 * @member {String} Id
 */
ProjectSpecification.prototype['Id'] = undefined;
/**
 * @member {Object} ProjectSpecificationClient
 */
ProjectSpecification.prototype['ProjectSpecificationClient'] = undefined;


ProjectSpecification.prototype['PrimaryContact'] = undefined;
ProjectSpecification.prototype['ClientProjectSpecificationManager'] = undefined;
ProjectSpecification.prototype['ClientManagerEmail'] = undefined;
ProjectSpecification.prototype['ClientManagerPhoneNumber'] = undefined;
ProjectSpecification.prototype['ContractorProjectSpecificationManager'] = undefined;
ProjectSpecification.prototype['ContractorManagerEmail'] = undefined;
ProjectSpecification.prototype['ContractorManagerPhoneNumber'] = undefined;
ProjectSpecification.prototype['SubContractorProjectSpecificationManager'] = undefined;
ProjectSpecification.prototype['SubContractorManagerEmail'] = undefined;
ProjectSpecification.prototype['SubContractorManagerPhoneNumber'] = undefined;
ProjectSpecification.prototype['ProjectSpecificationAddresses'] = undefined;


export default ProjectSpecification;

