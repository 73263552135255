import React from "react";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import { Box } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    marginTop: theme.spacing(2),
    // @ts-ignore
    marginBottom: theme.spacing(4),
    textAlign: 'center'
  },
  button: {
    minWidth: 250,
    // @ts-ignore
    paddingLeft: theme.spacing(3),
    // @ts-ignore
    paddingRight: theme.spacing(3),
  },
}));

export default function PrimaryButton({ children, gutterTop, ...buttonProps }) {

  const classes = useStyles();

  return (
    <Box className={classes.root}
      pt={gutterTop && 4}>
      <Button variant="contained"
        color="primary"
        className={classes.button}
        {...buttonProps}>
        {children}
      </Button>
    </Box>
  );
}