/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

 import ApiClient from '../ApiClient';

 /**
  * The Alert model module.
  * @module model/Alert
  * @version 1.0
  */
 class Alert {
     /**
      * Constructs a new <code>Alert</code>.
      * @alias module:model/Alert
      */
     constructor() { 
         
         Alert.initialize(this);
     }
 
     /**
      * Initializes the fields of this object.
      * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
      * Only for internal use.
      */
     static initialize(obj) { 
     }
 
     /**
      * Constructs a <code>Alert</code> from a plain JavaScript object, optionally creating a new instance.
      * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
      * @param {Object} data The plain JavaScript object bearing properties of interest.
      * @param {module:model/Alert} obj Optional instance to populate.
      * @return {module:model/Alert} The populated <code>Alert</code> instance.
      */
     static constructFromObject(data, obj) {
         if (data) {
             obj = obj || new Alert();
 
             if (data.hasOwnProperty('CreateDate')) {
                obj['CreateDate'] = ApiClient.convertToType(data['CreateDate'], 'Date');
            }
         
            if (data.hasOwnProperty('ModifiedDate')) {
                obj['ModifiedDate'] = ApiClient.convertToType(data['ModifiedDate'], 'Date');
            }

             
             if (data.hasOwnProperty('Id')) {
                 obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
             }
             if (data.hasOwnProperty('Visited')) {
                 obj['Visited'] = ApiClient.convertToType(data['Visited'], 'Boolean');
             }
           
             if (data.hasOwnProperty('Alert')) {
                 obj['Alert'] = ApiClient.convertToType(data['Alert'], 'Object');
             }

            
             
             
         }
         return obj;
     }
 
 
 }
 
 
 /**
  * client first name
  * @member {String} JobType
  */
  Alert.prototype['JobType'] = undefined;

  /* client first name
  * @member {String} AssignedName
  */
  Alert.prototype['AssignedName'] = undefined;


 /**
  * @member {String} Id
  */
  Alert.prototype['Id'] = undefined;
 /**
  * @member {String} Visited
  */
  Alert.prototype['Visited'] = undefined;
 
 /**
  * @member {String} AssignedTo
  */
  Alert.prototype['AssignedTo'] = undefined;
 
 /**
  * @member {String} Alert
  */
  Alert.prototype['Alert'] = undefined;
 /**
  * @member {String} DJR
  */
  Alert.prototype['DJR'] = undefined;
 
 /**
  * @member {String} CreateDate
  */
  Alert.prototype['CreateDate'] = undefined;
 
 /**
  * @member {String} ModifiedDate
  */
  Alert.prototype['ModifiedDate'] = undefined;
 /**
  * @member {String} Instruments
  */
  Alert.prototype['Instruments'] = undefined;
 /**
  * @member {String} JobType
  */
  Alert.prototype['JobType'] = undefined;
 /**
  * @member {String} PayrollCode
  */
  Alert.prototype['PayrollCode'] = undefined;
 /**
  * @member {String} Title
  */
  Alert.prototype['Title'] = undefined;
 
 
 
 
 export default Alert;
 
 