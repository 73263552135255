import React from 'react';
import { isValidNumber } from 'libphonenumber-js';

import { ValidatorComponent, ValidatorForm } from 'react-material-ui-form-validator';
import MaterialUiPhoneNumber from 'material-ui-phone-number';

ValidatorForm.addValidationRule('isPhone', value => {
  return !value || isValidNumber(value);
});

class PhoneValidator extends ValidatorComponent {

  renderValidatorComponent() {
    const { error, helperText, validators, errorMessages, validatorListener, ...otherProps } = this.props;
    const { isValid } = this.state;
    return (
      <MaterialUiPhoneNumber
        {...otherProps}
        error={!isValid || error}
        helperText={(!isValid && this.getErrorMessage()) || helperText}
      />
    );
  }
}

export default PhoneValidator;
