import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemAvatar } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";


export default function ClientListItem({ client, onClick, itemClasses }) {

  const { Name } = client;

 
  const handleClick = (e) => onClick && onClick(e);

  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      className={itemClasses}
      data-testid={'li-item-'+client.id}
    >
      <ListItemAvatar>
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
        primary={Name}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
