import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Box, Container } from "@mui/material";
import FlexBox from "components/common/FlexBox";
import Typography from "@mui/material/Typography";
import TextValidator from "components/common/input/TextValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import PrimaryButton from "components/common/PrimaryButton";
import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { chain, defer, isEmpty, trim } from "lodash";
import PhoneValidator from "components/common/input/PhoneValidator";
import * as actions from "actions";
import { useSelector, useDispatch } from "react-redux";
import { prop } from "ramda";
import TakeSignature from "components/common/signaturepad";
import BgView from "components/common/BgView";
import BackAppBar from "components/app/BackAppBar";
import { formatDateTime } from "utils/strUtils";
import path from "routesPath";
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));
export default function EditDjr() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { djrDetails } = useSelector(prop('djr'));

    const { AssignedTo = {}, Project = {}, JobType, StartDate, EndDate, Djr = {}, ProjectAddress = {} } = djrDetails;
    const sdate = formatDateTime(StartDate);
    const edate = formatDateTime(EndDate);
    const { FirstName = '', LastName = '' } = AssignedTo;
    const Name = FirstName;
    const { ProjectName = '', ProjectNo = '' } = Project;

    const {
        Description = "",
        SignatureDate = new Date(),
        Temprature = "",
        Weather = "",
        ODOMeter = "",
        Hours = "",
        OT = 0,
        DT = 0,
        ClientSignature = "",
        UserSignature = "",
        Id = null
    } = Djr || {}
    const [clientSignature, setClientSignature] = useState('data:image/png;base64,' + Buffer.from(ClientSignature).toString('base64')); // create a state that will contain our image url

    const [userSignature, setUserSignature] = useState('data:image/png;base64,' + Buffer.from(UserSignature).toString('base64')); // create a state that will contain our image url

    const [form, setForm] =
        useState({
            Temprature: Temprature,
            Description: Description,
            Weather: Weather,
            ODOMeter: ODOMeter,
            Hours: Hours,
            OT: OT,
            DT: DT,
            Id: Id
        });
    const hasEmptyFields = chain({
        ...form,
    }).omit(['Id'])
        .mapValues(trim)
        .some(isEmpty)
        .value();
    const onAdd = () => {
        console.log(djrDetails);
        const payload = {
            ...form,
            "ClientSignature": clientSignature,
            "UserSignature": userSignature,
            "Schedule": { Id: djrDetails.Id }
        }
        dispatch(actions.saveDJR(payload));
    };
    const isNew = true;
    const disabled = !isNew;

    const updateField = (prop, value) => setForm({ ...form, [prop]: value });
    const handleChange = (prop) => (e) => {
        updateField(prop, e && e.target ? e.target.value : e);
    };

    const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

    const submitDisabled = hasEmptyFields;


    return (
        <>
            <BgView>
                <BackAppBar backPath={path.DJR} />
                <Container className={classes.root}>
                    <FlexBox className={classes.top} >
                        <Box flexGrow={1} pl={2}>
                            <Typography variant="h5">
                                <strong data-testid="label-user-administration">
                                    {!isNew ? <>Edit Djr</> : <>New Djr</>}
                                </strong>
                            </Typography>
                        </Box>
                    </FlexBox>

                    <Container className={classes.container}>
                        <FlexBox justifyContent="space-between">
                            <Typography gutterBottom className="bold" data-testid='label-desc'>Name</Typography>
                            <Typography gutterBottom data-testid='label-desc-value'>{Name}</Typography>
                        </FlexBox>
                        <FlexBox justifyContent="space-between">

                            <Typography gutterBottom className="bold" data-testid='label-JobType'>JobType</Typography>
                            <Typography gutterBottom data-testid='label-JobType-value'>{JobType}</Typography>
                        </FlexBox>

                        <FlexBox justifyContent="space-between">

                            <Typography gutterBottom className="bold" data-testid='label-JobType'>Start Time</Typography>
                            <Typography gutterBottom data-testid='label-JobType-value'>{sdate}</Typography>
                        </FlexBox>

                        <FlexBox justifyContent="space-between">
                            <Typography gutterBottom className="bold" data-testid='label-JobType'>End Time</Typography>
                            <Typography gutterBottom data-testid='label-JobType-value'>{edate}</Typography>
                        </FlexBox>

                        <FlexBox justifyContent="space-between">
                            <Typography gutterBottom className="bold" data-testid='label-ProjectName'>ProjectName</Typography>
                            <Typography gutterBottom data-testid='label-ProjectName-value'>{ProjectName}</Typography>
                        </FlexBox>

                        <FlexBox justifyContent="space-between">
                            <Typography gutterBottom className="bold" data-testid='label-ProjectNo'>ProjectNo</Typography>
                            <Typography gutterBottom data-testid='label-ProjectNo-value'>{ProjectNo}</Typography>
                        </FlexBox>

                        <FlexBox justifyContent="space-between">
                            <Typography gutterBottom className="bold" data-testid='label-Location'>Location</Typography>
                            <Typography gutterBottom data-testid='label-Location-value'>{ProjectAddress?.Location}</Typography>
                        </FlexBox>
                        <ValidatorForm instantValidate={true} autoComplete="off">
                            <TextValidator
                                autoComplete='new-password'
                                name="Temperature"
                                label="Temperature"
                                value={form?.Temprature}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                data-testid="input-user-name"
                                onChange={handleChange("Temprature")}
                                onBlur={handleBlur("Temprature")}
                            />

                            <TextValidator
                                autoComplete='new-password'
                                name="Weather"
                                label="Weather"
                                value={form?.Weather}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                data-testid="input-weather"
                                onChange={handleChange("Weather")}
                                onBlur={handleBlur("Weather")}
                            />

                            <TextValidator
                                autoComplete='new-password'
                                name="ODOMeter"
                                label="ODOMeter"
                                value={form?.ODOMeter}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                data-testid="input-ODOMeter"
                                onChange={handleChange("ODOMeter")}
                                onBlur={handleBlur("ODOMeter")}
                            />

                            <TextValidator
                                autoComplete='new-password'
                                name="Hours"
                                label="Hours"
                                value={form?.Hours}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                data-testid="input-Hours"
                                onChange={handleChange("Hours")}
                                onBlur={handleBlur("Hours")}
                            />


                            <TextValidator
                                autoComplete='new-password'
                                name="OT"
                                label="OT"
                                value={form?.OT}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                data-testid="input-OT"
                                onChange={handleChange("OT")}
                                onBlur={handleBlur("OT")}
                            />

                            <TextValidator
                                autoComplete='new-password'
                                name="DT"
                                label="DT"
                                value={form?.DT}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                data-testid="input-DT"
                                onChange={handleChange("DT")}
                                onBlur={handleBlur("DT")}
                            />


                            <TextValidator
                                autoComplete='new-password'
                                name="Description"
                                label="Description"
                                value={form?.Description}
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                                disabled={disabled}
                                data-testid="input-Description"
                                onChange={handleChange("Description")}
                                onBlur={handleBlur("Description")}
                            />



                        </ValidatorForm>
                        <TakeSignature imageURL={userSignature} setImageURL={setUserSignature} Label='ICC Personnel Signature'></TakeSignature>
                        <TakeSignature imageURL={clientSignature} setImageURL={setClientSignature} Label='Client Signature'></TakeSignature>
                    </Container>
                    <FlexBox justifyContent="center" mt={1}>

                        <PrimaryButton onClick={onAdd} data-testid="btn-add-user" disabled={submitDisabled}>
                            Save
                        </PrimaryButton>

                    </FlexBox>
                </Container>
            </BgView>
        </>

    );
}
