import React from 'react';
import BackAppBar from "components/app/BackAppBar";
import {useSelector} from "react-redux";
import {prop} from "ramda";
import BgView from "components/common/BgView";
import DJRDetails from "components/djr/DJRDetails";
import path from 'routesPath';

export default function DJRView() {

  const {djrDetails} = useSelector(prop('djr'));

  return (
      <BgView>
        <BackAppBar backPath={path.allDJRs}/>
        <DJRDetails schedule={djrDetails}/>
      </BgView>
  );
}
