export const path = {
  root: "/",
  login: '/login',






  home: "/home",
  DJR: "/DJR",
  alert: "/alert",
  allAlerts: "/allAlerts",
  allDJRs: "/allDJR",
  DJRSummary: '/djrSummary',
  EditDJR: '/editDJR',
  profile: {
    alerts: "/allAlerts",
    userAdministration: "/profile/userAdministration",
    legal: "/profile/legal",
    notifications: "/profile/notifications",
    projectAdministration: "/profile/projectAdministration",
    credAdministration: "/profile/credAdministration",
    clientAdministration: "/profile/clientAdministration",
    contractorAdministration: "/profile/contractorAdministration",
    subContractorAdministration: "/profile/subContractorAdministration",
    leaveAdministration: "/profile/leaveAdministration",
    holidayAdministration: "/profile/holidayAdministration",
    report: "/profile/report",
    personalInfo: "/profile/personalInfo",
    matApproval: "/profile/matApproval",
    matProjectSpecification: "/profile/matProjectSpecification",
  },
  user: {
    userDetail: "/user/details",
    userUpdatePassword: "/user/updatePassword",
  },
  client: {
    clientDetail: "/client/details",
  },
  project: {
    projectDetail: "/project/details",
  },
  creed: {
    creedDetail: "/creed/details",
  },
  creedRequest: {
    creedRequestDetail: "/creedRequest/details"
  },
  holiday: {
    holidayDetail: "/holiday/details",
  },
  contractor: {
    contractorDetail: "/contractor/details",
  },
  subContractor: {
    subContractorDetail: "/subContractor/details",
  },
  leaves: {
    leaveDetail: "/leaves/details",
    leaveList: "/leaves/list",
  },
  task: {
    taskDetail: "/task/details",
  },

  forgotPassword: "/forgotPassword",
  feedback: '/feedback',
  coverage: '/coverage',

  terms: "/terms",
  appterms: "/appterms",
  appprivacy: "/appprivacy",
  phoneVerify: "/phoneVerify",

};

export function currentPath() {
  return window.location.hash.substring(1);
}

export default path;
