import _ from 'lodash';
import {payloadAction} from "../utils/stateUtils";

export const PROGRESS_SET_PROPS = 'PROGRESS_SET_PROPS';
export const PROGRESS_SHOW = 'PROGRESS_SHOW';
export const PROGRESS_HIDE = 'PROGRESS_HIDE';

export const setProgressProps = payloadAction(PROGRESS_SET_PROPS);

export const showProgress = (id = _.uniqueId('progress')) => ({
  type: PROGRESS_SHOW,
  payload: { id },
});

export const hideProgress = (id, failed = false) => ({
  type: PROGRESS_HIDE,
  payload: { id, failed },
});
