import React from "react";
import moment from "moment";
import makeStyles from '@mui/styles/makeStyles';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemAvatar } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import defaultValue from "assets/data/default-value";
import { formatDateTime, parseDateWithTZ } from "utils/strUtils";
export default function ContractorListItem({ leave, onClick, itemClasses }) {

  const { Reason, StartDate } = leave;
  const sd = formatDateTime(parseDateWithTZ(StartDate));
  const handleClick = (e) => onClick && onClick(e);

  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      className={itemClasses}
      data-testid={'li-item-'+leave.id}
    >
      <ListItemAvatar>
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
      secondary={sd}
        primary={Reason}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
