import React from 'react';
import TopAppBar from "./TopAppBar";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {prop} from "ramda";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  logo: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
})

const LogoAppBar = React.forwardRef((props, ref) => {

  const classes = useStyles();

  const {cfiName} = useSelector(prop('app'));
  const logo = (
      <Typography variant="h6" className={classes.logo}>
        <strong>{cfiName}</strong>
      </Typography>
  )

  return (
      <TopAppBar {...props} ref={ref} logo={logo}/>
  )

});

export default LogoAppBar;