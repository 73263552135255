import React from "react";
import BackAppBar from "components/app/BackAppBar";
import { useSelector } from "react-redux";
import { prop } from "ramda";
import BgView from "components/common/BgView";
import ProjectDeails from "components/project/ProjectDetails";

export default function ProjectView() {
  const { projectDetails } = useSelector(prop('project'));
  return (
    <BgView>
      <BackAppBar />
      <ProjectDeails Project={projectDetails} />
    </BgView>
  );
}
