import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import { prop } from "ramda";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FeedbackForm from "./FeedbackForm";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
    },
    feedbackFormContainer: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
}));

export default function FeedbackView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { feedbackDataSet } = useSelector(prop('creedRequest'));
    const { id } = useParams();

    useEffect(() => {
        console.log("FeedbackView component mounted");
        console.log("Feedback data set:", feedbackDataSet);
    }, [feedbackDataSet]);

    useEffect(() => {
        dispatch(actions.getCreedFeedBacks({ Id: id }));
    }, []);

    return (
        <div className={classes.root}>
            <h1>Feedback</h1>
            <Grid container spacing={2}>
                {feedbackDataSet?.map((feedbackData) => (
                    <Grid item xs={12} sm={6} key={feedbackData.Id}>
                        <div className={classes.feedbackFormContainer}>
                            <FeedbackForm feedbackData={feedbackData} />
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
