/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
/**
 * The Leave model module.
 * @module model/Leave
 * @version 1.0
 */
class Leave {
  /**
   * Constructs a new <code>Leave</code>.
   * @alias module:model/Leave
   */
  constructor() {
    Leave.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Leave</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Leave} obj Optional instance to populate.
   * @return {module:model/Leave} The populated <code>Leave</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Leave();

      if (data.hasOwnProperty("User")) {
        obj["User"] = ApiClient.convertToType(data["User"], "String");
      }
      if (data.hasOwnProperty("StartDate")) {
        obj["StartDate"] = ApiClient.convertToType(data["StartDate"], 'Date');
      }
      if (data.hasOwnProperty("EndDate")) {
        obj["EndDate"] = ApiClient.convertToType(data["EndDate"], 'Date');
      }
      if (data.hasOwnProperty("Reason")) {
        obj["Reason"] = ApiClient.convertToType(data["Reason"], "String");
      }
      if (data.hasOwnProperty("AssignedTo")) {
        obj["AssignedTo"] = ApiClient.convertToType(data["AssignedTo"],"Object");
      }
      if (data.hasOwnProperty("Id")) {
        obj["Id"] = ApiClient.convertToType(data["Id"], "String");
      }
    }
    return obj;
  }
}

/**
 * user details
 * @member {object} User
 */
Leave.prototype["User"] = undefined;
/**
 * leave start date
 * @member {string} StartDate
 */
Leave.prototype["StartDate"] = undefined;
/**
 * leave end date
 * @member {string} EndDate
 */
Leave.prototype["EndDate"] = undefined;
/**
 * leave Reason
 * @member {string} Reason
 */
Leave.prototype["Reason"] = undefined;
/**
 * leave Reason
 * @member {Object} selectedUser
 */
Leave.prototype["AssignedTo"] = undefined;

export default Leave;
