import { Button, DialogActions, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from '@mui/styles/makeStyles';
import { SlideUp } from "components/common/transitions";
import React from 'react';
import { formatDateTime } from "utils/strUtils";
const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-end",
        width: '100%'
    },
    topPaperScrollBody: {
        margin: '0',
        width: '100%'
    },

});

export default function DeleteCreedReqDialog({ open, onClose, onRemoveConfirm, creedRequest = {}, isDelete = false }) {
    const classes = useStyles();
    const { Comment, QB, Creed, Project, RequestBy, UserCreedsNotify, StartDate, EndDate, Status, ContactName, ContactPhoneNumber, } = creedRequest;
    const sdate = formatDateTime(StartDate);
    const edate = formatDateTime(EndDate);
    const getSubTExt = () => {
        if (creedRequest != null)
            return `Job position ${Creed?.CreedName} \n Project ${Project?.ProjectName} \n Date ${sdate} - ${edate} . `;
        return ''
    }
    const message = () => {
        if (isDelete)
            return 'Are you sure you want to delete ?'
        return 'Are you sure you want to cancel ?'
    }
    return (
        <Dialog open={open}
            onClose={onClose}
            TransitionComponent={SlideUp}
            classes={{

                scrollPaper: classes.topScrollPaper,
                paperScrollPaper: classes.topPaperScrollBody
            }}
        >
            <DialogContent>
                <Typography gutterBottom >{message()}</Typography>
                <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>{getSubTExt()}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    data-testid='btn-dialog-back'>
                    Back
                </Button>
                <Button variant="contained"
                    color="secondary"
                    onClick={onRemoveConfirm}
                    data-testid='btn-dialog-remove'
                >
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    );
}