/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LoginRequest model module.
 * @module model/LoginRequest
 * @version 1.0
 */
class LoginRequest {
    /**
     * Constructs a new <code>LoginRequest</code>.
     * @alias module:model/LoginRequest
     * @param otp {String} 
     * @param phone {String} 
     * @param smsId {String} 
     */
    constructor(otp, phone, smsId) { 
        
        LoginRequest.initialize(this, otp, phone, smsId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, otp, phone, smsId) { 
        obj['otp'] = otp;
        obj['phone'] = phone;
        obj['smsId'] = smsId;
    }

    /**
     * Constructs a <code>LoginRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LoginRequest} obj Optional instance to populate.
     * @return {module:model/LoginRequest} The populated <code>LoginRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoginRequest();

            if (data.hasOwnProperty('deviceId')) {
                obj['deviceId'] = ApiClient.convertToType(data['deviceId'], 'String');
            }
            if (data.hasOwnProperty('otp')) {
                obj['otp'] = ApiClient.convertToType(data['otp'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('smsId')) {
                obj['smsId'] = ApiClient.convertToType(data['smsId'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} deviceId
 */
LoginRequest.prototype['deviceId'] = undefined;

/**
 * @member {String} otp
 */
LoginRequest.prototype['otp'] = undefined;

/**
 * @member {String} phone
 */
LoginRequest.prototype['phone'] = undefined;

/**
 * @member {String} smsId
 */
LoginRequest.prototype['smsId'] = undefined;






export default LoginRequest;

