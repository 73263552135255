import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Grid, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as actions from "actions";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNameStr } from 'utils/strUtils';
import AddUserToCreedReqDialogue from './AddClientUserDialogue';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    listItemButtons: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flex: 0
    },
    listItemButton: {
        marginLeft: theme.spacing(1),
    },
}));

function CreedRequestUsersList({ userCreeds, updateUserCreeds, onApprove, creedRequest, project, onReassign }) {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [selectedDepartment, setSelectedDepartment] = useState('dob');
    const [openAddCreedDialogue, setOpenAddCreedDialogue] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (creedRequest.Id) {
            dispatch(actions.getCreedRecommendation({ creedRequest: creedRequest }));
        } else {
            dispatch(actions.setCreedRequestProps({ creedRecommendation: [] }));
        }

    }, [creedRequest]);

    useEffect(() => {
        if (project?.Department)
            setSelectedDepartment(project.Department)
    }, [project])

    useEffect(() => {
        // alert(userCreeds?.length);
        if (userCreeds?.length > 0) {
            setItems(userCreeds.map(k => ({
                ...k,
                User: {
                    ...k.User,
                    Name: getNameStr(k.User)
                }
            })));
        } else {
            setItems([]);
        }
    }, [userCreeds])

    const onAddCreed = async (data) => {
        let tempData = {
            Id: creedRequest.Id,
            Users: data,
            goBack: true
        }
        await dispatch(actions.addUserToCreedRequest(tempData));
        dispatch(actions.showCreedRequestDetails(tempData));
        setOpenAddCreedDialogue(false);
    };
    const closeAddCreedDialog = () => {
        setOpenAddCreedDialogue(false);
    }


    const addItem = () => {
        setOpenAddCreedDialogue(true)
    };

    const handleApprove = (item) => {
        onApprove(item);
        // const updatedItem = prompt('Edit Item', items[index]);
        // if (updatedItem) {
        //     const newItems = [...items];
        //     newItems[index] = updatedItem;
        //     updateUserCreeds(newItems);
        // }
    };

    const handleReassign = (item) => {
        onReassign(item);
        // const updatedItem = prompt('Edit Item', items[index]);
        // if (updatedItem) {
        //     const newItems = [...items];
        //     newItems[index] = updatedItem;
        //     updateUserCreeds(newItems);
        // }
    };

    const handleDelete = (index) => {
        const newItems = items.filter((item, i) => i !== index);
        updateUserCreeds(newItems);
    };

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h4>User's</h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.root}>
                    <Grid container spacing={2}> {/* added this line */}
                        {items.map((item, index) => (
                            <Grid item xs={12} sm={12} key={index}> {/* added this line */}
                                <ListItem sx={{ width: '100%' }}>
                                    <ListItemText primary={`${item.User.Name} `}
                                        secondary={item.User.Email} />
                                    <div>
                                        {
                                            item.Status && <Chip label={item.Status} size="small"
                                                color={item.Status === 'Available' || item.Status === 'Approved' ? "success" : "error"} />
                                        }

                                    </div>
                                    <div className={classes.listItemButtons}>
                                        {
                                            item.Status === 'Available' &&

                                            <Button className={classes.listItemButton} size='small' variant="contained" color="primary" onClick={() => handleApprove(item)}>
                                                Approve
                                            </Button>
                                        }
                                        {
                                            item.Status === 'Approved' &&

                                            <Button className={classes.listItemButton} size='small' variant="contained" color="primary" onClick={() => handleReassign(item)}>
                                                ReAssign
                                            </Button>
                                        }
                                        {/* <Button className={classes.listItemButton} size='small' variant="contained" color="secondary" onClick={() => handleDelete(index)}>
                                            Reject
                                        </Button> */}
                                    </div>
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                    <Button variant="contained" color="primary" onClick={addItem} size='small'>
                        Add New
                    </Button>
                </div>
            </AccordionDetails>

            <AddUserToCreedReqDialogue
                creedRequest={creedRequest}
                userCreedsList={items}
                open={openAddCreedDialogue}
                handleClose={closeAddCreedDialog}
                onAddUserToCreedSave={onAddCreed}
                department={selectedDepartment || 'dob'}
            >
            </AddUserToCreedReqDialogue>
        </Accordion>
    );
}

export default CreedRequestUsersList;
