/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


 import ApiClient from "../ApiClient";
 import Contractor from '../model/Contractor';
 import APIEndPoints from "./ApiEndpoints";
 
 /**
 * CardsController service.
 * @module api/ContractorControllerApi
 * @version 1.0
 */
 export default class ContractorControllerApi {
 
     /**
     * Constructs a new ContractorControllerApi. 
     * @alias module:api/ContractorControllerApi
     * @class
     * @param {module:ApiClient} [apiContractor] Optional API contractor implementation to use,
     * default to {@link module:ApiClient#instance} if unspecified.
     */
     constructor(apiContractor) {
         this.apiContractor = apiContractor || ApiClient.instance;
         this.endPoints = new APIEndPoints();
     }
 
 
 
 
     /**
      * getContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Contractor>} and HTTP response
      */
      getContractorsUsingGETWithHttpInfo() {
         let postBody = null;
 
         let pathParams = {
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = [Contractor];
         return this.apiContractor.callApi(
             this.endPoints.contractor.GetAll.URI,
             this.endPoints.contractor.GetAll.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Contractor>}
      */
      getContractorsUsingGET() {
         return this.getContractorsUsingGETWithHttpInfo()
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     /**
      * getAddContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Contractor>} and HTTP response
      */
     addContractorWithHttpInfo(body) {
         let postBody = body;
 
         let pathParams = {
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = Contractor;
         return this.apiContractor.callApi(
             this.endPoints.contractor.Post.URI,
             this.endPoints.contractor.Post.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getAddContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Contractor>}
      */
     addContractor(body) {
         return this.addContractorWithHttpInfo(body)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     /**
  * getContractor
  * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Contractor>} and HTTP response
  */
     removeContractorWithHttpInfo(id) {
         let postBody = null;
 
         let pathParams = {
             id: id
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = Contractor;
         return this.apiContractor.callApi(
             this.endPoints.contractor.Delete.URI,
             this.endPoints.contractor.Delete.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Contractor>}
      */
     removeContractor(id) {
         return this.removeContractorWithHttpInfo(id)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
 
 }
 