/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


 import ApiClient from "../ApiClient";
 import SubContractor from '../model/SubContractor';
 import APIEndPoints from "./ApiEndpoints";
 
 /**
 * CardsController service.
 * @module api/SubContractorControllerApi
 * @version 1.0
 */
 export default class SubContractorControllerApi {
 
     /**
     * Constructs a new SubContractorControllerApi. 
     * @alias module:api/SubContractorControllerApi
     * @class
     * @param {module:ApiClient} [apiSubContractor] Optional API subContractor implementation to use,
     * default to {@link module:ApiClient#instance} if unspecified.
     */
     constructor(apiSubContractor) {
         this.apiSubContractor = apiSubContractor || ApiClient.instance;
         this.endPoints = new APIEndPoints();
     }
 
 
 
 
     /**
      * getSubContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubContractor>} and HTTP response
      */
      getSubContractorsUsingGETWithHttpInfo() {
         let postBody = null;
 
         let pathParams = {
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = [SubContractor];
         return this.apiSubContractor.callApi(
             this.endPoints.subContractor.GetAll.URI,
             this.endPoints.subContractor.GetAll.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getSubContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubContractor>}
      */
      getSubContractorsUsingGET() {
         return this.getSubContractorsUsingGETWithHttpInfo()
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     /**
      * getAddSubContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubContractor>} and HTTP response
      */
     addSubContractorWithHttpInfo(body) {
         let postBody = body;
 
         let pathParams = {
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = SubContractor;
         return this.apiSubContractor.callApi(
             this.endPoints.subContractor.Post.URI,
             this.endPoints.subContractor.Post.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getAddSubContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubContractor>}
      */
     addSubContractor(body) {
         return this.addSubContractorWithHttpInfo(body)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     /**
  * getSubContractor
  * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubContractor>} and HTTP response
  */
     removeSubContractorWithHttpInfo(id) {
         let postBody = null;
 
         let pathParams = {
             id: id
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = SubContractor;
         return this.apiSubContractor.callApi(
             this.endPoints.subContractor.Delete.URI,
             this.endPoints.subContractor.Delete.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getSubContractor
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubContractor>}
      */
     removeSubContractor(id) {
         return this.removeSubContractorWithHttpInfo(id)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
 
 }
 