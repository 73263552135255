

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PrimaryButton from 'components/common/PrimaryButton';

import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Box, Container } from "@mui/material";
import FlexBox from "components/common/FlexBox";
import Typography from "@mui/material/Typography";
import TextValidator from "components/common/input/TextValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { chain, isEmpty, trim } from "lodash";
import PhoneValidator from "components/common/input/PhoneValidator";
import * as actions from "actions";
import { useDispatch, useSelector } from 'react-redux';
import { prop } from 'ramda';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '.MuiDialog-paper': {
        // backgroundColor:'red'
        margin: '5px',
        width: '100%',
        borderRadius: '10px'
    }
}));
const useStyles = makeStyles((theme) => ({
    root: {
        // minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        display: 'flex',
        justifyItems: 'center',
        justifyContent: 'center'

    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        // position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '32px' }} {...other}>
            <strong> {children}</strong>
            {onClose ? (

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute!important',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function EditJobStatusDialogue({ handleClose, open, data,scheduleId }) {


    const classes = useStyles();
    const dispatch = useDispatch();

    const JobStatusOptions = [ 'Completed','Pending'];

    const client = data || {};
    const { Id = null, JobStatus } = client;
    const [form, setForm] =
        useState({
            JobStatus: JobStatus,
            Id: Id
        });
    const hasEmptyFields = chain({
        ...form,
    })
        .omit(["Id"])

        .mapValues(trim)
        .some(isEmpty)
        .value();

    const onAdd = () => {
        let body = { ...form ,ScheduleId:scheduleId}

        dispatch(actions.updateJobStatus(body));
        handleClose();
    };
    const disabled = false;
    const updateField = (prop, value) => setForm({ ...form, [prop]: value });
    const handleChange = (prop) => (e) => {
        updateField(prop, e && e.target ? e.target.value : e);
    };
    const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());
    const submitDisabled = hasEmptyFields;


    const inputProps = {};
    

    return (
        <div>


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.title}>

                    Update Job Type
                </BootstrapDialogTitle>
                <DialogContent >



                    <Container className={classes.container}>
                        <ValidatorForm instantValidate={true} autoComplete="off">

                            <Autocomplete
                                value={form?.JobStatus}
                                onChange={(_event, newValue) => {
                                    updateField("JobStatus", newValue);
                                }}

                                id="controllable-states-demo"
                                options={JobStatusOptions}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Job Status"
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </ValidatorForm>
                    </Container>


                </DialogContent>
                <DialogActions>
                    <PrimaryButton autoFocus onClick={onAdd}>
                        Update
                    </PrimaryButton>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
