import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, Container, Grid, Stack, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import userRoles from "assets/data/roles";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import PhoneValidator from "components/common/input/PhoneValidator";
import TextValidator from "components/common/input/TextValidator";
import { chain, isEmpty, trim } from "lodash";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import RemoveUserDialog from "./RemoveUserDialog";
import ResetPasswordDialogue from "./ResetPassword";
import UserCreedList from "./User-Creeds-List";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  form: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  column: {
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 50%',
      padding: theme.spacing(2),
    },
  },
}));
export default function UserDetails({ User = {} }) {
  // const [roleValue, setRoleValue] = React.useState();
  const [showClientDropdown, setShowClientDropdown] = useState();
  const departments = dropDownOption.Departments;
  const employmentTypes = dropDownOption.EmploymentTypes;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { clients } = useSelector(prop('client'));
  const user = User || {};
  const {
    FirstName = "",
    Email = "",
    Role = "",
    MobileNo = "",
    WorkPhone = "",
    IsActive = true,
    SendEmail = false,
    SendSMS = false,
    isNew = false,
    Id = null,
    Client = null,
    UserCreeds = [],
    SSTExpire = null,
    SSTCard = '',
    Departments = [],
    EmploymentType = null,
    Rate = ''
  } = user;
  const [form, setForm] = useState({
    FirstName: FirstName,
    Email: Email,
    IsActive: IsActive,
    SendEmail: SendEmail,
    SendSMS: SendSMS,
    Role: Role ? userRoles.find(k => k.Id === Role) : null,
    MobileNo: MobileNo,
    WorkPhone: WorkPhone,
    Id: Id,
    Client: Client,
    UserCreeds: UserCreeds,
    SSTExpire: SSTExpire,
    SSTCard: SSTCard,
    Rate: Rate,
    Departments: Departments && Departments.length > 0 ? departments.filter(k => Departments.some(dep => dep.Department === k.Id)) : [],
    EmploymentType: EmploymentType ? employmentTypes.find(k => k.Id === EmploymentType) : null
  });
  const [Password, setPassword] = useState("");
  const passwordHandleChange = (props) => {
    setPassword(props.target.value);
  };
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const confirmPasswordHandleChange = (props) => {
    setConfirmPassword(props.target.value);
  };
  const hasEmptyFields =
    form?.Role?.Id === "staff"
      ? chain({
        ...form,
      })
        .omit(["Id"])
        .mapValues(trim)
        .some(isEmpty)
        .value()
      : chain({
        ...form,
      })
        .omit(["Id", "Skill", "Team", "SSTCard", "SSTExpiry"])
        .mapValues(trim)
        .some(isEmpty)
        .value();

  const [openRemoveUserDialog, setOpenRemoveUserDialog] = React.useState(false);
  const onRemove = () => {
    setOpenRemoveUserDialog(true);
  };

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = React.useState(false);
  const onResetPasswordClick = () => {
    setOpenResetPasswordDialog(true);
  };
  const onResetConfirm = () => {
    dispatch(actions.resetUserPassword(form));
    setOpenResetPasswordDialog(false);
  };
  const closeResetDialog = () => {
    setOpenResetPasswordDialog(false);
  };





  const onAdd = () => {
    let body = { ...form };
    if (isNew) {
      body["Password"] = Password;
      body["ConfirmPassword"] = ConfirmPassword;
    }
    body.Role = body?.Role?.Id ? body.Role.Id : "";
    body.Departments = body?.Departments?.length > 0 ? body.Departments.map(k => k.Id) : [];
    body.EmploymentType = body?.EmploymentType?.Id ? body.EmploymentType.Id : "";
    if (body.Role !== 'staff') {
      delete body.UserCreeds;
    }
    dispatch(actions.addUser(body));
  };
  const onRemoveConfirm = () => {
    dispatch(actions.removeUser(form));
  };
  const closeDialog = () => {
    setOpenRemoveUserDialog(false);
  };

  const disabled = false;
  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "IsActive" || prop === "IsFieldStaff" || prop === "SendEmail" || prop === "SendSMS") {
      updateField(prop, e.target.checked);
    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = false;

  useEffect(() => {
    setShowClientDropdown(form?.Role?.Id === "clientuser" || false);
  }, [form.Role]);

  return (
    <>
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit User</> : <>Add a User</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            <Grid container spacing={2} className={classes.form}>
              <Grid item xs={12} sm={6} className={classes.column}>

                <TextValidator
                  autoComplete='none'
                  name="FirstName"
                  label="Full Name"
                  value={form?.FirstName}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("FirstName")}
                  onBlur={handleBlur("FirstName")}
                  validators={["required"]}
                  errorMessages={["this field is required"]}

                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete='new-password'
                  name="Email"
                  label="Email"
                  value={form?.Email}
                  margin="normal"
                  fullWidth
                  disabled={!isNew}
                  onChange={handleChange("Email")}
                  validators={["required", "isEmail"]}
                  errorMessages={["this field is required", "Email is not valid"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <PhoneValidator
                  defaultCountry="us"
                  disableAreaCodes
                  countryCodeEditable={false}
                  label="Phone number"
                  value={form?.MobileNo}
                  onChange={(value) => updateField("MobileNo", value)}
                  onBlur={handleBlur("MobileNo")}
                  fullWidth
                  disabled={disabled}
                  margin="normal"
                  validators={["required", "isPhone"]}
                  errorMessages={[
                    "Phone number is required",
                    "The Mobile number is not valid",
                  ]}
                  inputProps={{
                    autoComplete: 'new-password'
                  }}
                  data-testid="input-MobileNo-number"
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <PhoneValidator
                  defaultCountry="us"
                  disableAreaCodes
                  countryCodeEditable={false}
                  label="Work Phone number"
                  value={form?.WorkPhone}
                  onChange={(value) => updateField("WorkPhone", value)}
                  onBlur={handleBlur("WorkPhone")}
                  fullWidth
                  disabled={disabled}
                  margin="normal"
                  validators={["required", "isPhone"]}
                  errorMessages={[
                    "Phone number is required",
                    "The Phone number is not valid",
                  ]}
                  inputProps={{
                    autoComplete: 'new-password'

                  }}
                  data-testid="input-WorkPhone-number"
                />
              </Grid>
              {isNew ? (
                <>
                  {
                    <Grid item xs={12} sm={6} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        required
                        name="password"
                        label="Password"
                        value={Password}
                        margin="normal"
                        fullWidth
                        type="password"
                        onChange={passwordHandleChange}
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                      />
                    </Grid>
                  }
                  {
                    <Grid item xs={12} sm={6} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        required
                        name="confirmPassword"
                        label="Confirm Password"
                        value={ConfirmPassword}
                        margin="normal"
                        fullWidth
                        type="password"
                        onChange={confirmPasswordHandleChange}
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                      />
                    </Grid>
                  }
                </>
              ) : (
                <></>
              )}
              <Grid item xs={12} sm={6} className={classes.column}>
                <Autocomplete
                  id="role-type-select"
                  options={userRoles}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Role"
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  getOptionLabel={(option) => option.Name}
                  value={form?.Role}
                  onChange={(_event, newValue) => {
                    updateField("Role", newValue);
                  }}
                />
              </Grid>
              {form?.Role?.Id === 'staff' &&
                <Grid item xs={12} sm={6} className={classes.column}>
                  <Autocomplete
                    id="department"
                    options={departments}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Department"
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                    multiple
                    getOptionLabel={(option) => option.Name}
                    value={form?.Departments}
                    onChange={(_event, newValue) => {
                      updateField("Departments", newValue);
                    }}
                  />
                </Grid>
              }
              {showClientDropdown && isNew ? (
                <>
                  <Grid item xs={12} sm={6} className={classes.column}>
                    <Autocomplete
                      id="staff-client"
                      options={clients}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      getOptionLabel={(option) => option.Name}
                      value={form?.StaffCategory}
                      onChange={(_event, newValue) => {
                        updateField("Client", newValue);
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}

              {form?.Role?.Id === 'staff' &&
                <Grid item xs={12} sm={6} className={classes.column}>
                  <TextValidator
                    autoComplete='none'
                    name="SSTCard"
                    label="SST Card"
                    value={form?.SSTCard}
                    margin="normal"
                    fullWidth
                    disabled={disabled}
                    data-testid="input-user-name"
                    onChange={handleChange("SSTCard")}
                    onBlur={handleBlur("SSTCard")}
                    errorMessages={["this field is required"]}
                  />
                </Grid>
              }
              {
                form?.Role?.Id === 'staff' &&
                <Grid item xs={12} sm={6} className={classes.column}>
                  <LocalizationProvider dateAdapter={
                    AdapterDateFns}>
                    <Stack spacing={4}>
                      <MobileDatePicker
                        name="SSTExpire"
                        label="SST Expiration Date"
                        value={form?.SSTExpire}
                        onChange={handleChange("SSTExpire")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              }
              {
                form?.Role?.Id === 'staff' &&
                <Grid item xs={12} sm={6} className={classes.column}>
                  <TextValidator
                    autoComplete='none'
                    name="Rate"
                    label="Rate"
                    value={form?.Rate}
                    margin="normal"
                    fullWidth
                    disabled={disabled}
                    data-testid="input-user-name"
                    onChange={handleChange("Rate")}
                    onBlur={handleBlur("Rate")}
                    errorMessages={["this field is required"]}
                  />
                </Grid>
              }

              {form?.Role?.Id === 'staff' &&
                <Grid item xs={12} sm={6} className={classes.column}>
                  <Autocomplete
                    id="EmploymentType"
                    options={employmentTypes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employment Type"
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                    getOptionLabel={(option) => option.Name}
                    value={form?.EmploymentType}
                    onChange={(_event, newValue) => {
                      updateField("EmploymentType", newValue);
                    }}
                  />
                </Grid>
              }

              {form?.Role?.Id === 'staff' &&
                <Grid item xs={12} sm={12} md={12} className={classes.column}>
                  <UserCreedList
                    departments={form.Departments}
                    userCreeds={form.UserCreeds || []}
                    updateUserCreeds={(newValue) => {
                      updateField("UserCreeds", newValue);
                    }}>

                  </UserCreedList>
                </Grid>
              }
              <Grid item xs={12} sm={4} md={4} className={classes.column}>
              <FormGroup sx={{ m: '10px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultValue='true'
                      checked={form?.IsActive}
                      onChange={handleChange("IsActive")}
                    />
                  }
                  label="Active"
                />
              </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.column}>
                <FormGroup sx={{ m: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultValue='true'
                        checked={form?.SendEmail}
                        onChange={handleChange("SendEmail")}
                      />
                    }
                    label="Send Email Notification"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={4} md={4} className={classes.column}>
                <FormGroup sx={{ m: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultValue='false'
                        checked={form?.SendSMS}
                        onChange={handleChange("SendSMS")}
                      />
                    }
                    label="Send SMS Notification"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Container>
        <FlexBox justifyContent="center">
          {!isNew ? (
            <>
              <PrimaryButton
                // color="secondary"
                onClick={onAdd}
                data-testid="btn-remove-user"
              >
                Save
              </PrimaryButton>


            </>
          ) : (
            <PrimaryButton
              onClick={onAdd}
              data-testid="btn-add-user"
              disabled={submitDisabled}
            >
              Add User
            </PrimaryButton>
          )}
        </FlexBox>
        <FlexBox justifyContent="center">
          {!isNew ?

            <PrimaryButton
              onClick={onRemove}
              disabled={submitDisabled}
              data-testid="btn-remove-task"
              color="error"
            >
              Remove
            </PrimaryButton>
            : ''}
        </FlexBox>
        <FlexBox justifyContent="center">
          {!isNew ?

            <PrimaryButton
              onClick={onResetPasswordClick}
              data-testid="btn-remove-task"
              color="error"
            >
              Reset Password
            </PrimaryButton> : ''}
        </FlexBox>
        <ResetPasswordDialogue
          open={openResetPasswordDialog}
          onClose={closeResetDialog}
          onRemoveConfirm={onResetConfirm}
        ></ResetPasswordDialogue>

        <RemoveUserDialog
          open={openRemoveUserDialog}
          onClose={closeDialog}
          onRemoveConfirm={onRemoveConfirm}
        ></RemoveUserDialog>
      </Container>
    </>
  );
}
