import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Chip, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { prop } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { abbr, formatDateTime } from 'utils/strUtils';
// Inside your component

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
export const STATUS_MAP = {
    "Raised": "New Request",// grey
    "InReview": "Pending",
    "Approved": "Approved",
    "Cancelled": "Cancelled",
    "Reassigned": "Reassigned"
}
export default function RecipeReviewCard({ creedRequest, onEditClick, onDeleteClick, onRateReviewClick, onCancelClick }) {
    const { Comment, QB, Creed, Project, RequestBy, UserCreedsNotify, StartDate, EndDate, Status, ContactName, ContactPhoneNumber, FillInFor } = creedRequest;
    const { LoginUserRole } = useSelector(prop("user"));

    const sdate = formatDateTime(StartDate);
    const edate = formatDateTime(EndDate);
    const handleEditClick = (e) => onEditClick && onEditClick(e);
    const handleDeleteClick = (e) => onDeleteClick && onDeleteClick(e);
    const handleCancelClick = (e) => onCancelClick && onCancelClick(e);
    const handleRateReviewClick = (e) => onRateReviewClick && onRateReviewClick(e);
    const theme = useTheme();
    function subHeader(sdate, edate) {
        return `${sdate} - ${edate}`
    }
    function statusName() {
        return STATUS_MAP[Status] || "";
    }
    function getColorBackground(pstatus) {
        if (pstatus === "Completed" || pstatus === "Approved") {
            return theme.palette.common.green
        } else if (pstatus === "Cancelled") {
            return theme.palette.common.red
        } else if (pstatus === "Raised") {
            return theme.palette.secondary.gray
        } else if (pstatus === 'Reassigned') {
            return '#ddebf7'
        }
        else {
            return theme.palette.warning.main
        }
    }

    function getColor(pstatus) {
        if (pstatus === "Completed" || pstatus === "Approved") {
            return theme.palette.common.white
        } else if (pstatus === "Cancelled") {
            return theme.palette.common.white
        } else if (pstatus === "Raised" || pstatus === "Reassigned") {
            return theme.palette.common.black
        }
        else {
            return theme.palette.warning.black
        }
    }
    return (
        <Card sx={{ width: '100%', height: '100%' }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: getColorBackground(Status) }} aria-label="recipe">
                        {abbr(statusName())}
                    </Avatar>
                }
                title={Creed.CreedName}
                subheader={subHeader(sdate, edate)}

            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    <strong>Project: </strong> {Project.ProjectName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <strong>Requested By: </strong> {RequestBy?.FirstName}
                    {RequestBy.Role == 'admin' && ' - ICC Admin'}
                    {RequestBy.Role == 'clientuser' && Project?.ProjectClient?.Name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <strong>Location: </strong> {Project.Location}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <strong>Contact Person: </strong> {ContactName}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    <strong>Contact Person Number: </strong> {ContactPhoneNumber}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <strong>Comment: </strong> {Comment}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <strong>Status: </strong>   {
                        Status && <Chip label={statusName()} size="small"

                            sx={{ background: getColorBackground(Status), color: getColor(Status) }} />
                    }
                </Typography>
                {
                    LoginUserRole === "admin" && <>
                        <Typography variant="body2" color="text.secondary">
                            <strong>Accepted By: </strong>   {
                                UserCreedsNotify?.filter(k => k.Status === 'Approved' || k.Status === 'Available')?.map(k => k.User.FirstName).join(', ')
                            }
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                            <strong>QB: </strong>  <Chip label={QB ? 'Yes' : 'No'} size="small" color={QB ? 'success' : 'error'} />
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                            <strong>Fill For: </strong> {FillInFor?.FirstName}
                        </Typography>
                    </>
                }
            </CardContent>
            <CardActions disableSpacing>
                {
                    (LoginUserRole === "admin" || LoginUserRole === "clientuser") && <>
                        <IconButton aria-label="cancel">
                            <Tooltip title="Cancel">

                                <CancelIcon 
                                    onClick={handleCancelClick}
                            />
                            </Tooltip>
                        </IconButton> 
                        {
                            LoginUserRole === "admin" && <IconButton aria-label="delete">
                                <Tooltip title="Delete">

                                    <DeleteIcon
                                        onClick={handleDeleteClick}
                                    />
                                </Tooltip>
                            </IconButton>
                        }


                        <IconButton aria-label="Rate Review">
                            <Tooltip title="Rate Review">

                                <RateReviewIcon
                                    onClick={handleRateReviewClick}
                                />
                            </Tooltip>
                        </IconButton>
                        <ExpandMore

                        aria-label="edit"
                        >
                            <Tooltip title="Edit">

                                <ModeEditOutlineIcon onClick={handleEditClick} /></Tooltip>

                    </ExpandMore>
                    </>

                }

            </CardActions>
        </Card>
    );
}
