/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
/**
 * The Holiday model module.
 * @module model/Holiday
 * @version 1.0
 */
class Holiday {
  /**
   * Constructs a new <code>Holiday</code>.
   * @alias module:model/Holiday
   */
  constructor() {
    Holiday.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Holiday</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Holiday} obj Optional instance to populate.
   * @return {module:model/Holiday} The populated <code>Holiday</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Holiday();

      if (data.hasOwnProperty("StartDate")) {
        obj["StartDate"] = ApiClient.convertToType(data["StartDate"], "Date");
      }
      if (data.hasOwnProperty("EndDate")) {
        obj["EndDate"] = ApiClient.convertToType(data["EndDate"], "Date");
      }
      if (data.hasOwnProperty("Reason")) {
        obj["Reason"] = ApiClient.convertToType(data["Reason"], "String");
      }
      if (data.hasOwnProperty("Id")) {
        obj["Id"] = ApiClient.convertToType(data["Id"], "String");
      }
    }
    return obj;
  }
}

/**
 * @member {String} EndDate
 */
Holiday.prototype["EndDate"] = undefined;
/**
 * @member {String} StartDate
 */
Holiday.prototype["StartDate"] = undefined;

/**
 * @member {String} Id
 */
Holiday.prototype["Id"] = undefined;

export default Holiday;
