import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading
} from "redux-saga/effects";

import { LoginRequest } from "api/mobile";
import jwt_decode from "jwt-decode";
import { parseDigits } from "libphonenumber-js";
import { prop } from "ramda";
import path from "routesPath";
import {
  endProgress,
  failProgress,
  getAccessToken,
  startProgress
} from "sagas/common";
import Api from "services/api";
import secureStorage from "services/secureStorage";
import storage from "services/storage";
import { getErrorCode, isForbiddenErrorStatus } from "utils/httpUtils";
import * as actions from "../actions";
import history from "../history";
import { getAlertCount } from "./alert";
import { getCreeds } from "./creed";
import { getProjects } from "./project";
import { getTimeSlots, getWeekdays } from "./roster";
export const excludepaths = [path.terms, path.phoneVerify, path.forgotPassword,path.appprivacy,path.appterms]
const DEMO_PHONE_NUMBER = "+1 (201) 200-0000";
function* initUser() {
  try {
    const currentPath = history.location.pathname;
    if (!(excludepaths.includes(currentPath) || currentPath.includes('feedback') || currentPath.includes('coverage'))) {
    // if (history.location.pathname !== path.terms) {
      // Only perform the hasAuth check if not on the /terms route
      if (storage.hasAuth()) {
        yield put(actions.setUserProps({ hasAuth: true }));

        // yield fingerprint.show("Authorization");
        const refreshToken = yield secureStorage.getRefreshToken();
        const accessToken = yield secureStorage.getAccessToken();

        yield put(actions.setUserProps({ auth: { refreshToken, accessToken } }));

        const tokens = yield call(getAccessToken);
        yield updateAuth({ ...tokens });
        yield afterLogin();
      } else {
        history.push(path.login);
      }
    }
  } catch (error) {
    console.error(error);
    // storage.clearHasAuth();
    yield put(actions.setUserProps({ hasAuth: false }));
    history.push(path.login);
  }
  yield put(actions.setUserProps({ initialized: true }));
  hideSplash();
}




function* afterLogin() {
  yield processProfile();
  yield getProjects();
  yield getUsers();
  yield getCreeds();
  yield getTimeSlots()
  yield getWeekdays()
  yield getAlertCount()
  yield put(actions.initPush());
}

function* processProfile() {
  const { status } = yield fetchProfile();
  return yield initHome();
}




function* fetchProfile() {
  const profile = yield Api.user.getUserDetailsUsingGET();
  const { auth } = yield select(prop("user"));
  const isTableView = storage.get('isTableView')();
  yield put(actions.setUserProps({
    ...profile, status: auth.status, LoginUserRole: profile.Role,
    LoginUserId: profile.Id,
    isTableView
  }));
  const selectedDepartmentValue = storage.get('selectedDepartmentValue')();
  if (selectedDepartmentValue) {
    yield put(actions.setTaskProps({
      selectedDepartmentValue: selectedDepartmentValue
    }));
  }

  const selectedRosterDepartmentValue = storage.get('selectedRosterDepartmentValue')();
  if (selectedRosterDepartmentValue) {
    yield put(actions.setRosterProps({ selectedDepartmentValue: selectedRosterDepartmentValue }));
  }
  return { ...profile, status: auth.status };
}
function* initHome() {
  if (history.location?.pathname == '/' || window.location.hash.includes('login')) {
    history.push(path.home);
  }
  hideSplash();
  yield delay(1000);
}



function* login(action) {
  const progress = yield startProgress();
  try {
    const { pin } = action.payload;
    const { device } = yield select(prop("cordova"));
    const { auth } = yield select(prop("user"));
    const phone = normalizePhone(auth.phone);

    const body = new LoginRequest(pin, phone, auth.smsId);
    body.deviceId = device.uuid;

    const data = yield Api.auth.loginUsingPOST(body);
    yield updateAuth({ ...auth, ...data });
    yield secureStorage.setRefreshToken(data.refreshToken);
    yield secureStorage.setAccessToken(data.accessToken);

    storage.setHasAuth();

    yield afterLogin();

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "requestPin"));
  }
}

function normalizePhone(phone = "") {
  return parseDigits(phone);
  //  return parsePhoneNumber(phone).number;
}

function* handleUpdateAuth({ payload }) {
  updateAuth(payload.auth);
}

function* updateAuth(auth) {
  const { accessToken, refreshToken } = auth;
  if (refreshToken) {
    yield secureStorage.setRefreshToken(refreshToken);
  }
  if (accessToken) {
    yield secureStorage.setAccessToken(accessToken);
  }
  storage.setHasAuth();
  Api.setAccessToken(accessToken);
  const customerId = decodeUserId(accessToken);
  yield put(actions.setUserProps({ customerId, auth }));
  return customerId;
}

function decodeUserId(accessToken) {
  if (accessToken) {
    const decoded = jwt_decode(accessToken);
    const { userId = "" } = decoded;
    return userId;
  }
}

function* setupProfile(action) {
  const progress = yield startProgress();
  try {
    const {
      firstName,
      lastName,
      street,
      street2,
      city,
      stateCode,
      zipCode,
      ssn,
      dateOfBirth,
    } = action.payload;

    const customerAddress = {
      street,
      street2,
      city,
      stateCode,
      zipCode,
    };
    const customerDetails = {
      firstName,
      lastName,
      last4SSN: ssn,
      dateOfBirth,
    };
    const customer = { customerAddress, customerDetails };
    const body = { customer };

    const profile = yield Api.customer.setupProfileUsingPUT(body);
    yield put(actions.setUserProps({ ...profile }));
    yield endProgress(progress);

    yield handleRegistrationStatus(profile.status);
  } catch (error) {
    yield failProgress(progress);
    yield handleSetupError(error);
  }
}

function* handleSetupError(error) {
  if (
    isForbiddenErrorStatus(error) &&
    getErrorCode(error) === "Q_MOBILE_CUSTOMER_SETUP_FAIL"
  ) {
    history.push(path.visitBank);
  } else {
    yield put(actions.showErrorDrawer(error, "setupProfile"));
  }
}

function* handleRegistrationStatus(status) {
  switch (status) {
    case "REGISTERED":
    // return yield put(actions.prequalifyLoan());
    case "REJECTED":
      return history.push(path.visitBank);
  }
}



function* getUsers() {
  const progress = yield startProgress();
  try {
    const users = yield Api.user.getUsersUsingGET();

    // const Users = chain(tx)
    //     .map(t => set(t, 'time', t.UserDateTime.getTime()))
    //     .map(t => set(t, 'date', formatDate(t.UserDateTime)))
    //     .map(t => set(t, 'unixDate', formatUnixDate(t.UserDateTime)))
    //     .groupBy('unixDate')
    //     .mapValues(v => orderBy(v, 'time', 'desc'))
    //     .value();

    yield put(actions.setUserProps({ users }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addUser(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const users = yield Api.user.addUser(body);
    history.goBack()
    yield refreshUsers();
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addNote(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const user = yield Api.user.addNote(body);
    //history.goBack()
    //yield refreshUsers();

    const { users } = yield select(prop('user'))

    const idx = users.findIndex(k => k.Id == body.User.Id);
    if (idx != -1) {
      for (let key in body.Data) {
        if (users[idx].hasOwnProperty(key)) {
          users[idx][key] = body.Data[key]
        }
      }
    }
    yield put(actions.setUserProps({ users }));



    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* updateField(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const user = yield Api.user.updateField(body);
    //history.goBack()
    // yield refreshUsers();


    const { users } = yield select(prop('user'))

    const idx = users.findIndex(k => k.Id == body.User.Id);
    if (idx != -1) {
      for (let key in body.Data) {
        if (users[idx].hasOwnProperty(key)) {
          users[idx][key] = body.Data[key]
        }
      }
    }
    yield put(actions.setUserProps({ users: JSON.parse(JSON.stringify(users)) }));

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* updateUserCreed(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const user = yield Api.user.updateUserCreed(body);
    //history.goBack()
    yield refreshUsers();


    // const { users } = yield select(prop('user'))

    // const idx = users.findIndex(k => k.Id == body.User.Id);
    // if (idx != -1) {
    //   for (let key in body.Data) {
    //     if (users[idx].hasOwnProperty(key)) {
    //       users[idx][key] = body.Data[key]
    //     }
    //   }
    // }
    // yield put(actions.setUserProps({ users: JSON.parse(JSON.stringify(users)) }));

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* resetUserPassword(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const users = yield Api.user.resetUserPassword(body);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* updateUserPassword(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const users = yield Api.user.updateUserPassword(body);
    history.goBack()
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeUser(action) {
  try {
    const id = action.payload.Id;
    const users = yield Api.user.removeUser(id);

    history.goBack()
  } catch (error) {
    console.error(error);
  }
}

function* refreshUsers() {
  const progress = yield startProgress();
  yield getUsers();
  yield endProgress(progress);
}

function* showUserDetails(action) {


  const { user } = action.payload;
  if (user.isNew) {
    yield put(actions.setUserProps({ userDetails: user }));
    history.push(path.user.userDetail);
  }
  else {
    const body = {
      Id: user.Id
    };
    const userRes = yield Api.user.getUserByIdUsingPOST(body);
    yield put(actions.setUserProps({ userDetails: userRes }));
    history.push(path.user.userDetail);
  }



}

function* logout({ payload }) {
  const progress = yield startProgress();


  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('xSessionId');
  yield put(actions.setUserProps({ auth: {} }));

  history.push(path.login);
  // Api.resetAccessToken();
  // Api.resetSessionId();

  yield endProgress(progress);
  // yield put(actions.resetApp());
}

function* forgotPassword(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload?.user;
    const users = yield Api.user.forgotPassword(body);
    history.push(path.login)
    yield endProgress(progress);

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}


function* loginWithEmailPass(action) {
  const progress = yield startProgress();
  try {
    const { device } = yield select(prop("cordova"));
    const { auth } = yield select(prop("user"));

    const body = action.payload.auth;
    const users = yield Api.login.loginWithEmailPass(body);
    // history.goBack()
    yield put(actions.setUserProps({ ...body }));
    yield updateAuth({ ...auth, ...users });
    storage.setHasAuth();
    yield afterLogin();

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}


function hideSplash() {
  if (window.navigator.splashscreen) {
    window.navigator.splashscreen.hide();
  }
}


export default function* saga() {
  yield all([
    takeLeading(actions.USER_INIT, initUser),
    takeLatest(actions.USER_LOGIN_WITH_PIN, login),
    takeLatest(actions.USER_LOGOUT, logout),

    takeLatest(actions.USER_UPDATE_AUTH, handleUpdateAuth),
    takeLatest(actions.USER_SETUP_PROFILE, setupProfile),
    takeLatest(actions.USER_GET_PROFILE, fetchProfile),

    takeLatest(actions.GET_USERS, getUsers),
    takeLatest(actions.ADD_USER, addUser),
    takeLatest(actions.ADDNOTE, updateField),
    takeLatest(actions.UPDATE_USER_FIELD, updateField),
    takeLatest(actions.UPDATE_USER_CREED, updateUserCreed),
    takeLatest(actions.REMOVE_USER, removeUser),
    takeLatest(actions.RESET_USER_PASSWORD, resetUserPassword),


    takeLatest(actions.UPDATE_USER_PASSWORD, updateUserPassword),
    takeLatest(actions.USER_LOGIN_REQUEST, loginWithEmailPass),
    takeLatest(actions.FORGOT_PASSWORD, forgotPassword),

    takeLatest(actions.REFRESH_USERS, refreshUsers),
    takeLatest(actions.USER_DETAILS, showUserDetails),

  ]);
}
