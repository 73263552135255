import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import SearchBar from 'components/common/SearchBar';
import EditNoteDialog from 'components/roster/editNoteDialogue';
import moment from 'moment';
import { prop } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddMTAAppDialogue from './AddMTAAppDialogue';
export const getStatusBackgroundColor = (status) => {
    switch (status) {
        case 'Active':
            return '#334a3d';
        case 'Inactive':
            return '#d0cece ';
        case 'Completed':
            return '#5b9bd5';
        default:
            return 'white';
    }
};
export const getStatusTextColor = (status) => {
    switch (status) {
        case 'Active':
            return '#fff';
        case 'Inactive':
            return '#000 ';
        case 'Completed':
            return '#fff';
        default:
            return 'white';
    }
};
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    card: {
        marginBottom: 5,
        border: 'none',
        boxShadow: 'none'
    },
    cardContent: {
        padding: "10px 5px!important",
    },
    tableHeight: {
        // maxHeight: 'calc(100vh - 233px)'
        maxHeight: "calc(100vh - 266px)",
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
        // maxWidth:'130px'
        // boxShadow: "5px 2px 5px grey",
        // borderRight: "2px solid black"
    },
    disable: {
        opacity: 0.5,
        pointerEvents: 'none'
    },
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
        }
    },


    addCircle: {
        display: 'none',
        position: 'absolute',
        // background: 'white',
        // boxShadow: 'inset 0px 0px 5px 0px #939090',
        // // borderRadius: '50%',
        // borderRadius: '0',
        // padding: '0px',
        // height: '100%'
        // "&. "
    },
    column: {
        // minWidth: '200px',
        // width: '200px',
        // maxWidth: '250px',
        padding: '0px 2px',
        position: 'relative',
        "&:hover $addCircle": {
            display: 'inline-block',
            // background: 'white',
            "& .MuiButtonBase-root": {
                padding: '5px',
                background: 'white',
                boxShadow: 'inset 0px 0px 5px 0px #939090',
                "&:hover": {
                    background: 'white',
                    boxShadow: 'inset 0px 0px 5px 0px #742d2d'

                }
            }
        }
    },
}));
function DynamicTable({ data, columns }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const { LoginUserRole } = useSelector(prop("user"));
    const [searchText, setSearchText] = useState('');
    const [sortedData, setSortedData] = useState([]);


    const [openAddCreedDialogue, setOpenAddCreedDialogue] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState({});

    const handleSort = useCallback((columnId) => {
        if (orderBy === columnId && order === 'asc') {
            setOrder('desc');
        } else {
            setOrderBy(columnId);
            setOrder('asc');
        }
    }, [orderBy, order]);
    const closeAddCreedDialog = () => {
        setOpenAddCreedDialogue(false);
    }
    const dispatch = useDispatch();

    const onUpdate = (data) => {
        // const tempItems = [...items];
        // if (itemIndex !== -1) {
        //     tempItems[itemIndex] = data;
        //     updateUserCreeds(tempItems);
        // } else {
        //     tempItems.push(data);
        //     updateUserCreeds(tempItems);
        // }
        // console.log(data);
        let tempData = {
            Id: data.Id,
            Date: data.Date,
            User: {
                Id: selectedItem.RowData.UserId,
            },
            Project: {
                Id: selectedItem.ProjectId
            },
            Creed: data.Creed
        }
        dispatch(actions.addMTA(tempData));

        setOpenAddCreedDialogue(false);

    };
    const updateTask = useCallback(() => {
        let filteredList = data ? [...data] : [];
        if (searchText.length > 0) {
            filteredList = filteredList.filter(k =>
                k.Name?.toLowerCase().includes(searchText) ||
                k.User?.Email?.toLowerCase().includes(searchText) ||
                k.User?.PhoneNo?.toLowerCase().includes(searchText)
            );
        }
        const newSortedData = filteredList.sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] < b[orderBy] ? -1 : 1;
            } else {
                return a[orderBy] > b[orderBy] ? -1 : 1;
            }
        });

        if (JSON.stringify(sortedData) !== JSON.stringify(newSortedData)) {
            setSortedData(newSortedData);
        }
    }, [searchText, data, sortedData]);
    useEffect(updateTask, [searchText, data]);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const classes = useStyles();
    const onSearch = useCallback((e) => {
        setSearchText(e.target.value.trim().toLowerCase());
    }, []);

    const getData = (cell, id) => {
        if (!id)
            return ''
        if (cell[id]['CN'])
            return cell[id]['CN']
        if (cell[id]['HASSPEC'] == 'Yes')
            return <WarningAmberIcon sx={{ color: 'red', fontSize: '16px', marginTop: '1px' }} />
        return ''

    }

    const getDateData = (cell, id) => {
        if (!id)
            return ''
        let key = 'D' + id;
        if (cell[key] && moment(cell[key]).isValid()) {
            return moment(cell[key]).format("MM-DD-YY")
        }
        return ''
    }

    const getNotes = (cell, id) => {
        if (!id)
            return ''
        if (cell[id]['N']) {
            return cell[id]['N'];
        }
        return ''
    }
    const getBackgroundColor = (cell, id) => {
        // if (!id)
        //     return ''
        // let key = 'BC' + id;
        // if (cell[key]) {
        //     return cell[key];
        // }
        // return '#fff'
        return cell[id]?.BC || '#fff'
    }

    const getTextColor = (cell, id) => {
        // if (!id)
        //     return ''
        // let key = 'TC' + id;
        // if (cell[key]) {
        //     return cell[key];
        // }
        // return '#000'
        return cell[id]?.TC || '#fff'
    }
    const TableTaskData = (cellData, column) => {
        return (
            // <Typography style={{backgroundColor: '#95a6e3'}}>
            <Typography style={{
                padding: "3px 3px",
                backgroundColor: getBackgroundColor(cellData, column.id),
                color: getTextColor(cellData, column.id), wordBreak: 'break-word', width: '100%'

            }}>
                {/* {getData(cellData, column.id)} */}
                {cellData[column.id]['CN'] && cellData[column.id]['CN']}
                {cellData[column.id]['HASSPEC'] == 'Yes' && <WarningAmberIcon sx={{ color: 'red', fontSize: '16px', marginTop: '1px' }} />}
                <br />
                {cellData[column.id]['D'] && cellData[column.id]['D']}
                {/* {getDateData(cellData, column.id)} */}
            </Typography>
        );
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = (event, request) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(request);
    };

    const closeMenu = () => {
        setAnchorEl(null);
        // setSelectedItem(null);
    };

    const handleEdit = (request) => {
        let d = {
            ...request
        }

        if (request.RowData[request.ProjectId].CN) {
            d['Creed'] = {
                Id: request.RowData[request.ProjectId].CID,
                CreedName: request.RowData[request.ProjectId].CN
            }
        }
        if (request.RowData[request.ProjectId].D) {
            d['Date'] = moment(request.RowData[request.ProjectId].D)
        }
        setSelectedItem(d);
        setOpenAddCreedDialogue(true)
        closeMenu();
    };
    const handleNotesAdd = (request) => {
        setSelectedItem(request);
        setOpenAddNoteDialogue(true)
        closeMenu();
    };

    const handleDelete = (request) => {
        setSelectedItem(request);
        let tempData = {
            User: {
                Id: request.RowData.UserId,
            },
            Project: {
                Id: request.ProjectId
            },
            Creed: data.Creed
        }
        dispatch(actions.removeMTA(tempData));
        closeMenu();
    };

    const [openAddNoteDialogue, setOpenAddNoteDialogue] = React.useState(false);
    const closeAddNoteDialog = () => {
        setOpenAddNoteDialogue(false);
    }

    const onNoteUpdate = (data) => {
        let tempData = {
            User: {
                Id: selectedItem.RowData.UserId,
            },
            Project: {
                Id: selectedItem.ProjectId
            },
            Note: data.Note

        }
        dispatch(actions.addMTANote(tempData));
        setOpenAddNoteDialogue(false);

    };
    return (
        <Paper>
            <Box sx={{ p: 1, maxWidth: '580px' }}>
                <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
            </Box>
            <TableContainer className={classes.tableHeight}>
                <Table stickyHeader aria-label="sticky table" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align="center" style={{
                                    maxWidth: '150px',
                                    minWidth: '130px',
                                    zIndex: column.id == 'Name' ? 4 : 3,
                                    backgroundColor: getStatusBackgroundColor(column.status),
                                    color: column.id == 'Name' ? '#000' : getStatusTextColor(column.status)

                                }} className={column.id == 'Name' ? classes.sticky : ""}>
                                    {column.label}
                                    {/* <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleSort(column.id)}
                                        style={{ cursor: 'pointer', whiteSpace: 'pre' }}
                                    >
                                        {column.label}
                                    </TableSortLabel> */}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => ( */}
                        {sortedData.map((row, index) => (
                            <TableRow key={index}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align="center"
                                        className={column.id === 'Name' ? classes.sticky : classes.column}
                                        style={{ maxWidth: '150px', zIndex: column.id === 'Name' ? 2 : 1, opacity: row.IsActive ? 1 : 0.5 }}
                                    >
                                        {column.id === 'Name' ? (
                                            <>
                                                {row[column.id]}
                                            </>
                                        ) : (
                                                (LoginUserRole === "admin") && (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {TableTaskData(row, column)}
                                                        <Stack spacing={0.5} direction="row" className={classes.addCircle}>
                                                                <IconButton aria-label="edit" onClick={() => handleEdit({ RowData: row, ProjectId: column.id })}>
                                                                    <EditOutlinedIcon color="primary" fontSize="small" />
                                                            </IconButton>
                                                                <IconButton aria-label="delete" onClick={() => handleDelete({ RowData: row, ProjectId: column.id })}>
                                                                    <DeleteOutlinedIcon color="primary" fontSize="small" />
                                                            </IconButton>
                                                                <IconButton aria-label="notes" onClick={() => handleNotesAdd({ RowData: row, ProjectId: column.id, Note: getData(row, column.id, 'Note') })}>
                                                                    <EditNoteIcon color="primary" fontSize="small" />
                                                            </IconButton>
                                                        </Stack> 
                                                        {/* {
                                                            <IconButton aria-label="edit" className={classes.addCircle} onClick={() => handleEdit({ RowData: row, ProjectId: column.id })}>
                                                            <EditOutlinedIcon color="primary" fontSize="small" />
                                                        </IconButton>
                                                        } */}
                                                        {
                                                            row[column.id]['N'] && <Tooltip title={row[column.id]['N']} arrow>
                                                                {/* <IconButton aria-label="info" style={{ position: 'absolute', top: 0, right: 0 }}>
                                                                    <InfoIcon color="action" fontSize="small" sx={{ fontSize: '9px', color: 'red' }} />
                                                                </IconButton> */}
                                                                <div style={{
                                                                    position: 'absolute', top: 0, right: '-5px',

                                                                    'border-style': 'solid',
                                                                    borderWidth: '0 7px 7px 7px',
                                                                    'borderColor': 'transparent transparent #FF4532 transparent',
                                                                    transform: 'rotate(45deg)'


                                                                }}> </div>
                                                            </Tooltip>
                                                        } 

                                                    </div>
                                            )
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={columns.length} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
            /> */}
            <AddMTAAppDialogue
                open={openAddCreedDialogue}
                handleClose={closeAddCreedDialog}
                onUpdate={onUpdate}
                data={selectedItem?.RowData || {}}
            >
            </AddMTAAppDialogue>
            <EditNoteDialog
                open={openAddNoteDialogue}
                handleClose={closeAddNoteDialog}
                onUpdate={onNoteUpdate}
                data={selectedItem}
            >
            </EditNoteDialog>
        </Paper>
    );
}

export default DynamicTable;
