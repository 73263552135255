import React from "react";
import BackAppBar from "components/app/BackAppBar";
import { useSelector } from "react-redux";
import { prop } from "ramda";
import BgView from "components/common/BgView";
import SubContractorDeails from "components/subContractor/SubContractorDetails";

export default function SubContractorView() {
  const { subContractorDetails } = useSelector(prop('subContractor'));
  return (
    <BgView>
      <BackAppBar />
      <SubContractorDeails SubContractor={subContractorDetails} />
    </BgView>
  );
}
