import { MTA_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
    mtas: [],
    mtaNotes: [],
    mtaDetails: null,
};

export default propsReducer(MTA_SET_PROPS, DEFAULT_STATE);
