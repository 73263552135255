import {all, delay, put, select, takeEvery} from "redux-saga/effects";

import * as actions from '../actions/progress';
import {equals, prop} from "ramda";
import {reject} from "lodash";

function* hideProgress(action) {
  yield delay(300);

  const {id} = action.payload;

  const state = yield select(prop('progress'));

  const activeProgressIds = reject(state.activeProgressIds, equals(id));
  const completed = activeProgressIds.length === 0;
  const show = !completed;
  const failed = state.failed || action.payload.failed;

  yield put(actions.setProgressProps({activeProgressIds, show, completed, failed}));

  if (completed) {
    yield delay(600);
    yield put(actions.setProgressProps({completed: false}));
  }
}

export default function* saga() {
  yield all([
    takeEvery(actions.PROGRESS_HIDE, hideProgress),
  ]);
};