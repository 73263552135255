import BackAppBar from "components/app/BackAppBar";
import HolidayList from "components/holiday/HolidayList";
import React from "react";
import { useDispatch } from "react-redux";
import * as actions from "actions";
export default function HolidayAdministartionView() {
 const dispatch = useDispatch();
  const holidayDetails = (holiday) => {
    dispatch(actions.showHolidayDetails(holiday));
  };
  const newHolidayClick = (holiday = {}) => {
    dispatch(actions.showHolidayDetails({ ...holiday, isNew: true }));
  };
  return (
    <>
      <BackAppBar />
      <HolidayList onItemSelect={holidayDetails} onAddNewHoliday={newHolidayClick} />
    </>
  );
}
