/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import { getNameStr } from 'utils/strUtils';
import ApiClient from '../ApiClient';
import ClientUser from './ClientUsers';

/**
 * The Client model module.
 * @module model/Client
 * @version 1.0
 */
class Client {
    /**
     * Constructs a new <code>Client</code>.
     * @alias module:model/Client
     */
    constructor() {

        Client.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>Client</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Client} obj Optional instance to populate.
     * @return {module:model/Client} The populated <code>Client</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Client();

            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }


            if (data.hasOwnProperty('Email')) {
                obj['Email'] = ApiClient.convertToType(data['Email'], 'String');
            }
            if (data.hasOwnProperty('MobileNo')) {
                obj['MobileNo'] = ApiClient.convertToType(data['MobileNo'], 'String');
            }

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('ClientUsers')) {
                obj['ClientUsers'] = ApiClient.convertToType(data['ClientUsers'], [ClientUser]);
            }
            obj['Name'] = getNameStr(data);

        }
        return obj;
    }


}

/**
 * client first name
 * @member {String} Name
 */
Client.prototype['Name'] = undefined;


/**
 * client first name
 * @member {String} Email
 */
Client.prototype['Email'] = undefined;

/**
* @member {String} MobileNo
*/
Client.prototype['MobileNo'] = undefined;

/**
* rolw
* @member {module:model/Client.Role} Role
*/
Client.prototype['Role'] = undefined;

/**
 * @member {String} Id
 */
Client.prototype['Id'] = undefined;
Client.prototype['Name'] = undefined;

/**
 * Allowed values for the <code>Role</code> property.
 * @enum {String}
 * @readonly
 */
Client['Role'] = {

    /**
     * value: "MANAGER"
     * @const
     */
    "MANAGER": "MANAGER",

    /**
     * value: "SALESPERSON"
     * @const
     */
    "SALESPERSON": "SALESPERSON",

};



export default Client;

