import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import {Check, Close} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
      progress: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: theme.zIndex.tooltip,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      progressPaper: {
        position: 'relative',
        width: theme.spacing(7),
        height: theme.spacing(7),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
      },
      progressIcon: {
        position: 'absolute',
      },
    })
);

export default function ProgressSpinner({show, completed, failed}) {
  const classes = useStyles();

  if (!show && !completed) return null;

  return (
      <div className={classes.progress}>
        <Paper className={classes.progressPaper} elevation={4}>
          <Fade in={show}>
            <CircularProgress className={classes.progressIcon}/>
          </Fade>
          <Fade in={completed}>
            {failed
                ? <Close color="error" fontSize="large" className={classes.progressIcon}/>
                : <Check color="primary" fontSize="large" className={classes.progressIcon}/>
            }
          </Fade>
        </Paper>
      </div>
  );
}