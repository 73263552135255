import { Container } from "@mui/material";
import ViewTitle from "components/common/ViewTitle";
import React from 'react';

export default function LegalView() {
  const privacyPolicyLink = "https://example.com/privacy-policy"; // Replace with your actual privacy policy URL
  const smsTermsLink = "https://example.com/sms-terms"; // Replace with your actual SMS Terms URL

  return (
    <Container>
      {/* <BackAppBar /> */}
      <ViewTitle>Legal</ViewTitle>

      <>
        <title>Privacy Policy and SMS Terms</title>
        <h1>Privacy Policy</h1>
        <p>
          <em>Last Updated: 02-09-2023</em>
        </p>
        <p>
          This Privacy Policy outlines how ICC (Infinite Consulting Corp) ("we," "us,"
          or "our") collects, uses, maintains, and discloses information collected
          from users ("you" or "your") of our website, mobile application, or any
          other services we offer (collectively, the "Services").
        </p>
        <h2>1. Information We Collect</h2>
        <p>We may collect the following types of information from users:</p>
        <ul>
          <li>
            <strong>Personal Information:</strong> This may include your name, email
            address, postal address, phone number, or other personally identifiable
            information when voluntarily provided by you.
          </li>
          <li>
            <strong>Non-Personal Information:</strong> We may also collect
            non-personal information such as your browser type, device type, and usage
            data through cookies and similar technologies.
          </li>
        </ul>
        <h2>2. How We Use Your Information</h2>
        <p>We may use your information for the following purposes:</p>
        <ul>
          <li>To provide and improve our Services.</li>
          <li>To personalize your experience.</li>
          <li>To send periodic emails or updates.</li>
          <li>To respond to your inquiries or requests.</li>
          <li>To comply with legal requirements.</li>
        </ul>
        <h2>3. Disclosure of Your Information</h2>
        <p>
          We do not sell, trade, or rent your personal information to third parties.
          We may share your information with trusted third parties for specific
          purposes such as processing payments or providing support services.
        </p>
        <h2>4. Security</h2>
        <p>
          We use reasonable measures to protect your information from unauthorized
          access, disclosure, alteration, or destruction.
        </p>
        <h2>5. Your Choices</h2>
        <p>
          You may opt-out of receiving promotional emails from us by following the
          unsubscribe instructions provided in the email. You may also have other
          rights regarding your personal information as provided by applicable laws.
        </p>
        <h2>6. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. The date of the latest
          revision will be indicated at the top of the policy.
        </p>
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy or your personal
          information, please contact us at{" "}
          <a href="mailto:info@infiniteconsultingcorp.com">
            info@infiniteconsultingcorp.com
          </a>
          .
        </p>
        <hr />
        <h1>SMS Terms and Conditions</h1>
        <p>
          <em>Last Updated: 02-09-2023</em>
        </p>
        <p>
          By opting in to receive text messages from ICC (Infinite Consulting Corp),
          you agree to the following terms and conditions:
        </p>
        <h2>1. Message Frequency</h2>
        <p>
          You may receive recurring text messages from us. Message frequency may vary.
        </p>
        <h2>2. Message Content</h2>
        <p>
          Messages may include promotional content, updates, alerts, and information
          related to our products or services.
        </p>
        <h2>3. Message and Data Rates</h2>
        <p>
          Message and data rates may apply according to your mobile carrier plan. You
          are responsible for any charges associated with text messaging.
        </p>
        <h2>4. Opt-Out</h2>
        <p>
          You can opt-out of receiving text messages at any time by replying "STOP" to
          the received message. You will no longer receive messages from us.
        </p>
        <h2>5. Help</h2>
        <p>
          For help or assistance, reply "HELP" to any message or contact us at{" "}
          <a href="tel:516-331-1797">516-331-1797</a>.
        </p>
        <h2>6. Privacy</h2>
        <p>
          We respect your privacy. Your information is subject to our Privacy Policy,
          which can be found on our website.
        </p>
        <h2>7. Changes to Terms</h2>
        <p>
          We may update these SMS terms and conditions at any time. The date of the
          latest revision will be indicated at the top.
        </p>
        {/* <h2>8. Active 10DLC Campaigns</h2>
        <p>Details of our active 10DLC campaigns:</p>
        <ul>
          <li>Scheduled Notifications: Test Campaign for Scheduled Notifications</li>
        </ul> */}
        <h2>8. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding these terms, please contact
          us at{" "}
          <a href="mailto:info@infiniteconsultingcorp.com">
            info@infiniteconsultingcorp.com
          </a>
          .
        </p>
        <hr />
        {/* Carrier Disclaimer */}
        <p>
          <strong>Carrier Disclaimer:</strong> 'Carriers are not liable for delayed or
          undelivered messages'.
        </p>
      </>

    </Container>
  );
}
