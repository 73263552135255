import { propsReducer } from "utils/stateUtils";
import { ALERT_SET_PROPS } from "actions/alert";



const DEFAULT_STATE = {
    alerts: [],
    alertDetails: null,
    alertResponse: null,

};

export default propsReducer(ALERT_SET_PROPS, DEFAULT_STATE);
