import * as actions from "actions";
import { chain, orderBy, set } from "lodash";
import { all, delay, put, takeLatest } from "redux-saga/effects";
import { endProgress, failProgress, startProgress } from "sagas/common";
import Api from "services/api";

import path from "routesPath";
import { formatDate, formatUnixDate } from "utils/strUtils";
import history from "../history";



function* getAlerts() {
  const progress = yield startProgress();
  try {
    const tx = yield Api.alert.getAlertsUsingGET();

    const alerts = chain(tx)
      .map(t => set(t, 'time', t.CreateDate.getTime()))
      .map(t => set(t, 'date', formatDate(t.CreateDate)))
      .map(t => set(t, 'unixDate', formatUnixDate(t.CreateDate)))
      .groupBy('unixDate')
      .mapValues(v => orderBy(v, 'time', 'desc'))
      .value();

    yield put(actions.setAlertProps({ alerts }));
    yield endProgress(progress);
  } catch (error) {
    console.error(error);
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "getAlertsFailed"));
  }
}
export function* getAlertCount() {
  const progress = yield startProgress();
  try {
    const tx = yield Api.alert.GetNewCount();


    yield put(actions.setAlertProps({ newAlerts: tx }));
    yield endProgress(progress);
  } catch (error) {
    console.error(error);
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "getAlertsFailed"));
  }
}

function* refreshAlerts() {
  const progress = yield startProgress();
  yield getAlerts();
  yield endProgress(progress);
}

function* showDetails(action) {
  const { alert } = action.payload;
  yield put(actions.setAlertProps({ alertDetails: alert }));
  history.push(path.alert);
}


function showAllAlert() {
  history.push(path.allAlerts);
}

function* startAlert(action) {
  const progress = yield startProgress();
  try {
    const { qrDetails } = action.payload;

    const data = yield Api.alert.startAlert(qrDetails);

    yield put(actions.setAlertProps({ alertResponse: data }));

    document.body.style.backgroundColor = "white";
    history.push(path.doneAlert);
    delay(300);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, "alertError"));
  }
}



export default function* saga() {
  yield all([
    takeLatest(actions.GET_ALERTS, getAlerts),
    takeLatest(actions.GET_ALERT_COUNT, getAlertCount),
    takeLatest(actions.REFRESH_ALERTS, refreshAlerts),
    takeLatest(actions.ALERT_DETAILS, showDetails),
    takeLatest(actions.SHOW_ALL_ALERT, showAllAlert),
    takeLatest(actions.START_ALERT, startAlert),


  ]);
};
