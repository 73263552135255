import {
    Alert, Box, Button, Checkbox, FormControlLabel, Grid, Paper,
    Snackbar,
    Typography
} from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import Logo from "assets/icons/logo.png";
import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import CodeValidatorField from './common/input/CodeValidatorField';
import PhoneValidator from './common/input/PhoneValidator';
import TermsAndConditions from './common/input/TermsAndConditions';


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    form: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        alignItems: 'center',
        justifyContent: 'center'
    },
    column: {
        // [theme.breakpoints.up('sm')]: {
        //     flex: '0 0 50%',
        //     padding: theme.spacing(2),
        // },
        // justifyContent: 'center'
    },
}));
const PhoneVerificationPage = () => {
    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [smsConsent, setSmsConsent] = useState(false);
    const [termsConsent, setTermsConsent] = useState(false);
    const [sentCode, setSentCode] = useState(false);
    const [resendDisabled, setResendDisabled] = useState(true);
    const [timer, setTimer] = useState(30);

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event);
        setSentCode(false);
        setResendDisabled(true);
    };

    const handleVerificationCodeChange = (event) => {
        setVerificationCode(event.target.value);
    };

    const handleConsentChange = () => {
        setSmsConsent(!smsConsent);
    };

    const handleTermsAcceptChange = () => {
        setTermsConsent(!termsConsent);
    };

    const handleSendCode = () => {
        setSentCode(true);
        setResendDisabled(true);
        startTimer();
    };

    const handleResendCode = () => {
        setSentCode(true);
        setResendDisabled(true);
        startTimer();
    };

    const startTimer = () => {
        let countdown = 30;
        const interval = setInterval(() => {
            countdown--;
            setTimer(countdown);
            if (countdown === 0) {
                clearInterval(interval);
                setResendDisabled(false);
                setTimer(30);
            }
        }, 1000);
    };

    useEffect(() => {
        if (timer === 0) {
            setResendDisabled(false);
        }
    }, [timer]);

    const handleVerify = () => {

    };
    const dispatch = useDispatch();

    const handleFormErrors = errors => console.error(errors);
    const [open, setOpen] = useState(false);
    const handleFormSubmit = event => {
        event.preventDefault();
        dispatch(actions.forgotPassword({ Phone: phoneNumber }));
        setOpen(true)

        if (verificationCode.length === 4 && smsConsent) {
            console.log('Verification code entered:', verificationCode);
            console.log('Phone number:', phoneNumber);
            // Add your code here to handle verification
        } else {
            console.log('Please enter a 4-digit code and provide SMS consent to proceed.');
        }
        return false;

    };
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="#f1f2f7"
        >
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                onClose={() => setOpen(false)}
            >
                <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                    Please Check you email for the password.
                </Alert>
            </Snackbar>
            <Paper
                elevation={2}
                sx={{
                    width: "100%",
                    maxWidth: "400px",
                    padding: "50px",
                    textAlign: "center",
                    margin: "10px"
                }}
            >
                <Box mb={3}>
                    <img src={Logo} alt="Logo" style={{ height: "70px" }} />
                </Box>
                <Typography variant="h5" fontWeight="bold" mb={2}>
                    Verify Phone Number
                </Typography>
                <ValidatorForm
                    instantValidate={false}
                    onSubmit={handleFormSubmit}
                    onError={handleFormErrors} form
                >
                    <Grid container spacing={2} className={classes.form}>
                        <Grid item className={classes.column} xs={12}>
                            <PhoneValidator
                                defaultCountry="us"
                                disableAreaCodes
                                countryCodeEditable={false}
                                label="Phone number"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                fullWidth
                                disabled={sentCode}
                                margin="normal"
                                validators={["required", "isPhone"]}
                                errorMessages={[
                                    "Phone number is required",
                                    "The Mobile number is not valid",
                                ]}
                                inputProps={{
                                    autoComplete: 'new-password'
                                }}
                                data-testid="input-MobileNo-number"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.column}>
                            {!sentCode && (
                                <Button variant="contained" onClick={handleSendCode}>
                                    Send Code
                                </Button>
                            )}
                        </Grid>
                    </Grid>


                    {sentCode && (
                        <Box >
                            <CodeValidatorField label="Enter 4-digit verification code"
                                value={verificationCode}
                                onChange={handleVerificationCodeChange}
                                fullWidth
                                margin="normal"></CodeValidatorField>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={smsConsent}
                                        onChange={handleConsentChange}
                                    />
                                }
                                label="I agree to receive text messages for scheduling/coverage notifications. I understand that I will receive message notifications for each scheduled coverage, and data rates may apply. Reply STOP to opt-out"
                            />
                            <TermsAndConditions
                                acceptedTerms={termsConsent}
                                handleTermsAcceptChange={handleTermsAcceptChange}

                            ></TermsAndConditions>
                            <Button
                                variant="contained"
                                onClick={handleVerify}
                                disabled={!(verificationCode.length === 4 && smsConsent && termsConsent)}
                            >
                                Verify
                            </Button>
                            <Button onClick={handleResendCode} disabled={resendDisabled}>
                                Resend Code ({timer}s)
                            </Button>
                        </Box>
                    )}

                </ValidatorForm>

            </Paper>
        </Box>
    );
};

export default PhoneVerificationPage;
