import React from 'react';
import BackAppBar from "components/app/BackAppBar";
import {useSelector} from "react-redux";
import {prop} from "ramda";
import BgView from "components/common/BgView";
import AlertDetails from './AlertDetails';

export default function AlertView() {

  const {alertDetails} = useSelector(prop('alert'));

  return (
      <BgView>
        <BackAppBar/>
        <AlertDetails alert={alertDetails}/>
      </BgView>
  );
}
