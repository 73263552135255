/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

 import ApiClient from '../ApiClient';

 /**
  * The AlertResponse model module.
  * @module model/AlertResponse
  * @version 1.0
  */
 class AlertResponse {
     /**
      * Constructs a new <code>AlertResponse</code>.
      * @alias module:model/AlertResponse
      */
     constructor() { 
         
         AlertResponse.initialize(this);
     }
 
     /**
      * Initializes the fields of this object.
      * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
      * Only for internal use.
      */
     static initialize(obj) { 
     }
 
     /**
      * Constructs a <code>AlertResponse</code> from a plain JavaScript object, optionally creating a new instance.
      * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
      * @param {Object} data The plain JavaScript object bearing properties of interest.
      * @param {module:model/AlertResponse} obj Optional instance to populate.
      * @return {module:model/AlertResponse} The populated <code>AlertResponse</code> instance.
      */
     static constructFromObject(data, obj) {
         if (data) {
             obj = obj || new AlertResponse();
 
             if (data.hasOwnProperty('category')) {
                 obj['category'] = ApiClient.convertToType(data['category'], 'String');
             }
             if (data.hasOwnProperty('debitCredit')) {
                 obj['debitCredit'] = ApiClient.convertToType(data['debitCredit'], 'String');
             }
             if (data.hasOwnProperty('declined')) {
                 obj['declined'] = ApiClient.convertToType(data['declined'], 'Boolean');
             }
             if (data.hasOwnProperty('description')) {
                 obj['description'] = ApiClient.convertToType(data['description'], 'String');
             }
             if (data.hasOwnProperty('feedMessageType')) {
                 obj['feedMessageType'] = ApiClient.convertToType(data['feedMessageType'], 'String');
             }
             if (data.hasOwnProperty('localAlertResponseDate')) {
                 obj['localAlertResponseDate'] = ApiClient.convertToType(data['localAlertResponseDate'], 'String');
             }
             if (data.hasOwnProperty('localAlertResponseTime')) {
                 obj['localAlertResponseTime'] = ApiClient.convertToType(data['localAlertResponseTime'], 'String');
             }
             if (data.hasOwnProperty('postingDate')) {
                 obj['postingDate'] = ApiClient.convertToType(data['postingDate'], 'String');
             }
             if (data.hasOwnProperty('postingTime')) {
                 obj['postingTime'] = ApiClient.convertToType(data['postingTime'], 'String');
             }
             if (data.hasOwnProperty('qAlertResponseId')) {
                 obj['qAlertResponseId'] = ApiClient.convertToType(data['qAlertResponseId'], 'String');
             }
             if (data.hasOwnProperty('rejectionReason')) {
                 obj['rejectionReason'] = ApiClient.convertToType(data['rejectionReason'], 'String');
             }
             if (data.hasOwnProperty('AlertResponseDateTime')) {
                 obj['AlertResponseDateTime'] = ApiClient.convertToType(data['AlertResponseDateTime'], 'Date');
             }
             if (data.hasOwnProperty('AlertResponseAmount')) {
                 obj['AlertResponseAmount'] = ApiClient.convertToType(data['AlertResponseAmount'], 'Number');
             }
             if (data.hasOwnProperty('AlertResponseSource')) {
                 obj['AlertResponseSource'] = ApiClient.convertToType(data['AlertResponseSource'], 'String');
             }
         }
         return obj;
     }
 
 
 }
 
 /**
  * MerchantType Translated to text using the MCC code mapping
  * @member {String} category
  */
 AlertResponse.prototype['category'] = undefined;
 
 /**
  * Debit or Credit AlertResponse type
  * @member {module:model/AlertResponse.DebitCreditEnum} debitCredit
  */
 AlertResponse.prototype['debitCredit'] = undefined;
 
 /**
  * If RejectionCode <> 00 then true, otherwise false and null if empty
  * @member {Boolean} declined
  */
 AlertResponse.prototype['declined'] = undefined;
 
 /**
  * Mechantname OR AlertResponseDescription1 OR description1
  * @member {String} description
  */
 AlertResponse.prototype['description'] = undefined;
 

 
 /**
  * LocalAlertResponseDate from TransCast Feed
  * @member {String} localAlertResponseDate
  */
 AlertResponse.prototype['localAlertResponseDate'] = undefined;
 
 /**
  * LocalAlertResponseTime from TransCast Feed
  * @member {String} localAlertResponseTime
  */
 AlertResponse.prototype['localAlertResponseTime'] = undefined;
 
 /**
  * PostingDate from TransCast Feed
  * @member {String} postingDate
  */
 AlertResponse.prototype['postingDate'] = undefined;
 
 /**
  * PostingTime from TransCast Feed
  * @member {String} postingTime
  */
 AlertResponse.prototype['postingTime'] = undefined;
 
 /**
  * @member {String} qAlertResponseId
  */
 AlertResponse.prototype['qAlertResponseId'] = undefined;
 
 /**
  * RejectionReason description
  * @member {String} rejectionReason
  */
 AlertResponse.prototype['rejectionReason'] = undefined;
 
 /**
  * AlertResponse amount
  * @member {Number} AlertResponseAmount
  */
 AlertResponse.prototype['AlertResponseAmount'] = undefined;
 
 /**
  *  formatted date from LocalAlertResponse Date/Time
  * @member {Date} AlertResponseDateTime
  */
 AlertResponse.prototype['AlertResponseDateTime'] = undefined;
 
 /**
  * AlertResponseSource description
  * @member {String} AlertResponseSource
  */
 AlertResponse.prototype['AlertResponseSource'] = undefined;
 
 
 
 
 
 /**
  * Allowed values for the <code>debitCredit</code> property.
  * @enum {String}
  * @readonly
  */
 AlertResponse['DebitCreditEnum'] = {
 
     /**
      * value: "DEBIT"
      * @const
      */
     "DEBIT": "DEBIT",
 
     /**
      * value: "CREDIT"
      * @const
      */
     "CREDIT": "CREDIT"
 };
 
 

 
 
 export default AlertResponse;
 
 