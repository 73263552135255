import React from 'react';

import {withStyles} from '@mui/styles';
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import {ValidatorComponent, ValidatorForm} from 'react-material-ui-form-validator';

const styles = () => ({
  formControlLabel: {
    marginRight: 0,
  },
});

ValidatorForm.addValidationRule('isCheckboxChecked', value => {
  return Boolean(value);
});

class CheckboxValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {classes, errorMessages, validators, requiredError, value, validatorListener, formControlProps, label, children, ...rest} = this.props;
    return (
        <FormControl {...formControlProps}>
          <FormControlLabel
              control={<Checkbox {...rest} ref={(r) => {
                this.input = r;
              }}/>}
              label={label || children}
              className={classes.formControlLabel}
          />
          {this.errorText()}
        </FormControl>
    );
  }

  errorText = () => {
    const {isValid} = this.state;
    if (isValid) {
      return null;
    }

    return (
        <FormHelperText error>{this.getErrorMessage()}</FormHelperText>
    );
  }
}

export default withStyles(styles)(CheckboxValidator);
